import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  DayOfWeek,
  getLeagues,
  getRegistrationBatch,
  getSports,
} from "../../app/venueMasterSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import {
  ArrowUpDown,
  Calendar,
  CalendarCheck,
  MoreHorizontal,
} from "lucide-react";
import dayjs from "dayjs";
import { dayOfWeek } from "@/src/utils/dayOfWeek";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../UI/shadcn/select";
import { MoreVerticalIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button/Button";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { Pagination } from "@mui/material";
import Tooltip from "../UI/Tooltip/Tooltip";
import {
  SessionsPaginatedQuery,
  useLeagueDynamicQuery,
  useSessionsPaginatedLazyQuery,
  useSessionsPaginatedQuery,
} from "../../../src/generated/graphql";
import { rootUrl } from "../../utils/environmentDependantVars";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { FormFieldSelectMultiSearch } from "../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import Cookies from "js-cookie";
import { displayAlertSuccess } from "../../app/globalSlice";

const weekdaysArray: any = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

type FreeAgentCount = {
  totalCount: number;
  men: number;
  women: number;
  other: number;
};

type Division = {
  id: string;
  name: string;
};

type Session = {
  id: string;
  region: {
    id: number;
    name: string;
  };
  registrationBatch: {
    id: number;
    name: string;
  };
  league: {
    id: number;
    name: string;
  };
  dayOfWeek: number;
  startDate: string;
  setCapacity: number;
  indyTeams: number;
  teamCount: number;
  freeAgentCount: FreeAgentCount;
  divisions: Division[];
};

type SessionFilterWayfinder = {
  leagueIds: number[] | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  regionIds: number[];
  sportId: number | null;
  startDate: string;
  endDate: string | null;
};

type PaginationType = {
  page: number;
  pageSize: number;
  pageCount: number | undefined;
};

const initialSessionFilters = {
  regionIds: [0],
  registrationBatchId: null,
  sportId: null,
  dayOfWeek: null,
  leagueIds: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
};

const Wayfinder: React.FC = () => {
  const { selectedRegions, registrationBatches, sports } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const dispatch: any = useDispatch<AppDispatch>();

  const [sessionFilters, setSessionFilters] = useState<SessionFilterWayfinder>(
    () => {
      const sessionFilter = Cookies.get("sessionFilters");
      if (sessionFilter) {
        return JSON.parse(sessionFilter);
      } else {
        return initialSessionFilters;
      }
    }
  );

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 25,
    pageCount: undefined,
  });

  const [sessionData, setSessionData] = useState<
    SessionsPaginatedQuery["sessionsPaginated"]["sessions"]
  >([]);

  const { data: dataLeagues, loading: loadingLeagues } = useLeagueDynamicQuery({
    variables: {
      leagueDynamicFilter: {
        regBatchIds: sessionFilters.registrationBatchId
          ? [sessionFilters.registrationBatchId]
          : [],
        regionIds:
          sessionFilters.regionIds[0] === 0
            ? selectedRegions.map((region) => region.id)
            : sessionFilters.regionIds,
        sportIds: sessionFilters.sportId ? [sessionFilters.sportId] : [],
        dayIds: sessionFilters.dayOfWeek ? [sessionFilters.dayOfWeek] : [],
        sportFormatIds: [],
      },
    },
  });

  const { data: dataSessions, loading: loadingSessions } =
    useSessionsPaginatedQuery({
      variables: {
        sessionsPaginatedInput: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          sessionFilters: {
            ...sessionFilters,
            regionIds:
              sessionFilters.regionIds[0] === 0
                ? selectedRegions.map((region) => region.id)
                : sessionFilters.regionIds,
            dayOfWeek:
              sessionFilters.dayOfWeek == -1 ? null : sessionFilters.dayOfWeek,
          },
        },
      },
      onCompleted: (data) => {
        if (data.sessionsPaginated) {
          setSessionData(data.sessionsPaginated.sessions);
          setPagination((prevState: PaginationType) => {
            return {
              ...prevState,
              pageCount: data.sessionsPaginated.count,
            };
          });
        }
      },
    });

  useEffect(() => {
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
  }, []);

  useEffect(() => {
    if (sessionFilters === initialSessionFilters) {
      Cookies.remove("sessionFilters");
    } else {
      Cookies.set("sessionFilters", JSON.stringify(sessionFilters));
    }
  }, [sessionFilters]);

  useEffect(() => {
    const regionIdsOnSelectionChange = sessionFilters.regionIds.filter(
      (sessionFilterRegion) =>
        selectedRegions.map((region) => region.id).includes(sessionFilterRegion)
    );
    setSessionFilters((prevState) => ({
      ...prevState,
      regionIds:
        regionIdsOnSelectionChange.length > 0
          ? regionIdsOnSelectionChange
          : [0],
    }));
  }, [selectedRegions]);

  const sessionColumns: ColumnDef<
    SessionsPaginatedQuery["sessionsPaginated"]["sessions"][0]
  >[] = [
    {
      accessorKey: "id",
      header: () => <div className="text-center">Session Id</div>,
      cell: ({ row }) => {
        return <p className="font-medium text-center">{row.getValue("id")}</p>;
      },
    },
    {
      accessorKey: "region.name",
      header: "Region",
    },
    {
      accessorKey: "league.name",
      header: "League",
    },
    {
      accessorKey: "dayOfWeek",
      header: () => <div className="text-left">Day of Week</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">{DayOfWeek[row.original.dayOfWeek]}</p>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: () => <div className="text-left">Start Date</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">
            {dayjs(row.original.startDate).format("YYYY-MM-DD")}
          </p>
        );
      },
    },
    {
      accessorKey: "setCapacity",
      header: () => (
        <Tooltip
          trigger="Set Capacity"
          hoverText="This is the set number of teams we have for sale, including free agent teams."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.getValue("setCapacity")}
          </p>
        );
      },
    },
    {
      accessorKey: "indyTeams",
      header: () => (
        <Tooltip
          trigger="Set FA Teams"
          hoverText="This is the total number of free agent teams we have available for sale. The gender capacity for each free agent team is set at the sport format."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">{row.getValue("indyTeams")}</p>
        );
      },
    },
    {
      accessorKey: "teamCount",
      header: () => (
        <Tooltip
          trigger="Team Regs"
          hoverText="This is the total number of team registrations for this session."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">{row.getValue("teamCount")}</p>
        );
      },
    },
    {
      accessorKey: "freeAgentCount",
      header: () => (
        <Tooltip
          trigger="Free Agents Regs"
          hoverText="This is the total number of free agent registrations. (T: Total M: Men + Trans Men, W: Women + Trans Women, X: All other genders)"
        />
      ),

      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-4 font-medium text-left">
            <p>T{row.original.freeAgentCount?.totalCount}</p>
            <p>M{row.original.freeAgentCount?.men}</p>
            <p>W{row.original.freeAgentCount?.women}</p>
            <p>X{row.original.freeAgentCount?.other}</p>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a
                  href={`/ops/free-agent-teams?regBatchId=${row.original.registrationBatch.id}&sessionId=${row.original.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    FA teams
                  </DropdownMenuItem>
                </a>
                <a
                  href={`/ops/division/?regBatchId=${row.original.registrationBatch.id}&sessionId=${row.original.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Create divisions
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/session/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Edit Session
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/session-hq/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Session HQ
                  </DropdownMenuItem>
                </a>
                <a
                  href={`/players/waitlist?leagueId=${
                    row.original.league.id
                  }&startDate=${dayjs(row.original.startDate).format(
                    "YYYY-MM-DD"
                  )}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    View Waitlist
                  </DropdownMenuItem>
                </a>
                <a
                  href={`${rootUrl}/program-discovery/league-details/${row.original.league.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Preview League
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger
                className="outline-none text-primary-40 data-[state=open]:text-primary-90"
                disabled={row.original.divisions.length === 0}
              >
                {row.original.divisions.length === 0 ? (
                  <Calendar className="text-disabled" />
                ) : (
                  <CalendarCheck className="transition-colors hover:text-primary-90 text-inherit" />
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {row.original.divisions.map((division) => {
                  return (
                    <DropdownMenuItem
                      key={division.id}
                      className="hover:bg-primary-95"
                    >
                      <a href={`/ops/division/${division.id}/schedule`}>
                        {division.name}
                      </a>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return (
    <main className="flex flex-col justify-between w-full gap-8 pb-4">
      <LoadingDialog open={loadingSessions} />
      <Headline1Variable>League Wayfinder</Headline1Variable>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-3 gap-2">
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    registrationBatchId: +value,
                  };
                });
              }}
              label="Registration Batch"
              placeholder="Registration Batch"
              value={sessionFilters.registrationBatchId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...registrationBatches]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    regionIds: [+value],
                  };
                });
              }}
              label="Region"
              placeholder="Region"
              value={sessionFilters.regionIds[0]?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...selectedRegions]}
            </FormFieldSelect>
          </div>
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    sportId: +value,
                  };
                });
              }}
              label="Sport"
              placeholder="Sport"
              value={sessionFilters.sportId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...sports]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    dayOfWeek: +value,
                  };
                });
              }}
              label="Day Of Week"
              placeholder="Day Of Week"
              value={sessionFilters.dayOfWeek?.toString() ?? "-1"}
            >
              {[{ id: "-1", name: "All" }, ...weekdaysArray]}
            </FormFieldSelect>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <FormFieldSelectMultiSearch
              label="Select Leagues"
              name="leagueIds"
              maxCount={2}
              options={[
                ...(dataLeagues?.leagueDynamic.map((league) => {
                  return { value: `${league.id}`, label: league.name };
                }) || []),
              ]}
              values={
                sessionFilters.leagueIds
                  ? sessionFilters.leagueIds.map((id) => id.toString())
                  : []
              }
              onValueChange={(values) => {
                setSessionFilters((prevState) => {
                  return {
                    ...prevState,
                    leagueIds: values.map((value) => +value),
                  };
                });
              }}
              placeholder="Select League"
            />
          </div>
          <div className="flex items-end">
            <Button
              variant="secondary"
              className="w-full h-10"
              onClick={() => {
                setSessionFilters(initialSessionFilters);
              }}
            >
              Reset All Filters
            </Button>
          </div>
        </div>
        <Subtitle1>Start Date Range</Subtitle1>
        <div className="grid items-end grid-cols-3 gap-2">
          <FormFieldDate
            label="Start*"
            initialValue={dayjs(sessionFilters.startDate).toDate()}
            dateChange={(date) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  startDate: dayjs(date).format("YYYY-MM-DD"),
                };
              });
            }}
          />
          <FormFieldDate
            label="End"
            initialValue={
              sessionFilters.endDate
                ? dayjs(sessionFilters.endDate).toDate()
                : undefined
            }
            dateChange={(date) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  endDate: dayjs(date).format("YYYY-MM-DD"),
                };
              });
            }}
          />
          <div className="flex flex-row w-full gap-4">
            <Button
              variant="secondary"
              className="w-full h-10"
              onClick={() => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    startDate: initialSessionFilters.startDate,
                    endDate: null,
                  };
                });
              }}
            >
              Reset Dates
            </Button>
          </div>
        </div>
      </div>
      {sessionData.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={sessionData}
            columns={sessionColumns}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default Wayfinder;
