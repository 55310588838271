export function numberToMoney(number: number, currency?: string): string {
  if (number <= 0) {
    return new Intl.NumberFormat("en-CA", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
      currency: currency ? currency : "CAD",
      currencyDisplay: currency ? "code" : undefined,
    }).format(0);
  }

  return new Intl.NumberFormat("en-CA", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: currency ? currency : "CAD",
    currencyDisplay: currency ? "code" : undefined,
  }).format(number);
}
