import { Box, Button, IconButton, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import { Column, Row, useTable } from "react-table";
import { Session, UserAccount } from "../../types/types";
import dayjs from "dayjs";
import { GraphQLError } from "graphql";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import FormField from "../UI/FormField/FormField";
import CheckBox from "../UI/Checkbox/Checkbox";
import BaseTable from "../UI/Table/Table";
import ActionsCell from "../UI/ActionCell";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import ButtonUI from "../UI/Button/Button";
import SearchIcon from "@mui/icons-material/Search";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { CircularProgress } from "@mui/material";
import { DataTable } from "../UI/Table/ShadcnTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  GroupAddOutlined,
  PersonAddAlt1Outlined,
  TextSnippetOutlined,
} from "@mui/icons-material";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { Loader2, MoreVerticalIcon } from "lucide-react";
import { getRefundReasons, getRefundTypes } from "../../app/financeSlice";
import TextArea from "../UI/FormField/TextArea/TextAreaWithCounter";
import { z } from "zod";
import Card from "../UI/Card/Card";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import {
  GetSessionByDayAndLeagueSearchQuery,
  Region,
  useGetSessionByDayAndLeagueSearchLazyQuery,
  useLeagueTransferMutation,
  useRefundMutation,
  useRemoveRegistrationMutation,
  useShoppingCartItemRemoveLeagueTransferMutation,
  useShoppingCartLazyQuery,
  ShoppingCartQuery,
  useSendTransactionReceiptEmailMutation,
  useGetTransactionReceiptEmailLazyQuery,
  useCreditsByUserIdLazyQuery,
  CreditsByUserIdQuery,
  useCreditPurchaseMutation,
} from "../../../src/generated/graphql";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CancelIcon from "@mui/icons-material/Cancel";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";
import { numberToMoney } from "../../utils/financialHelpers";
import { baseURL } from "../../utils/baseUrl";
import { emailButton } from "../Marketing/Email/EmailButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";

type getSessionsByDayAndSearchInput = {
  dayOfWeek: number;
  leagueSearch: string;
  regions: number[];
};

enum indyOrTeam {
  indy = 1,
  team = 2,
}

enum daysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

// Define the original type
type ShoppingCartItem =
  ShoppingCartQuery["shoppingCart"]["shoppingCartItems"][0];

type ShoppingCartTransaction =
  ShoppingCartQuery["shoppingCart"]["shoppingCartTransactions"][0];

type ShoppingCartUser = ShoppingCartQuery["shoppingCart"]["user"];

type SessionsForLeagueTransfer =
  GetSessionByDayAndLeagueSearchQuery["getSessionByDayAndLeagueSearch"][0];

// Credit refund additional fields
interface AdditionalFields {
  itemTotalBalance: number;
  totalValue: number;
}

// Combine the original type with the additional fields
type ExtendedShoppingCartItem = ShoppingCartItem & AdditionalFields;

// Define the type for the Cell parameter
interface CellParam {
  row: { original: ExtendedShoppingCartItem };
  rows: { original: ExtendedShoppingCartItem }[];
}
interface TransactionCellParam {
  row: { original: ShoppingCartTransaction };
  rows: { original: ShoppingCartTransaction }[];
}

interface CreditPurchase {
  currency: "CAD" | "USD";
  checked: boolean;
  creditId: number;
  expiryDate: Date;
  currentBalance: number;
  purchaseAmount: number;
}

const ShoppingCart: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const shoppingCartId = params.id;

  const pathsBreadcrumbs = [
    { name: "Shopping Carts", url: "/players/shopping-carts" },
    { name: "Shopping Cart Details", url: "" },
  ];

  const weekdaysArray: { id: number; name: string }[] = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  const RefundSchema = z.object({
    status: z.number(),
    amount: z.number().refine((value) => value > 0, {
      message: "Amount must be greater than 0",
      path: ["amount"],
    }),
    itemId: z.number().optional().nullable(),
    type: z.number().refine((value) => value != 0, {
      message: "You must select a refund method",
      path: ["type"],
    }),
    reason: z.number().refine((value) => value != 0, {
      message: "You must select a refund reason",
      path: ["reason"],
    }),
    notes: z.string().optional(),
    requestedBy: z.string().nullable().optional(),
    requestedDatetime: z.string().nullable().optional(),
    txnId: z.number().nullable(),
  });
  type RefundType = z.infer<typeof RefundSchema>;

  const { isLoading, selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const {
    isLoading: isLoadingFinance,
    refundReasons,
    refundTypes,
  } = useSelector((state: RootState) => state.finance);

  const { user: userAdmin } = useSelector((state: RootState) => state.auth);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const refundInputInitial: RefundType = {
    status: 1,
    type: 0,
    reason: 0,
    amount: 0,
    itemId: 0,
    requestedBy: "",
    requestedDatetime: "",
    txnId: null,
  };

  const [transactions, setTransactions] = useState<ShoppingCartTransaction[]>(
    []
  );
  const [shoppingCartItems, setShoppingCartItems] = useState<
    ExtendedShoppingCartItem[]
  >([]);
  const [shoppingCart, setShoppingCart] =
    useState<ShoppingCartQuery["shoppingCart"]>();
  const [user, setUser] = useState<ShoppingCartUser>({
    id: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [queriedSessions, setQueriedSessions] = useState<
    SessionsForLeagueTransfer[]
  >([]);

  const [shoppingCartCCPurchaseTotal, setShoppingCartCCPurchaseTotal] =
    useState(0);
  const [selectedItem, setSelectedItem] = useState<
    ExtendedShoppingCartItem | undefined
  >(undefined);
  // const [isVoid, setIsVoid] = useState(false);
  const [refundInput, setRefundInput] =
    useState<RefundType>(refundInputInitial);
  const [refundErrors, setRefundErrors] = useState<{
    [key: string]: string;
  }>({
    amount: "",
    type: "",
    reason: "",
  });
  const [open, setOpen] = useState(false);
  const [maxRefundValue, setMaxRefundValue] = useState<{
    creditCard: number;
    credit: number;
  }>({
    creditCard: 0,
    credit: 0,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedItem(undefined);
    setOpen(false);
    setErrorMessageRefund("");
    setRemoveReg(false);
    setRefundInput(refundInputInitial);
  };
  const [removeReg, setRemoveReg] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [errorMessageRefud, setErrorMessageRefund] = useState("");
  const [queryMessage, setQueryMessage] = useState<string>("");
  const [sessionFilter, setSessionFilter] =
    useState<getSessionsByDayAndSearchInput>({
      regions: selectedRegions.map((region: any) => +region.id),
      dayOfWeek: 7,
      leagueSearch: "",
    });
  const [openLeagueTransfer, setOpenLeagueTransfer] = useState(false);
  const [openLeagueTransferDetails, setOpenLeagueTransferDetails] =
    useState(false);
  const [leagueTransfer, setLeagueTransfer] = useState<any>({});
  const [refundUrl, setRefundUrl] = useState<string | null>(null);
  const [openLeagueTransferURL, setOpenLeagueTransferURL] = useState(false);
  const [openLeagueTransferConfirmation, setOpenLeagueTransferConfirmation] =
    useState(false);
  const [selectedLeaguetranserType, setSelectedLeagueTransferType] =
    useState("1");
  const [
    selectedLeaguetransferRefundType,
    setSelectedLeagueTransferRefundType,
  ] = useState("1");
  const [refunds, setRefunds] = useState<
    ShoppingCartQuery["shoppingCart"]["shoppingCartItems"][0]["refunds"]
  >([]);
  const [openRemoveRegValidation, setOpenRemoveRegValidation] =
    useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [openTxnInfoModal, setOpenTxnInfoModal] = useState<{
    type: "notes" | "resend receipt";
    open: boolean;
    title: string;
    txnId?: number;
    description: string[];
  }>({
    open: false,
    type: "notes",
    title: "",
    description: [""],
  });

  useEffect(() => {
    console.log(maxRefundValue);
  }, [maxRefundValue]);
  useEffect(() => {
    console.log(refundInput);
  }, [refundInput]);
  useEffect(() => {
    console.log(refundTypes);
  }, [refundTypes]);
  const [selectedEmail, setSelectedEmail] = useState<any>({});
  const [openCreditPurchaseModal, setOpenCreditPurchaseModal] = useState(false);
  const [credits, setCredits] = useState<CreditPurchase[]>([]);

  const [CreateRefund, { loading: loadingRefund }] = useRefundMutation();

  const [RemoveRegistration, { loading: loadingRemoveReg }] =
    useRemoveRegistrationMutation();

  const [getSessionsByDayAndSearch, { loading: sessionLoading }] =
    useGetSessionByDayAndLeagueSearchLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [CreateLeagueTransfer, { loading: loadingLeagueTransfer }] =
    useLeagueTransferMutation();

  const [deleteShoppingCartItem, { loading: loadingDelete }] =
    useShoppingCartItemRemoveLeagueTransferMutation({
      fetchPolicy: "no-cache",
    });

  const [createCreditPurchase, { loading: loadingCreditPurchase }] =
    useCreditPurchaseMutation();

  const [shoppingCartById] = useShoppingCartLazyQuery({
    onCompleted: (data) => {
      //Set User
      const shoppingCartData = data.shoppingCart;
      setUser(shoppingCartData.user);

      if (shoppingCartData && shoppingCartData.shoppingCartTransactions) {
        const scTransactions = [...shoppingCartData.shoppingCartTransactions];

        let shoppingCartCCPurchaseTotal = 0;

        shoppingCartData.shoppingCartTransactions.forEach((cartTransaction) => {
          if (cartTransaction.transactionType.id === 1) {
            shoppingCartCCPurchaseTotal += cartTransaction.transactionAmount;
          } else if (
            cartTransaction.transactionType.id === 2 ||
            cartTransaction.transactionType.id === 3 ||
            cartTransaction.transactionType.id === 4
          ) {
            shoppingCartCCPurchaseTotal -= cartTransaction.transactionAmount;
          }
        });
        setRefunds([]);
        const shoppingCartItemsArray: ExtendedShoppingCartItem[] =
          shoppingCartData.shoppingCartItems.map((shoppingCartItem) => {
            if (
              shoppingCartItem.refunds &&
              shoppingCartItem.refunds.length > 0
            ) {
              shoppingCartItem.refunds.forEach((refund) => {
                setRefunds((prev) => [...prev, refund]);
              });
            }

            return {
              ...shoppingCartItem,
              itemTotalBalance: 0,
              totalValue: 0,
            };
          });

        //Append total row shopping cart items table
        setShoppingCartItems(shoppingCartItemsArray);

        setShoppingCartCCPurchaseTotal(+shoppingCartCCPurchaseTotal.toFixed(2));
        setTransactions(scTransactions);

        // set the shopping cart
        setShoppingCart(shoppingCartData);

        // const voidTransaction = scTransactions.find((transactions: any) => transactions.transactionType?.name === 'Void');
        // if (voidTransaction) setIsVoid(true);
      }
    },
    fetchPolicy: "no-cache",
  });

  const [getCreditsByUserId, { data: dataCredits, loading: loadingCredits }] =
    useCreditsByUserIdLazyQuery({
      variables: {
        userId: shoppingCart?.user.id || "",
        active: true,
      },
      onCompleted: (data) => {
        if (data && data.creditsByUserId) {
          setCredits(
            data.creditsByUserId.map((credit) => {
              return {
                currency: credit.currency as "CAD" | "USD",
                checked: false,
                creditId: credit.id,
                expiryDate: new Date(credit.expiryDate),
                currentBalance: credit.currentBalance,
                purchaseAmount: credit.currentBalance,
              };
            })
          );
        }
      },
      fetchPolicy: "no-cache",
    });

  const [sendTransactionReceipt, { loading: loadingSendReceipt }] =
    useSendTransactionReceiptEmailMutation();

  const [getTransactionReceiptEmail, { loading: loadingEmail }] =
    useGetTransactionReceiptEmailLazyQuery({
      fetchPolicy: "network-only",
    });

  if (iframeRef.current && iframeRef && selectedEmail.body) {
    const formattedBody = `${selectedEmail.body.replace(
      /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
      (_: any, link: string, text: string) => {
        return emailButton(link, text);
      }
    )}`;
    const blob = new Blob([formattedBody], { type: "text/html" });
    iframeRef.current.src = URL.createObjectURL(blob);
  }

  useEffect(() => {
    if (selectedEmail.body && openTxnInfoModal.open) {
      iframeRef.current?.focus();
      setTimeout(() => {
        if (iframeRef.current) {
          const formattedBody = `${selectedEmail.body.replace(
            /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
            (_: any, link: string, text: string) => {
              return emailButton(link, text);
            }
          )}`;
          const blob = new Blob([formattedBody], { type: "text/html" });
          iframeRef.current.src = URL.createObjectURL(blob);
        }
      }, 1000);
    }
  }, [selectedEmail, openTxnInfoModal.open]);

  useEffect(() => {
    if (shoppingCartId) {
      shoppingCartById({
        variables: {
          shoppingCartId: +shoppingCartId,
        },
      });
    }
    dispatch(getRefundReasons());
    dispatch(getRefundTypes());
  }, []);

  useEffect(() => {
    setSessionFilter({
      ...sessionFilter,
      regions: selectedRegions.map((region: Region) => +region.id),
    });
  }, [selectedRegions]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      setMaxRefundValue({
        creditCard: Math.min(
          selectedItem.amountPaid,
          shoppingCartCCPurchaseTotal
        ),
        credit: selectedItem.amountPaid,
      });
    }
  }, [selectedItem]);

  const refundConfirmation = (shoppingCartItemId: number) => {
    CreateRefund({
      variables: {
        refundArgs: {
          amount: +refundInput.amount.toFixed(2),
          itemId: +shoppingCartItemId,
          requestedBy: userAdmin?.id || "",
          requestedDatetime: new Date(),
          status: refundInput.status ? +refundInput.status : 1,
          reason: refundInput.reason,
          type: refundInput.type,
          txnId: refundInput.txnId ? +refundInput.txnId : null,
          notes: refundInput.notes
            ? refundInput.notes.substring(0, 255)
            : undefined,
        },
        removeRegistration: removeReg,
      },
      onCompleted: (data) => {
        if (data.refund) {
          dispatch(displayAlertSuccess("Refund Created"));
          setOpenValidation(false);
          handleClose();
          setRefundInput(refundInputInitial);
          if (shoppingCartId)
            shoppingCartById({
              variables: {
                shoppingCartId: +shoppingCartId,
              },
            });
        }
      },
      onError(error, clientOptions) {
        setErrorMessageRefund(error.message);
        setOpenValidation(false);
      },
    });
  };

  const handleSelectedOption = (
    item: ExtendedShoppingCartItem,
    idx: number
  ) => {
    if (idx === 1) {
      let total_transactions = item.amountPaid || 0;
      setSelectedItem({
        ...item,
        itemTotalBalance: +total_transactions.toFixed(2),
      });
      handleOpen();
    }
    if (idx === 2) {
      const regionId = item.session
        ? item.session.league?.sportFormat?.region?.id
        : item.tournament
        ? item.tournament.sportFormat?.region?.id
        : 0;
      setOpenLeagueTransfer(true);
      setSelectedItem({
        ...item,
        totalValue: +(item.itemAmount + item.tax).toFixed(2),
      });
      setSessionFilter({
        ...sessionFilter,
        regions: [regionId],
      });
    }
    if (idx === 3) {
      const leagueTransferUrl = `${baseURL}/league-transfer/${item.leagueTransferKey}`;
      setOpenLeagueTransferURL(true);
      setRefundUrl(leagueTransferUrl);
    }

    if (idx === 4) {
      if (shoppingCartId)
        handleRemoveShoppingCartItem(+shoppingCartId, +item.id);
    }

    if (idx === 5) {
      setOpenRemoveRegValidation(true);
      setSelectedItem(item);
    }

    if (idx === 6) {
      setOpenCreditPurchaseModal(true);
      getCreditsByUserId();

      setSelectedItem(item);
    }
  };

  const handleRemoveShoppingCartItem = async (
    shoppingCartId: number,
    shoppingCartItemId: number
  ) => {
    await deleteShoppingCartItem({
      variables: {
        shoppingCartItemId: shoppingCartItemId,
        shoppingCartId: shoppingCartId,
      },
    })
      .then((res: any) => {
        shoppingCartById({
          variables: {
            shoppingCartId: shoppingCartId,
          },
        });
        dispatch(displayAlertSuccess("League transfer removed"));
      })
      .catch((error: GraphQLError) => {
        dispatch(displayAlertError(error.message));
      });
  };

  const handleTransferLeague = () => {
    const result = RefundSchema.safeParse(refundInput);
    if (!selectedItem) {
      dispatch(displayAlertError("Please select an item to transfer"));
      return;
    }
    if (
      +(leagueTransfer.totalValue - selectedItem.amountPaid).toFixed(2) < 0 &&
      selectedLeaguetransferRefundType === "1"
    ) {
      if (!result.success) {
        const newFormErrors = { ...refundErrors };
        const newFormErrorKeys = Object.keys(newFormErrors);
        let messages: string[] = [];
        newFormErrorKeys.forEach((field) => {
          const fieldError = result.error.errors.find(
            (error: any) => error.path[0] === field
          );
          if (fieldError) {
            newFormErrors[field] = fieldError.message; // Set the error message
            messages.push(`Error ${field}: ${fieldError.message}`);
          } else {
            newFormErrors[field] = ""; // No matching error, set to an empty string
          }
        });
        setRefundErrors(newFormErrors);
        return;
      }
    }
    CreateLeagueTransfer({
      variables: {
        shoppingCartItemId: +selectedItem.id,
        newSessionId: +leagueTransfer.id,
        userId: user?.id,
        overwriteTransfer: selectedLeaguetranserType === "2" ? true : false,
        refundArgs: {
          ...refundInput,
          amount: +(
            selectedItem.amountPaid - leagueTransfer.totalValue
          ).toFixed(2),
          itemId: null,
          requestedBy: user?.id || "",
          requestedDatetime: new Date(),
        },
        overwriteRefund:
          selectedLeaguetransferRefundType === "2" ? true : false,
      },
    })
      .then((res: any) => {
        if (res.data.leagueTransfer.leagueTransferURL) {
          shoppingCartById({
            variables: {
              shoppingCartId: +shoppingCartId!,
            },
          });
          dispatch(displayAlertSuccess("League Transfer URL created"));
          setRefundUrl(res.data.leagueTransfer.leagueTransferURL);
        } else {
          shoppingCartById({
            variables: {
              shoppingCartId: +shoppingCartId!,
            },
          });
          dispatch(displayAlertSuccess("League Transfer Succesfully made"));
          setOpenLeagueTransferDetails(false);
          setSelectedLeagueTransferType("1");
          setOpenLeagueTransferConfirmation(false);
        }
        setOpenLeagueTransfer(false);
        setRefundInput(refundInputInitial);
      })
      .catch((error: GraphQLError) => {
        dispatch(displayAlertError(error.message));
      });
  };

  const searchSessions = async () => {
    if (queryMessage) setQueryMessage("");
    const { dayOfWeek, regions, leagueSearch } = sessionFilter;
    const res = await getSessionsByDayAndSearch({
      variables: {
        dayOfWeek: dayOfWeek,
        regions: regions,
        leagueSearch: leagueSearch,
        userId: user?.id!,
      },
      onCompleted: (data) => {
        if (data.getSessionByDayAndLeagueSearch.length === 0) {
          setQueriedSessions([]);
          setQueryMessage("No sessions found");
        } else {
          setQueriedSessions(data.getSessionByDayAndLeagueSearch);
        }
      },
    });
  };

  const refund = (shoppingCartItemId: number) => {
    const result = RefundSchema.safeParse(refundInput);
    if (refundInput.type === 1 && maxRefundValue.creditCard === 0) {
      dispatch(displayAlertError("No CC refund available"));
      return;
    }
    if (
      refundInput.type === 1 &&
      +refundInput.amount > maxRefundValue.creditCard
    ) {
      dispatch(
        displayAlertError(
          "CC refund amount cannot be greater than max CC refund amount."
        )
      );
      return;
    } else if (
      refundInput.type === 2 &&
      +refundInput.amount > maxRefundValue.credit
    ) {
      dispatch(
        displayAlertError(
          "Credit refund amount cannot be greater than amount paid."
        )
      );
      return;
    }
    if (result.success) {
      if (
        (selectedItem &&
          +refundInput.amount === maxRefundValue.credit && //Max amount possible
          !selectedItem.registration?.isDeleted) ||
        removeReg
      ) {
        setOpenValidation(true);
        return;
      } else {
        refundConfirmation(shoppingCartItemId);
      }
    } else {
      const newFormErrors = { ...refundErrors };
      const newFormErrorKeys = Object.keys(newFormErrors);
      let messages: string[] = [];
      newFormErrorKeys.forEach((field) => {
        const fieldError = result.error.errors.find(
          (error: any) => error.path[0] === field
        );
        if (fieldError) {
          newFormErrors[field] = fieldError.message; // Set the error message
          messages.push(`Error ${field}: ${fieldError.message}`);
        } else {
          newFormErrors[field] = ""; // No matching error, set to an empty string
        }
      });
      setRefundErrors(newFormErrors);
    }
  };

  const handleRemoveRegistration = () => {
    if (!selectedItem) {
      dispatch(displayAlertError("Please select an item to remove"));
      return;
    }
    RemoveRegistration({
      variables: {
        shoppingCartItemId: +selectedItem.id,
        productTypeId: selectedItem.productTypeId,
      },
    })
      .then(() => {
        dispatch(displayAlertSuccess("Registration Removed"));
        setOpenRemoveRegValidation(false);
        setSelectedItem(undefined);
        if (shoppingCartId)
          shoppingCartById({
            variables: {
              shoppingCartId: +shoppingCartId,
            },
          });
      })
      .catch((error: GraphQLError) => {
        dispatch(displayAlertError(error.message));
      });
  };

  const handleCreditPurchase = () => {
    if (!selectedItem) {
      dispatch(displayAlertError("Please select an item to purchase credits"));
      return;
    }
    const selectedCredits = credits.filter((credit) => credit.checked);
    if (selectedCredits.length === 0) {
      dispatch(displayAlertError("Please select a credit to purchase"));
      return;
    }
    selectedCredits.map((credit) => {
      if (credit.purchaseAmount > credit.currentBalance) {
        dispatch(
          displayAlertError(
            `Purchase amount cannot be greater than current balance for credit ${credit.creditId}`
          )
        );
        return;
      }
    });

    createCreditPurchase({
      variables: {
        creditPurchaseArgs: {
          shoppingCartItemId: selectedItem.id,
          credits: selectedCredits.map((credit) => {
            return {
              creditId: credit.creditId,
              purchaseAmount: credit.purchaseAmount,
            };
          }),
        },
      },
      onCompleted: (data) => {
        if (!data.creditPurchase.success)
          dispatch(displayAlertError(data.creditPurchase.message));
        dispatch(displayAlertSuccess("Credit Purchase Successful"));
        setOpenCreditPurchaseModal(false);
        setSelectedItem(undefined);
        setCredits([]);
        if (shoppingCartId)
          shoppingCartById({
            variables: {
              shoppingCartId: +shoppingCartId,
            },
          });
      },
    });
  };

  const COLUMNS: Column<ExtendedShoppingCartItem>[] = [
    {
      Header: "Item Id",
      id: "id",
      Cell: (d: CellParam) => {
        let transferIcon = false;
        let refundIcon = false;

        if (d.row.original.leagueTransferKey) transferIcon = true;
        const leagueTransferTxn = d.row.original.shoppingCartTransactions?.find(
          () =>
            d.row.original.shoppingCartTransactions.find(
              (
                txn: ShoppingCartQuery["shoppingCart"]["shoppingCartTransactions"][0]
              ) =>
                txn.transactionType.name === "League Transfer In" ||
                txn.transactionType.name === "League Transfer Out"
            )
        );
        if (leagueTransferTxn) transferIcon = true;
        const leagueTransferPurchase = d.rows.find(
          (item) =>
            String(item.original.leagueTransferIdOut) ===
            String(d.row.original.id)
        );
        if (leagueTransferPurchase) transferIcon = true;
        if (d.row.original.refunds?.length) refundIcon = true;
        return (
          <div className="flex gap-2">
            {d.row.original.id}
            {d.row.original.id && transferIcon && (
              <Tooltip title="League Transfer">
                <CompareArrowsOutlinedIcon />
              </Tooltip>
            )}
            {d.row.original.id && refundIcon && (
              <Tooltip title="Refund">
                <CurrencyExchangeIcon />
              </Tooltip>
            )}
            {d.row.original.id && d.row.original.registration?.isDeleted && (
              <Tooltip title="Registration Removed">
                <CancelIcon />
              </Tooltip>
            )}
          </div>
        );
      },
      Footer: () => <div>Totals</div>,
    },
    {
      Header: "Time Created",
      id: "createdAt",
      accessor: (d) => {
        return (
          <div>{d.id ? dayjs(d.createdAt).format("YYYY-MM-DD HH:mm") : ""}</div>
        );
      },
    },
    {
      Header: "Product Id",
      id: "productId",
      accessor: (d) => {
        return (
          <div>
            {d.session ? d.session.id : d.tournament ? d.tournament.id : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Product Type",
      id: "productType",
      accessor: (d) => {
        return <div>{d.productType?.name}</div>;
      },
    },
    {
      Header: "Description",
      id: "description",
      accessor: (d) => {
        return (
          <div>
            {d.session
              ? `${d.session!.registrationBatch.name} - ${
                  daysOfWeek[d.session.dayOfWeek]
                } - ${d.session.league.name}`
              : d.tournament
              ? `${d.tournament.name} - ${daysOfWeek[d.tournament.dayOfWeek]}`
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Amount",
      id: "itemAmount",
      accessor: (d) => {
        return <div>{numberToMoney(d.itemAmount)}</div>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.itemAmount, 0),
          [rows]
        );
        return <div>{numberToMoney(total)}</div>;
      },
    },
    {
      Header: "Discount",
      id: "discount",
      accessor: (d) => {
        return <div>{numberToMoney(d.discount)}</div>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.discount, 0),
          [rows]
        );
        return <div>{numberToMoney(total)}</div>;
      },
    },
    {
      Header: "Txn Fee",
      id: "txnFee",
      accessor: (d) => {
        return <div>{numberToMoney(d.txnFee ?? 0)}</div>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + (row.original.txnFee ?? 0), 0),
          [rows]
        );
        return <div>{numberToMoney(total)}</div>;
      },
    },
    {
      Header: "Tax",
      id: "tax",
      accessor: (d) => {
        return <div>{numberToMoney(d.tax)}</div>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.tax, 0),
          [rows]
        );
        return <div>{numberToMoney(total)}</div>;
      },
    },
    {
      Header: "Total",
      id: "total",
      accessor: (d) => {
        return (
          <div>
            {numberToMoney(d.itemAmount + (d.txnFee ?? 0) + d.tax - d.discount)}
          </div>
        );
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce(
              (sum, row) =>
                sum +
                row.original.itemAmount +
                (row.original.txnFee ?? 0) +
                row.original.tax -
                row.original.discount,
              0
            ),
          [rows]
        );
        return <div>{numberToMoney(total)}</div>;
      },
    },
    {
      Header: "Amount Paid",
      id: "balance",
      accessor: (d) => {
        return <>{numberToMoney(d.amountPaid)}</>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.amountPaid, 0),
          [rows]
        );
        return <div>{numberToMoney(total)}</div>;
      },
    },
  ];

  if (shoppingCart?.shoppingCartStatus?.name === "Complete") {
    COLUMNS.push({
      Header: "Actions",
      Cell: (d: CellParam) => {
        let options = [];
        let refundVisible = true;
        let inProgressRefund = false;
        d.row.original.refunds?.map(
          (
            refund: ShoppingCartQuery["shoppingCart"]["shoppingCartItems"][0]["refunds"][0]
          ) => {
            if (refund.status.id === 1) inProgressRefund = true;
          }
        );
        refundVisible = d.row.original.amountPaid > 0 && !inProgressRefund;
        const leagueTransferPurchase = d.rows.find(
          (item) =>
            item.original.leagueTransferIdOut === +d.row.original.id &&
            item.original.amountPaid === 0
        );
        if (refundVisible) options.push({ id: 1, text: "Refund" });
        if (
          d.row.original.amountPaid > 0 &&
          !leagueTransferPurchase &&
          !inProgressRefund &&
          (d.row.original.productTypeId === 1 ||
            d.row.original.productTypeId === 2)
        )
          options.push({ id: 2, text: "League Transfer" });
        if (
          d.row.original.leagueTransferKey &&
          d.row.original.amountPaid === 0
        ) {
          options.push({ id: 3, text: "League Transfer URL" });
          options.push({ id: 4, text: "Remove" });
        }
        // Remove Reg
        if (!d.row.original.registration?.isDeleted)
          options.push({ id: 5, text: "Remove Reg" });

        // Credit Purchase
        options.push({ id: 6, text: "Use Credit" });
        return (
          <div>
            {d.row.original.id && options.length > 0 && (
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <MoreVerticalIcon />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="flex flex-col gap-1">
                  {options.map((options) => {
                    return (
                      <DropdownMenuItem
                        className="hover:bg-gray-100"
                        onClick={() => {
                          handleSelectedOption(d.row.original, options.id);
                        }}
                      >
                        {options.text}
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        );
      },
    });
  }

  const COLUMNS_TRANSACTIONS: Column<ShoppingCartTransaction>[] = [
    {
      Header: "Transaction Id",
      id: "transactionId",
      accessor: (d) => {
        return <div>{d.id}</div>;
      },
      Footer: () => <div>Totals</div>,
    },
    {
      Header: "Time Created",
      id: "createdAt",
      accessor: (d) => {
        return (
          <div>{d.id ? dayjs(d.createdAt).format("YYYY-MM-DD HH:mm") : ""}</div>
        );
      },
    },
    {
      Header: "Transaction Type",
      id: "transactionType",
      accessor: (d) => {
        return <div>{d.transactionType?.name}</div>;
      },
    },
    {
      Header: "Transaction Status",
      id: "transactionStatus",
      accessor: (d) => {
        return <div>{d.id ? (d.success ? "Success" : "Failed") : ""}</div>;
      },
    },
    {
      Header: "Item Id",
      id: "itemId",
      accessor: (d) => {
        return (
          <div className="flex flex-col gap-1">
            {d.shoppingCartItems?.map((sci) => <p>{sci?.id}</p>) ?? "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Amount",
      id: "transactionAmount",
      accessor: (d) => {
        return (
          <div
            style={
              !d.transactionType?.name.includes("Purchase") &&
              !(d.transactionType?.name === "League Transfer In") &&
              d.id
                ? { color: "red" }
                : {}
            }
          >
            {" "}
            {!d.transactionType?.name.includes("Purchase") &&
            !(d.transactionType?.name === "League Transfer In") &&
            d.id
              ? "-"
              : ""}
            {numberToMoney(d.transactionAmount)}
          </div>
        );
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () =>
            rows.reduce((sum, row) => {
              if (
                (row.original.transactionType?.name.includes("Purchase") ||
                  row.original.transactionType?.name ===
                    "League Transfer In") &&
                row.original.success === true
              ) {
                return sum + row.original.transactionAmount;
              } else if (!row.original.success) {
                return 0;
              } else {
                return sum - row.original.transactionAmount;
              }
            }, 0),
          [rows]
        );
        return <div>{numberToMoney(total)}</div>;
      },
    },
    {
      Header: "Actions",
      Cell: (d: TransactionCellParam) => {
        const options = [{ id: 1, text: "Notes" }];
        if (
          (d.row.original.transactionType.name.includes("Purchase") ||
            d.row.original.transactionType.name.includes("Refund")) &&
          d.row.original.success
        ) {
          options.push({ id: 2, text: "Resend Receipt" });
        }
        return (
          <div>
            <ActionsCell
              row={d.row.original}
              options={options}
              handleSelectedOption={async (rowData, idx) => {
                if (idx === 1) {
                  setOpenTxnInfoModal({
                    open: true,
                    type: "notes",
                    title: `Transaction Type - ${d.row.original.transactionType?.name}`,
                    description: [
                      `Gateway Message: ${
                        d.row.original.gatewayMessage || "N/A"
                      }`,
                      `OrderId: ${d.row.original.orderId || "N/A"}`,
                    ],
                    txnId: undefined,
                  });
                }
                if (idx === 2) {
                  setOpenTxnInfoModal({
                    open: true,
                    type: "resend receipt",
                    title: "Transaction Receipt Preview",
                    description: [""],
                    txnId: +rowData.id,
                  });

                  await getTransactionReceiptEmail({
                    variables: {
                      txnId: +rowData.id,
                    },
                    onCompleted: (data) => {
                      setSelectedEmail({
                        subject: "Transaction Receipt",
                        body: data.getTransactionReceiptEmail,
                      });
                    },
                  });
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  const COLUMNS_SHOPPING_CART: Column<ShoppingCartQuery["shoppingCart"]>[] = [
    {
      Header: "Shopping Cart ID",
      id: "id",
      accessor: (d) => {
        return <div>{d.id}</div>;
      },
    },
    {
      Header: "Time Created",
      id: "createdAt",
      accessor: (d) => {
        return (
          <div>{d.id ? dayjs(d.createdAt).format("YYYY-MM-DD HH:mm") : ""}</div>
        );
      },
    },
    {
      Header: "Player",
      id: "player",
      accessor: (d) => {
        return (
          <div>
            {`${d.user?.firstName} ${d.user?.lastName}`}
            {" ["}
            {
              <a
                className="underline cursor-pointer text-info-50"
                href={`/players/user/${d.user?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {d.user?.id}
              </a>
            }
            {"]"}
          </div>
        );
      },
    },
    {
      Header: "Last Active",
      id: "transactionStatus",
      accessor: (d) => {
        return (
          <div>
            {d.id ? dayjs(d.lastActive).format("YYYY-MM-DD HH:mm") : ""}
          </div>
        );
      },
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d) => {
        return <div>{d.shoppingCartStatus?.name}</div>;
      },
    },
  ];

  const queriedSessionsColumns: ColumnDef<SessionsForLeagueTransfer>[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      header: "Region",
      accessorKey: "league.sportFormat.region.name",
    },
    {
      accessorKey: "league.name",
      header: "League Name",
    },
    {
      header: "Reg Batch",
      accessorKey: "registrationBatch.name",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {row.original.registrationBatch.name}
          </div>
        );
      },
    },
    {
      header: "Reg Batch Dates",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            <p>
              {dayjs(row.original.registrationBatch.regOpenDate).format(
                "YYYY-MM-DD"
              )}
            </p>
            <p>
              {dayjs(row.original.registrationBatch.regCloseDate).format(
                "YYYY-MM-DD"
              )}
            </p>
          </div>
        );
      },
    },
    {
      header: "Day of Week",
      accessorKey: "dayOfWeek",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {weekdaysArray.find((day) => day.id === row.original.dayOfWeek)
              ?.name ?? row.original.dayOfWeek}
          </div>
        );
      },
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      cell: ({ row }) => {
        return (
          <div className="font-medium text-left">
            {dayjs(row.original.startDate).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      header: "Cost",
      accessorKey: "price",
      cell: ({ row }) => {
        return (
          <>
            {selectedItem ? (
              <div className="font-medium text-left">
                {+selectedItem.productTypeId === indyOrTeam.indy && (
                  <div>${row.original.priceForIndy}</div>
                )}
                {+selectedItem.productType?.id === indyOrTeam.team && (
                  <div>${row.original.priceForTeam}</div>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
    {
      header: "Transfer League",
      accessorKey: "availability.freeAgent",
      cell: ({ row }) => {
        return (
          <>
            {selectedItem ? (
              <div>
                {+selectedItem.productType?.id === indyOrTeam.indy && (
                  <ButtonUI
                    variant={
                      row.original.availability?.freeAgent !== "Available"
                        ? "disabled"
                        : "primary"
                    }
                    onClick={() => {
                      const txnFee =
                        row.original.league.sportFormat.region.txnFee.find(
                          (fee) => {
                            return fee.productTypeId === 1;
                          }
                        )?.amount ?? 0;
                      setLeagueTransfer({
                        ...row.original,
                        totalValue: +(
                          (row.original.priceForIndy + txnFee) *
                          (1 +
                            row.original.league?.sportFormat?.region?.taxRate)
                        ).toFixed(2),
                      });

                      setOpenLeagueTransferDetails(true);
                    }}
                    disabled={
                      row.original.availability?.freeAgent !== "Available"
                    }
                  >
                    <PersonAddAlt1Outlined
                      fontSize="large"
                      sx={{
                        width: "18px",
                        height: "18px",
                      }}
                    />
                  </ButtonUI>
                )}
                {+selectedItem.productType?.id === indyOrTeam.team && (
                  <ButtonUI
                    variant={
                      row.original.availability?.team !== "Available"
                        ? "disabled"
                        : "primary"
                    }
                    onClick={() => {
                      const txnFee =
                        row.original.league.sportFormat.region.txnFee.find(
                          (fee) => {
                            return fee.productTypeId === 2;
                          }
                        )?.amount ?? 0;
                      setLeagueTransfer({
                        ...row.original,
                        totalValue: +(
                          (row.original.priceForTeam + txnFee) *
                          (1 +
                            row.original.league?.sportFormat?.region?.taxRate)
                        ).toFixed(2),
                      });
                      setOpenLeagueTransferDetails(true);
                    }}
                    disabled={row.original.availability?.team !== "Available"}
                  >
                    <GroupAddOutlined
                      fontSize="large"
                      sx={{
                        width: "18px",
                        height: "18px",
                      }}
                    />
                  </ButtonUI>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
  ];

  const queriedSessionsData = useMemo(() => {
    const today = new Date();
    if (queriedSessions) {
      const filteredSessions = queriedSessions.filter((session) => {
        // If today is between the reg open and close date
        const regOpen = new Date(session.registrationBatch.regOpenDate);
        const regClose = new Date(session.registrationBatch.regCloseDate);
        return regOpen <= today && today <= regClose;
      });
      return filteredSessions;
    } else {
      return [];
    }
  }, [queriedSessions]);

  const columns = useMemo(() => COLUMNS, [shoppingCart]);
  const data = useMemo(() => shoppingCartItems, [shoppingCartItems]);

  const columns_transactions = useMemo(() => COLUMNS_TRANSACTIONS, []);
  const data_transactions = useMemo(() => transactions, [transactions]);

  const data_shoppingcart = useMemo(
    () => (shoppingCart ? [shoppingCart] : []),
    [shoppingCart]
  );

  const handleBlur = (e: any) => {
    var num = parseFloat(e.target.value);
    var cleanNum = num.toFixed(2);
    setRefundInput({
      ...refundInput,
      amount: Number(cleanNum),
    });
  };

  const ShoppingCartItemsTable = (
    <>
      <BaseTable
        columns={columns}
        data={data}
      />
      {refunds.length > 0 &&
        refunds.map((refund) => {
          return (
            <Card className="mt-2">
              {refund.status.id === 1 ? (
                <div>{`${refund.requestedBy?.firstName} ${refund.requestedBy?.lastName} has requested a ${refund.type.name} refund of $${refund.amount}. Reason: ${refund.reason.name} `}</div>
              ) : refund.status.id === 2 ? (
                <div>{`A ${refund.type.name} refund of $${refund.amount} requested by ${refund.requestedBy?.firstName} ${refund.requestedBy?.lastName} has been approved by ${refund.reviewedBy?.firstName} ${refund.reviewedBy?.lastName}. Reason: ${refund.reason.name} `}</div>
              ) : refund.status.id === 3 ? (
                <div>{`A ${refund.type.name} refund of $${refund.amount} requested by ${refund.requestedBy?.firstName} ${refund.requestedBy?.lastName} has been rejected by ${refund.reviewedBy?.firstName} ${refund.reviewedBy?.lastName}. Reason: ${refund.reason.name} `}</div>
              ) : (
                <></>
              )}
            </Card>
          );
        })}
    </>
  );

  const COLUMNS_CREDITS: Column<CreditPurchase>[] = [
    {
      Header: "",
      id: "checked",
      accessor: (d) => {
        return (
          <CheckBox
            checkedState={d.checked}
            inputChange={(val: boolean) => {
              setCredits((prev) =>
                prev.map((credit) => {
                  if (credit.creditId === d.creditId) {
                    return {
                      ...credit,
                      checked: val,
                    };
                  }
                  return {
                    ...credit,
                  };
                })
              );
            }}
          />
        );
      },
    },
    {
      Header: "Credit Id",
      id: "creditId",
      accessor: (d) => d.creditId,
    },
    {
      Header: "Expiry Date",
      id: "expiryDate",
      accessor: (d) => dayjs(d.expiryDate).format("YYYY-MM-DD"),
    },
    {
      Header: "Current Balance",
      id: "currentBalance",
      accessor: (d) => `$${d.currentBalance}`,
    },
    {
      Header: "Purchase Amount",
      id: "purchaseAmount",
      accessor: (d) => {
        return (
          <FormField
            disabled={!d.checked}
            initialValue={
              credits
                .find((credit) => credit.creditId === d.creditId)
                ?.currentBalance.toString() ?? "0"
            }
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCredits((prev) =>
                prev.map((credit) => {
                  if (credit.creditId === d.creditId) {
                    return {
                      ...credit,
                      purchaseAmount: +e.target.value,
                    };
                  }
                  return credit;
                })
              );
            }}
            type="number"
            name="credit amount"
            placeholder="Credit purchase"
            className="w-2/3 h-9"
            max={d.currentBalance}
            error={d.checked && d.purchaseAmount > d.currentBalance}
          />
        );
      },
    },
  ];

  const dataCreditsTable = useMemo(
    () =>
      credits.filter((credit) => {
        let currencyMatch = false;
        if (
          selectedItem?.productTypeId === 1 ||
          selectedItem?.productTypeId === 2
        ) {
          return (
            selectedItem.session!.league.sportFormat.region.currency ===
            credit.currency
          );
        } else if (
          selectedItem?.productTypeId === 3 ||
          selectedItem?.productTypeId === 4
        ) {
          return (
            selectedItem.tournament!.sportFormat.region.currency ===
            credit.currency
          );
        }
        return false;
      }),
    [credits, selectedItem]
  );

  const creditPurchaseTable = (
    <div className="w-[95%]">
      <BaseTable
        columns={COLUMNS_CREDITS}
        data={dataCreditsTable}
      />
    </div>
  );

  const ShoppingCartTransactionsTable = (
    <div className="w-[70%]">
      <BaseTable
        columns={columns_transactions}
        data={data_transactions}
      />
    </div>
  );

  const ShoppingCartDetailsTable = (
    <div className="w-[65%]">
      <BaseTable
        columns={COLUMNS_SHOPPING_CART}
        data={data_shoppingcart}
      />
    </div>
  );

  const searchDialogLeagueTransfer = () => {
    if (!selectedItem) {
      displayAlertError("Please select an item to transfer");
      return (
        <Modal
          open={openLeagueTransfer}
          sx={{
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "75%",
              minHeight: "50%",
              maxHeight: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              zIndex: 10,
            }}
          >
            <div className="flex flex-col justify-between w-full h-full gap-4">
              <div className="flex flex-col gap-4">
                Could not find item to transfer
              </div>
            </div>
          </Box>
        </Modal>
      );
    }

    const dayOfWeekId = selectedItem.session
      ? selectedItem.session.dayOfWeek
      : selectedItem.tournament
      ? selectedItem.tournament.dayOfWeek
      : undefined;
    const name = selectedItem.session
      ? selectedItem.session.league.name
      : selectedItem.tournament
      ? selectedItem.tournament.name
      : undefined;
    return (
      <Modal
        open={openLeagueTransfer}
        sx={{
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            minHeight: "50%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            zIndex: 10,
          }}
        >
          <div className="flex flex-col justify-between w-full h-full gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex gap-1">
                <div className="text-lg font-bold">Current League: </div>
                {dayOfWeekId ? daysOfWeek[dayOfWeekId] : ""} -{name ? name : ""}
              </div>
              <div className="flex flex-row items-end gap-4 ">
                <div className="w-64">
                  <FormFieldSelect
                    key={
                      sessionFilter.regions.length > 0
                        ? sessionFilter.regions[0]
                        : "0"
                    }
                    inputChange={(value) => {
                      setSessionFilter({
                        ...sessionFilter,
                        regions:
                          value === "0"
                            ? selectedRegions.map((region: any) => +region.id)
                            : [+value],
                      });
                    }}
                    label={"Region"}
                    placeholder="Select Region"
                    value={
                      sessionFilter.regions.length > 1
                        ? "0"
                        : sessionFilter.regions[0]?.toString()
                    }
                  >
                    {[{ id: 0, name: "All" }, ...selectedRegions]}
                  </FormFieldSelect>
                </div>
                <div className="w-64">
                  <FormFieldSelect
                    key={sessionFilter.dayOfWeek}
                    inputChange={(value) => {
                      setSessionFilter({
                        ...sessionFilter,
                        ["dayOfWeek"]: +value,
                      });
                    }}
                    label={"Day"}
                    placeholder="Select Day of Week"
                    value={sessionFilter.dayOfWeek.toString()}
                  >
                    {[{ id: 7, name: "All" }, ...weekdaysArray]}
                  </FormFieldSelect>
                </div>
                <div className="w-80 max-w-80">
                  <FormField
                    initialValue={sessionFilter.leagueSearch}
                    inputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSessionFilter({
                        ...sessionFilter,
                        leagueSearch: e.target.value,
                      })
                    }
                    name="leagueSearch"
                    placeholder="Search"
                    className="h-9"
                  ></FormField>
                </div>
                <div>
                  <ButtonUI
                    variant="primary"
                    onClick={() => searchSessions()}
                    className="w-60 max-h-9"
                  >
                    <div className="flex flex-row items-center gap-2">
                      <SearchIcon
                        fontSize="large"
                        sx={{
                          width: "18px",
                          height: "18px",
                        }}
                      />
                      <span>Search Session</span>
                    </div>
                  </ButtonUI>
                </div>
              </div>
              {queryMessage && <Subtitle1>{queryMessage}</Subtitle1>}
              {sessionLoading ? (
                <CircularProgress />
              ) : (
                <div className="max-h-[450px] overflow-scroll border rounded-md">
                  {queriedSessions.length > 0 && (
                    <DataTable
                      data={queriedSessionsData}
                      columns={queriedSessionsColumns}
                    />
                  )}
                </div>
              )}
            </div>
            <ButtonUI
              variant="secondary"
              onClick={() => {
                setOpenLeagueTransfer(false);
                setQueriedSessions([]);
              }}
            >
              Close
            </ButtonUI>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <main className="pb-10">
      <LoadingDialog
        open={
          loadingRefund ||
          isLoading ||
          loadingDelete ||
          loadingRemoveReg ||
          loadingCreditPurchase
        }
      />
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/venues"
      ></BreadCrumbs>
      <Headline1Variable>
        Shopping Cart History - {shoppingCartId}
      </Headline1Variable>
      <div className="flex flex-col gap-3 mt-2">
        <Headline2Variable>Shopping Cart Details</Headline2Variable>
        {shoppingCart && ShoppingCartDetailsTable}
        <div className="flex items-center gap-2">
          <Headline2Variable>Items</Headline2Variable>
          <IconButton
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              border: "1px solid #585858",
              padding: "12px",
            }}
            onClick={() => {
              setOpenInfoModal(true);
            }}
          >
            <QuestionMarkIcon />
          </IconButton>
        </div>
        {shoppingCart &&
          shoppingCart.shoppingCartItems &&
          ShoppingCartItemsTable}
        <Headline2Variable>Transactions</Headline2Variable>
        {transactions.length > 0 && ShoppingCartTransactionsTable}
      </div>
      {searchDialogLeagueTransfer()}
      {selectedItem !== undefined && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            zIndex: 10,
          }}
        >
          <Box sx={style}>
            <h3>Refund Shopping Cart Item </h3>
            {errorMessageRefud && (
              <h5 className="mb-2 text-red-500">{errorMessageRefud}</h5>
            )}
            <div className="flex">
              <h6 className="w-1/2">Item Total:</h6>
              <p className="">
                {" "}
                {numberToMoney(
                  selectedItem.itemAmount +
                    (selectedItem.txnFee ?? 0) +
                    selectedItem.tax -
                    selectedItem.discount
                )}{" "}
              </p>
            </div>
            <div className="flex">
              <h6 className="w-1/2">Amount Paid:</h6>
              <p className="">
                {" "}
                {numberToMoney(selectedItem.itemTotalBalance)}{" "}
              </p>
            </div>
            <div className="flex">
              {refundInput.type !== 0 ? (
                <>
                  <h6 className="w-1/2">{`${
                    refundInput.type === 1
                      ? `${
                          maxRefundValue.creditCard > 0
                            ? "Maximum credit card refund:"
                            : "Credit card refund unavailable"
                        }`
                      : "Maximum credit refund:"
                  } `}</h6>
                  <h6 className="w-1/2">
                    {refundInput.type === 1
                      ? `${
                          maxRefundValue.creditCard > 0
                            ? numberToMoney(maxRefundValue.creditCard)
                            : ""
                        }`
                      : numberToMoney(maxRefundValue.credit)}
                  </h6>
                </>
              ) : (
                <h6 className="w-1/2">Select a refund type</h6>
              )}
            </div>
            <div className="flex my-4">
              <FormField
                type="number"
                name="Value"
                initialValue={refundInput.amount.toString()}
                error={refundErrors["amount"] ? true : false}
                assistiveText={
                  refundErrors["amount"] ? refundErrors["amount"] : ""
                }
                inputChange={(e) =>
                  setRefundInput((prevState) => ({
                    ...prevState,
                    amount: e.target.value,
                  }))
                }
                onBlur={handleBlur}
                label="Refund Amount:"
                width="100%"
                max={
                  refundInput.type === 1
                    ? maxRefundValue.creditCard
                    : maxRefundValue.credit
                }
              />
            </div>
            <div className="flex my-4">
              <FormFieldSelect
                name="Value"
                value={refundInput.type.toString()}
                error={refundErrors["type"] ? true : false}
                assistiveText={refundErrors["type"] ? refundErrors["type"] : ""}
                inputChange={(value) =>
                  setRefundInput((prevState) => ({
                    ...prevState,
                    type: +value,
                  }))
                }
                label="Refund Method"
                width="100%"
                placeholder="Select Refund Method"
              >
                {[{ id: 0, name: "Select Refund Method" }, ...refundTypes]}
              </FormFieldSelect>
            </div>
            <div className="flex my-4">
              <FormFieldSelect
                name="Value"
                value={refundInput.reason.toString()}
                error={refundErrors["reason"] ? true : false}
                assistiveText={
                  refundErrors["reason"] ? refundErrors["reason"] : ""
                }
                inputChange={(value) =>
                  setRefundInput({
                    ...refundInput,
                    reason: +value,
                  })
                }
                label="Refund Reason"
                width="100%"
                placeholder="Select Refund Reason"
              >
                {[
                  { id: 0, name: "Select Refund Reason" },
                  ...refundReasons
                    .filter((reason) => {
                      if (refundInput.type === 1) {
                        return reason.isRefund;
                      } else if (refundInput.type === 2) {
                        return reason.isCredit;
                      }
                    })
                    .sort((a, b) => (a.name > b.name ? 1 : -1)),
                ]}
              </FormFieldSelect>
            </div>
            <div className="flex my-4">
              <TextArea
                className="w-full"
                value={refundInput.notes ?? ""}
                id={"refundNotes"}
                maxInput={255}
                onChange={(e) => {
                  setRefundInput((prevState) => ({
                    ...prevState,
                    notes: e.target.value,
                  }));
                }}
                title="Refund Notes"
              />
            </div>
            <div className="flex flex-col gap-2 my-4">
              <CheckBox
                isDisabled={selectedItem.registration?.isDeleted || false}
                checkedState={removeReg}
                id="removeRegistration"
                label="Remove Registration"
                inputChange={(val: boolean) => {
                  setRemoveReg(val);
                }}
              />
              {selectedItem.registration?.isDeleted && (
                <Subtitle2 className="text-red-500 text-[10px]">
                  Registration has been removed
                </Subtitle2>
              )}
            </div>
            <div className="flex justify-end gap-3">
              <ButtonUI
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </ButtonUI>
              <ButtonUI
                variant="primary"
                onClick={() => {
                  refund(selectedItem.id);
                }}
              >
                Refund
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
      {selectedItem !== undefined && (
        <Modal
          open={openValidation}
          onClose={() => setOpenValidation(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h4>Refund Shopping Cart Item </h4>
            {+refundInput.amount === maxRefundValue.credit && !removeReg && (
              <p>
                You are about to refund the entire shopping cart item total. Are
                you sure you want to proceed without removing the registration?
              </p>
            )}
            {+refundInput.amount !== maxRefundValue.credit && removeReg && (
              <p>
                You are about to partially refund the shopping cart item and
                remove the registration. Are you sure you want to proceed?
              </p>
            )}
            {+refundInput.amount === maxRefundValue.credit && removeReg && (
              <p>
                You are about to refund the entire shopping cart item total and
                remove the registration. Are you sure?
              </p>
            )}
            <div className="flex justify-end gap-3">
              <ButtonUI
                variant="secondary"
                onClick={() => setOpenValidation(false)}
              >
                No
              </ButtonUI>
              <ButtonUI
                variant="primary"
                onClick={() => {
                  refundConfirmation(selectedItem.id);
                }}
              >
                Yes
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
      {selectedItem !== undefined && (
        <Modal
          open={openLeagueTransferDetails}
          onClose={() => {
            setOpenLeagueTransferDetails(false);
            setRefundUrl(null);
            setLeagueTransfer({});
            setSelectedItem(undefined);
            setSelectedLeagueTransferType("1");
            setSelectedLeagueTransferRefundType("1");
            setRefundInput(refundInputInitial);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            zIndex: 20,
          }}
        >
          <Box sx={style}>
            <h4>
              {selectedItem.productType?.name === "League Indy"
                ? " League Free Agent "
                : " League Team "}
              Transfer Details
            </h4>
            <div className="flex gap-5 mt-3">
              <div className="w-1/2">
                <h5>Current League:</h5>
                <p>
                  {selectedItem.session ? (
                    <>
                      {selectedItem.session.league?.sportFormat?.region?.name} -{" "}
                      {selectedItem.session.registrationBatch?.name} -
                      {daysOfWeek[selectedItem.session.dayOfWeek]} -
                      {selectedItem.session.league?.name}
                    </>
                  ) : selectedItem.tournament ? (
                    <>
                      {selectedItem.tournament?.sportFormat?.region?.name} -
                      {daysOfWeek[selectedItem.tournament.dayOfWeek]} -
                      {selectedItem.tournament.name}
                    </>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p className="mt-3">
                  Current Amount Paid: {selectedItem.amountPaid}
                </p>
              </div>
              <div className="w-1/2">
                <h5>New League:</h5>
                <p>
                  {selectedItem.session ? (
                    <>
                      {selectedItem.session.league.sportFormat.region.name} -{" "}
                      {selectedItem.session.registrationBatch.name} -
                      {daysOfWeek[selectedItem.session.dayOfWeek]} -
                      {leagueTransfer?.league?.name}
                    </>
                  ) : selectedItem.tournament ? (
                    <>
                      {selectedItem.tournament.sportFormat.region.name} -
                      {daysOfWeek[selectedItem.tournament.dayOfWeek]} -
                      {selectedItem.tournament.name}
                    </>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p className="mt-3">
                  New Price: ${leagueTransfer.totalValue?.toFixed(2)}
                </p>
              </div>
            </div>
            <h5 className="mt-3">
              Price Difference: $
              {+(leagueTransfer.totalValue - selectedItem.amountPaid).toFixed(
                2
              ) > 0
                ? (leagueTransfer.totalValue - selectedItem.amountPaid).toFixed(
                    2
                  )
                : selectedLeaguetransferRefundType === "1"
                ? (leagueTransfer.totalValue - selectedItem.amountPaid).toFixed(
                    2
                  )
                : "0.00"}
            </h5>

            {selectedItem.amountPaid < leagueTransfer.totalValue && (
              <FormFieldSelect
                value={selectedLeaguetranserType}
                name="leagueTransferType"
                placeholder="Select League Transfer Type"
                inputChange={(value: string) => {
                  setSelectedLeagueTransferType(value);
                }}
                label="League Transfer Type"
              >
                {[
                  { id: 1, name: "Player Payment" },
                  { id: 2, name: "Transfer without Payment" },
                ]}
              </FormFieldSelect>
            )}
            {selectedItem.amountPaid > leagueTransfer.totalValue && (
              <FormFieldSelect
                value={selectedLeaguetransferRefundType}
                name="leagueTransferType"
                placeholder="Select League Transfer Type"
                inputChange={(value: string) => {
                  setSelectedLeagueTransferRefundType(value);
                }}
                label="League Transfer Type"
              >
                {[
                  { id: 1, name: "Transfer with Refund" },
                  { id: 2, name: "Transfer without Refund" },
                ]}
              </FormFieldSelect>
            )}
            <div className="flex justify-end gap-3 mt-5">
              <ButtonUI
                variant="secondary"
                onClick={() => setOpenLeagueTransferDetails(false)}
              >
                Cancel
              </ButtonUI>
              <ButtonUI
                variant="primary"
                disabled={!!loadingLeagueTransfer || !!refundUrl}
                onClick={() => {
                  const refundVal =
                    Number(
                      (
                        selectedItem.amountPaid - leagueTransfer.totalValue
                      ).toFixed(2)
                    ) > 0 && selectedLeaguetransferRefundType === "1"
                      ? Number(
                          (
                            selectedItem.amountPaid - leagueTransfer.totalValue
                          ).toFixed(2)
                        )
                      : 0;
                  setOpenLeagueTransferConfirmation(true);
                  setOpenLeagueTransferDetails(false);
                  setRefundInput({
                    ...refundInput,
                    amount: refundVal,
                    reason: 7,
                  });
                }}
              >
                Next
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
      {selectedItem !== undefined && (
        <Modal
          open={openLeagueTransferConfirmation}
          onClose={() => {
            setOpenLeagueTransferConfirmation(false);
            setRefundUrl(null);
            setLeagueTransfer({});
            setSelectedItem(undefined);
            setRefundInput(refundInputInitial);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            zIndex: 10,
          }}
        >
          <Box sx={style}>
            <h4>
              {selectedItem.productType?.name === "League Indy"
                ? " League Free Agent "
                : " League Team "}
              Transfer Details
            </h4>
            <h5 className="mt-3">
              Price Difference: $
              {selectedLeaguetransferRefundType !== "2"
                ? (leagueTransfer.totalValue - selectedItem.amountPaid).toFixed(
                    2
                  )
                : "0.00"}
            </h5>
            <div>
              {selectedItem.amountPaid >= leagueTransfer.totalValue &&
                selectedLeaguetransferRefundType !== "2" && (
                  <div>
                    <h5>This transfer will be processed immediately:</h5>
                    <ul className="list-decimal list-inside">
                      {selectedItem.amountPaid > leagueTransfer.totalValue && (
                        <>
                          <div className="flex my-4">
                            <FormFieldSelect
                              name="Value"
                              value={refundInput.type.toString()}
                              error={refundErrors["type"] ? true : false}
                              assistiveText={
                                refundErrors["type"] ? refundErrors["type"] : ""
                              }
                              inputChange={(value) =>
                                setRefundInput({
                                  ...refundInput,
                                  ...(value === 0 && { reason: 0 }),
                                  type: +value,
                                })
                              }
                              label="Refund Method"
                              width="100%"
                              placeholder="Select Refund Method"
                            >
                              {[
                                ...refundTypes,
                                { id: 0, name: "Select Refund Method" },
                              ]}
                            </FormFieldSelect>
                          </div>
                          <div className="flex my-4">
                            <TextArea
                              className="w-full"
                              value={refundInput.notes ?? ""}
                              id={"refundNotes"}
                              maxInput={255}
                              onChange={(e) => {
                                setRefundInput((prevState) => ({
                                  ...prevState,
                                  notes: e.target.value,
                                }));
                              }}
                              title="Refund Notes"
                            />
                          </div>
                        </>
                      )}
                      <li>Create a shopping cart item for the new league.</li>
                      <li>Transfer the amount paid to the new item.</li>
                      <li>Create a registration for the new item.</li>
                      <li>
                        Delete the registration and any team/player records for
                        the current item.
                      </li>
                      {selectedItem.amountPaid > leagueTransfer.totalValue && (
                        <li>
                          A refund request for the price difference will be
                          created.
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              {selectedItem.amountPaid < leagueTransfer.totalValue &&
                selectedLeaguetranserType === "1" && (
                  <div>
                    <h5>
                      This transfer will be processed AFTER the player makes
                      their payment:
                    </h5>
                    <ul className="list-decimal list-inside">
                      <li>
                        A shopping cart item for the new league will be created.
                      </li>
                      <li>
                        The cart status will change to “Pending Player Action”.
                      </li>
                      <li>
                        Send the player the unique URL for them to make their
                        payment.
                      </li>
                      <li>
                        The player will need to make a payment for the price
                        difference.
                      </li>
                      <li>Transfer the amount paid to the new item.</li>
                      <li>Create a registration for the new item.</li>
                      <li>
                        Delete the registration and any team/player records for
                        the current item.
                      </li>
                    </ul>
                  </div>
                )}
              {((selectedItem.amountPaid < leagueTransfer.totalValue &&
                selectedLeaguetranserType === "2") ||
                (selectedItem.amountPaid > leagueTransfer.totalValue &&
                  selectedLeaguetransferRefundType === "2")) && (
                <div>
                  <h5>
                    This should only be done if we are making a special
                    exception for this player. (Ex, their current league is
                    getting whacked, or they paid a discount price on an
                    equivalently priced league).
                  </h5>
                  <h5>This transfer will be processed immediately:</h5>
                  <ul className="list-decimal list-inside">
                    <li>Create a shopping cart item for the new league.</li>
                    <li>Transfer the amount paid to the new item.</li>
                    <li>Create a registration for the new item.</li>
                    <li>
                      Delete the registration and any team/player records for
                      the current item.
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {refundUrl && (
              <div className="flex gap-3 mt-3">
                League Transfer Url:{" "}
                <p className="text-cyan-700">{refundUrl}</p>
                <ButtonUI
                  variant="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(refundUrl);
                  }}
                >
                  <div className="text-sm">Copy To Clipboard</div>
                </ButtonUI>
              </div>
            )}
            <div className="flex justify-end gap-3 mt-5">
              <ButtonUI
                variant="secondary"
                onClick={() => setOpenLeagueTransferConfirmation(false)}
              >
                Cancel
              </ButtonUI>
              <ButtonUI
                variant="primary"
                disabled={!!loadingLeagueTransfer || !!refundUrl}
                onClick={() => {
                  handleTransferLeague();
                }}
              >
                <div className="flex gap-3">
                  Confirm
                  {loadingLeagueTransfer && (
                    <Loader2 className="animate-spin text-light" />
                  )}
                </div>
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
      {selectedItem !== undefined && (
        <Modal
          open={openLeagueTransferURL}
          onClose={() => {
            setOpenLeagueTransferURL(false);
            setRefundUrl(null);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h4>League transfer URL</h4>
            {refundUrl && (
              <div className="flex gap-3 mt-3">
                League Transfer Url:{" "}
                <p className="text-cyan-700">{refundUrl}</p>
                <ButtonUI
                  variant="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(refundUrl);
                  }}
                >
                  <div className="text-sm">Copy To Clipboard</div>
                </ButtonUI>
              </div>
            )}
            <div className="flex justify-end gap-3 mt-5">
              <ButtonUI
                variant="secondary"
                onClick={() => {
                  setOpenLeagueTransferURL(false);
                  setRefundUrl(null);
                }}
              >
                Close
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
      {selectedItem !== undefined && (
        <Modal
          open={openRemoveRegValidation}
          onClose={() => {
            setOpenLeagueTransferURL(false);
            setRefundUrl(null);
          }}
          aria-labelledby="remove-reg-validation"
          aria-describedby="remove-reg-validation"
        >
          <Box sx={style}>
            <h4>Remove Registration</h4>
            <p>
              Are you sure you want to remove the registration for this item?
            </p>
            <p>
              This will delete the registration and any team/player records for
              the current item.
            </p>
            <p className="text-lg font-bold"> Item</p>
            <p>Product Type: {selectedItem.productType?.name}</p>
            <p>
              Product:{" "}
              {selectedItem.session ? (
                <>
                  {`${selectedItem.session.registrationBatch?.name} - ${
                    daysOfWeek[selectedItem.session.dayOfWeek]
                  } - ${selectedItem.session.league?.name}`}
                </>
              ) : selectedItem.tournament ? (
                <>
                  {`${daysOfWeek[selectedItem.tournament.dayOfWeek]} - ${
                    selectedItem.tournament.name
                  }`}
                </>
              ) : (
                "N/A"
              )}
              {}
            </p>
            <div className="flex justify-end gap-3 mt-5">
              <ButtonUI
                variant="secondary"
                onClick={() => {
                  setOpenRemoveRegValidation(false);
                  setSelectedItem(undefined);
                }}
              >
                Cancel
              </ButtonUI>
              <ButtonUI
                variant="primary"
                onClick={() => {
                  handleRemoveRegistration();
                }}
              >
                Confirm
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
      {selectedItem !== undefined && (
        <Modal
          open={openInfoModal}
          onClose={() => {
            setOpenInfoModal(false);
          }}
          aria-labelledby="info-modal"
          aria-describedby="info-modal"
        >
          <Box sx={style}>
            <div className="flex flex-col gap-3">
              <Subtitle1>
                These icons can be found in the Item Id column and represent the
                item's current status.
              </Subtitle1>
              <div className="flex items-center gap-2">
                <Tooltip title="League Transfer">
                  <CompareArrowsOutlinedIcon />
                </Tooltip>
                <Subtitle1>League Transfer</Subtitle1>
              </div>
              <div className="flex items-center gap-2">
                <Tooltip title="Refund">
                  <CurrencyExchangeIcon />
                </Tooltip>
                <Subtitle1>Refund</Subtitle1>
              </div>
              <div className="flex items-center gap-2">
                <Tooltip title="Registration Removed">
                  <CancelIcon />
                </Tooltip>
                <Subtitle1>Registration Removed</Subtitle1>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-5">
              <ButtonUI
                variant="secondary"
                onClick={() => {
                  setOpenInfoModal(false);
                }}
              >
                Close
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
      <Modal
        open={openTxnInfoModal.open}
        onClose={() => {
          setOpenTxnInfoModal({
            open: false,
            type: "notes",
            title: "",
            description: [""],
            txnId: undefined,
          });
        }}
        aria-labelledby="remove-reg-validation"
        aria-describedby="remove-reg-validation"
      >
        <Box sx={style}>
          <p className="text-lg font-extrabold">{openTxnInfoModal.title}</p>
          {openTxnInfoModal.type === "notes" && (
            <>
              {openTxnInfoModal.description.map((desc) => (
                <p>{desc}</p>
              ))}
            </>
          )}
          {openTxnInfoModal.type === "resend receipt" && (
            <div className="flex flex-col justify-between w-full h-full gap-4">
              <iframe
                ref={iframeRef}
                title="Email Preview"
                style={{ width: "100%", height: "400px", border: "none" }}
              />
            </div>
          )}

          <div className="flex justify-end gap-3 mt-2">
            <ButtonUI
              variant="secondary"
              onClick={() => {
                setOpenTxnInfoModal({
                  open: false,
                  type: "notes",
                  title: "",
                  description: [""],
                  txnId: undefined,
                });
              }}
            >
              Close
            </ButtonUI>
            {openTxnInfoModal.type === "resend receipt" && (
              <ButtonUI
                variant="secondary"
                onClick={async () => {
                  await sendTransactionReceipt({
                    variables: {
                      txnId: openTxnInfoModal.txnId ?? 0,
                    },
                    onCompleted: (data) => {
                      if (data.sendTransactionReceiptEmail?.success) {
                        dispatch(displayAlertSuccess("Receipt Sent"));
                        setOpenTxnInfoModal({
                          open: false,
                          type: "notes",
                          title: "",
                          description: [""],
                          txnId: undefined,
                        });
                      } else {
                        dispatch(displayAlertError("Something went wrong"));
                      }
                    },
                  });
                }}
              >
                Resend Receipt
              </ButtonUI>
            )}
          </div>
        </Box>
      </Modal>
      {selectedItem !== undefined && (
        <Modal
          open={openCreditPurchaseModal}
          onClose={() => {
            setOpenCreditPurchaseModal(false);
            setSelectedItem(undefined);
          }}
          aria-labelledby="info-modal"
          aria-describedby="info-modal"
        >
          <Box sx={style}>
            <div className="flex flex-col gap-3">
              <p className="text-3xl"> Apply Credit Purchase</p>
              {selectedItem && (
                <p className="text-xl">
                  {selectedItem.productType?.name} -{" "}
                  {selectedItem?.session
                    ? selectedItem.session?.league?.name
                    : selectedItem.tournament
                    ? selectedItem.tournament?.name
                    : " Could not find product"}
                </p>
              )}
              <div className="flex flex-col gap-1">
                <p className="text-lg">
                  Select a credit and amount to apply to this item. This will
                  apply a credit purchase transaction and:
                </p>

                <p className="pl-3">1. Increase the item amount paid</p>
                <p className="pl-3">2. Reduce the credit balance</p>
              </div>
              {loadingCredits && (
                <Loader2 className="animate-spin text-light" />
              )}
              {dataCredits?.creditsByUserId &&
                dataCredits.creditsByUserId.length > 0 &&
                creditPurchaseTable}
              {dataCredits?.creditsByUserId &&
                dataCredits.creditsByUserId.length === 0 && (
                  <p>No Credits Found</p>
                )}
            </div>
            <div className="flex justify-end gap-3 mt-5">
              <ButtonUI
                variant="secondary"
                onClick={() => {
                  setOpenCreditPurchaseModal(false);
                  setSelectedItem(undefined);
                }}
              >
                Close
              </ButtonUI>
              <ButtonUI
                disabled={
                  credits.reduce(
                    (acc, credit) => acc + credit.purchaseAmount,
                    0
                  ) === 0
                }
                variant={
                  credits.reduce(
                    (acc, credit) => acc + credit.purchaseAmount,
                    0
                  ) === 0
                    ? "disabled"
                    : "primary"
                }
                onClick={() => {
                  handleCreditPurchase();
                }}
              >
                Confirm
              </ButtonUI>
            </div>
          </Box>
        </Modal>
      )}
    </main>
  );
};

export default ShoppingCart;
