import { useEffect, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/src/app/store";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import {
  ListAllOperations,
  TeamHqQuery,
  TeamInvitationsByTeamIdQuery,
  useCreateTeamInvitationsMutation,
  useTeamHqQuery,
  useTeamInvitationsByTeamIdLazyQuery,
} from "../../generated/graphql";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import { DataTable } from "../UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import Button from "../UI/Button/Button";
import { Dialog, DialogContent, DialogHeader } from "../UI/shadcn/dialog";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";
import { z } from "zod";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import Body1 from "../UI/Text/Body/Body1";
import { LoaderCircle } from "lucide-react";
import FormField from "../UI/FormField/FormField";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Card from "../UI/Card/Card";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { MessageType } from "../UI/shadcn/chat/chatListBase";
import TeamChat from "../UI/shadcn/chat/teamChat";
import TabGroup from "../UI/Tab/TabGroup/TabGroup";
import BaseTable from "../UI/Table/Table";
import { clientURL } from "../Admin/Schedule/ScheduleNew";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const TeamInvitationEmailSchema = z
  .string()
  .refine((val) => emailRegex.test(val), {
    message: "Invalid email format",
  });

type TeamInvitationEmailType = z.infer<typeof TeamInvitationEmailSchema>;

const TeamInvitationEmailsSchema = z
  .array(TeamInvitationEmailSchema)
  .min(1, { message: "Please enter at least one email" });

type TeamInvitationEmailsType = z.infer<typeof TeamInvitationEmailsSchema>;
type TabGroupType = "Roster" | "Schedule";

const TeamHq = () => {
  /*** IMPORTED FUNTION DECLARATIONS ***/
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();
  // The id will be used to know if we're editing or creating data
  const { id } = params;
  // The id should be "Create" if we're creating data so this will return false
  const teamId = id ? +id : undefined;

  /*** STATES ***/
  const [team, setTeam] = useState<TeamHqQuery["team"] | undefined>(undefined);
  const [teamPlayersList, setTeamPlayersList] = useState<
    {
      id: string;
      name: string;
      role?: string;
      teamName?: string;
    }[]
  >([]);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [chatId, setChatId] = useState<number | undefined>(undefined);
  const [openTeamInvitationDialog, setOpenTeamInvitationDialog] =
    useState<boolean>(false);
  const [openTeamChatDialog, setOpenTeamChatDialog] = useState<boolean>(false);
  const [teamInvitationDialogDetails, setTeamInvitationDialogDetails] =
    useState<{ teamName: string; captainName: string; teamId: number }>({
      teamName: "",
      captainName: "",
      teamId: 0,
    });
  const [teamInvitationEmail, setTeamInvitationEmail] =
    useState<TeamInvitationEmailType>("");
  const [teamInvitationEmails, setTeamInvitationEmails] =
    useState<TeamInvitationEmailsType>([]);
  const [teamInvitations, setTeamInvitations] = useState<
    TeamInvitationsByTeamIdQuery["teamInvitationsByTeamId"]
  >([]);
  const [tabGroupValue, setTabGroupValue] = useState<TabGroupType>("Roster");

  /*** QUERIES ***/
  const { loading: loadingTeam } = useTeamHqQuery({
    variables: {
      id: teamId!,
    },
    skip: teamId == undefined,
    onCompleted: (data) => {
      setTeam(data.team);
      if (!data.team.chatRoom) {
        return;
      }
      const list = data.team.teamPlayers.map((player) => ({
        id: player.playerUserProfile.id || "",
        name:
          player.playerUserProfile.firstName +
          " " +
          player.playerUserProfile.lastName,
      }));
      const deletedList = data.team.deletedTeamPlayers.map((player) => ({
        id: player.playerUserProfile.id || "",
        name:
          player.playerUserProfile.firstName +
          " " +
          player.playerUserProfile.lastName +
          "(Removed from Team)",
      }));
      const fullList = [...list, ...deletedList];
      setTeamPlayersList(fullList);
      if (data.team.chatRoom.chatMessages) {
        const messagesList: MessageType[] = data.team.chatRoom.chatMessages.map(
          (message) => {
            const user = fullList.find((player) => player.id == message.userId);
            return {
              role: "other",
              content: message.message,
              name: user?.name ?? "",
              createdAt: dayjs(message.createdAt).format("MMM D, YYYY h:mm A"),
            };
          }
        );
        setMessages(messagesList);
        setChatId(data.team.chatRoom.id);
      }
    },
  });

  const [getTeamInvitationsByTeamId, { loading: teamInvitationsLoading }] =
    useTeamInvitationsByTeamIdLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setTeamInvitations(data.teamInvitationsByTeamId);
        setTeamInvitationEmails([]);
        setTeamInvitationEmail("");
      },
      notifyOnNetworkStatusChange: true,
    });

  /*** MUTATIONS ***/
  const [CreateTeamInvitations, { loading: loadingTeamInvitationCreate }] =
    useCreateTeamInvitationsMutation();
  /*** UTILITY FUNCTIONS ***/
  async function handleSendTeamInvitations() {
    let result = TeamInvitationEmailsSchema.safeParse(teamInvitationEmails);
    if (!result.success) {
      result.error.errors.forEach((error) => {
        dispatch(displayAlertError(error.message));
      });
      return;
    }
    await CreateTeamInvitations({
      variables: {
        createTeamInvitationInput: {
          emails: teamInvitationEmails,
          teamId: teamInvitationDialogDetails.teamId,
        },
      },
      refetchQueries: [ListAllOperations.Query.TeamInvitationsByTeamId],
      onCompleted: async (data) => {
        if (data.createTeamInvitation.success) {
          dispatch(displayAlertSuccess(data.createTeamInvitation.message));
        } else {
          dispatch(displayAlertError(data.createTeamInvitation.message));
        }
      },
    });
  }

  useEffect(() => {
    if (
      teamInvitationEmail.endsWith(" ") ||
      teamInvitationEmail.endsWith(",")
    ) {
      let result = TeamInvitationEmailSchema.safeParse(
        teamInvitationEmail.slice(0, -1)
      );
      if (!result.success) {
        dispatch(displayAlertError(result.error.errors[0].message));
      } else {
        setTeamInvitationEmails((prevState) => [
          ...prevState,
          teamInvitationEmail.slice(0, -1),
        ]);
        setTeamInvitationEmail("");
      }
    }
  }, [teamInvitationEmail]);

  const columnsForRoster: ColumnDef<TeamHqQuery["team"]["teamPlayers"][0]>[] = [
    {
      header: "Team Player Id",
      accessorFn: (row) => row.id,
    },
    {
      id: "name",
      header: "Player",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50 hover:text-info-40"
            href={`/players/user/${row.original.playerUserProfile.id}`}
          >
            {row.original.playerUserProfile.firstName +
              " " +
              row.original.playerUserProfile.lastName}
          </a>
        );
      },
    },
    {
      header: "Player Role",
      accessorFn: (row) => row.teamPlayerRole.teamPlayerRoleName,
    },
  ];
  const teamInvitationColoumns: ColumnDef<
    TeamInvitationsByTeamIdQuery["teamInvitationsByTeamId"][0]
  >[] = [
    {
      header: "Sent Date",
      cell: ({ row }) => {
        return dayjs(row.original.createdAt).format("MMM D, YYYY h:mm A");
      },
    },
    {
      header: "Email",
      cell: ({ row }) => {
        return row.original.email;
      },
    },
    {
      header: "User Id",
      cell: ({ row }) => {
        return row.original.userId ?? "N/A";
      },
    },
    {
      header: "Player Name",
      cell: ({ row }) => {
        if (row.original.user) {
          return `${row.original.user.firstName} ${row.original.user.lastName}`;
        }
        return "N/A";
      },
    },
    {
      header: "Status",
      cell: ({ row }) => {
        return row.original.teamInvitationStatus.name;
      },
    },
  ];

  const columnsForSchedule: ColumnDef<
    | NonNullable<TeamHqQuery["team"]["schedule"]>["regularGames"][0]
    | NonNullable<TeamHqQuery["team"]["schedule"]>["playoffGames"][0]
  >[] = [
    {
      id: "gameId",
      header: "Game Id",
      accessorFn: (row) => row.id,
      cell: ({ row }) => {
        return (
          <>
            <a
              className="underline text-info-50 hover:text-info-70"
              href={`${clientURL}/teams/team-info/${teamId}/game-info/${row.original.id}`}
              target="_blank"
            >
              {row.original.id}
            </a>
          </>
        );
      },
    },
    {
      id: "week",
      header: "Week",
      accessorFn: (row) => row.week,
    },
    {
      id: "venue",
      header: "Venue",
      accessorFn: (row) => row.venue.name,
    },
    {
      id: "region",
      header: "Region",
      accessorFn: (row) => row.venue.venueDetail.region.name,
    },
    {
      id: "startDateTimeLocal",
      header: "Game Start Time",
      accessorFn: (row) =>
        dayjs(row.startDateTimeLocal).format("YYYY-MM-DD HH:mm"),
    },
    {
      id: "awayTeam",
      header: "Home Team",
      accessorFn: (row) => row.awayTeam?.name,
      cell: ({ row }) => {
        return (
          <>
            {row.original.awayTeam ? (
              <a
                className="underline text-info-50 hover:text-info-70"
                href={`/players/team/${row.original.awayTeam.id}`}
              >
                {row.original.awayTeam.name}
              </a>
            ) : (
              "Missing Team"
            )}
          </>
        );
      },
    },
    {
      id: "awayScore",
      header: "Away Team Score",
      accessorFn: (row) => row.awayScore,
    },
    {
      id: "homeTeam",
      header: "Away Team",
      accessorFn: (row) => row.homeTeam?.name,
      cell: ({ row }) => {
        return (
          <>
            {row.original.homeTeam ? (
              <a
                className="underline text-info-50 hover:text-info-70"
                href={`/players/team/${row.original.homeTeam.id}`}
              >
                {row.original.homeTeam.name}
              </a>
            ) : (
              "Missing Team"
            )}
          </>
        );
      },
    },
    {
      id: "homeScore",
      header: "Home Team Score",
      accessorFn: (row) => row.homeScore,
    },
  ];

  const scheduleTab = (
    <div>
      <DataTable
        columns={columnsForSchedule}
        data={[
          ...(team?.schedule?.regularGames.filter(
            (game) =>
              game.awayTeam?.id === teamId || game.homeTeam?.id === teamId
          ) ?? []),
          ...(team?.schedule?.playoffGames.filter(
            (game) =>
              game.awayTeam?.id === teamId || game.homeTeam?.id === teamId
          ) ?? []),
        ]}
      />
    </div>
  );

  if (loadingTeam) {
    return <LoadingDialog open={true} />;
  }

  return (
    <>
      {team === undefined ? (
        <main>
          <Headline1Variable>Couldn't find team</Headline1Variable>
        </main>
      ) : (
        <main className="flex flex-col gap-6">
          <Headline1Variable>Team Name: {team.name}</Headline1Variable>
          <div className="flex flex-row gap-4">
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">
                  Session Details
                </Subtitle1>
                <Subtitle1 className="whitespace-nowrap">
                  <a
                    className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                    href={`/ops/session/${team.session.id}`}
                  >
                    Id: {team.session.id}
                  </a>
                </Subtitle1>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-start justify-between">
                <Subtitle1 className="whitespace-nowrap">League</Subtitle1>
                <Subtitle1 className="whitespace-nowrap">
                  <a
                    className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                    href={`/ops/league/${team.session.league.id}`}
                  >
                    {team.session.league.name}
                  </a>
                </Subtitle1>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-start justify-between">
                <Subtitle1 className="whitespace-nowrap">Division</Subtitle1>
                {team.division ? (
                  <Subtitle1 className="whitespace-nowrap">
                    <a
                      className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                      href={`/ops/division/${team.division.id}`}
                    >
                      {team.division.name}
                    </a>
                  </Subtitle1>
                ) : (
                  <Subtitle1>No Division</Subtitle1>
                )}
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-start justify-between">
                <Subtitle1 className="whitespace-nowrap">Schedule</Subtitle1>
                {team.schedule && team.division ? (
                  <Subtitle1 className="whitespace-nowrap">
                    <a
                      className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                      href={`/ops/division/${team.division.id}/schedule`}
                    >
                      Id: {team.schedule.id}
                    </a>
                  </Subtitle1>
                ) : (
                  <Subtitle1>No Schedule</Subtitle1>
                )}
              </div>
            </Card>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-4">
              <Button
                variant="secondary"
                onClick={async () => {
                  setOpenTeamInvitationDialog(true);
                  setTeamInvitationDialogDetails({
                    teamName: team.name,
                    captainName:
                      team.teamCaptain?.playerUserProfile?.firstName ??
                      "No captain (should not happen)",
                    teamId: team.id,
                  });
                  await getTeamInvitationsByTeamId({
                    variables: { teamId: team.id },
                  });
                }}
              >
                Team Invite
              </Button>
              <Button
                variant="secondary"
                onClick={async () => {
                  setOpenTeamChatDialog(true);
                }}
              >
                Team Chat
              </Button>
            </div>
            <TabGroup
              content={["Roster", "Schedule"]}
              inputChange={(value) => {
                setTabGroupValue(value);
              }}
              value={tabGroupValue}
              className="max-w-full w-fit"
            />
            {tabGroupValue === "Roster" && (
              <DataTable
                columns={columnsForRoster}
                data={team.teamPlayers}
              />
            )}
            {tabGroupValue === "Schedule" && scheduleTab}
          </div>
          <Dialog
            open={openTeamInvitationDialog}
            onOpenChange={(value) => setOpenTeamInvitationDialog(value)}
          >
            <DialogContent className="flex flex-col flex-grow w-fit max-w-[80%] max-h-[80%] overflow-scroll">
              <DialogHeader>
                <Headline2Variable>Team Invites</Headline2Variable>
              </DialogHeader>
              <div className="flex flex-col flex-grow max-w-full gap-4">
                <div className="flex flex-col gap-2">
                  <Subtitle2>
                    Team Name: {teamInvitationDialogDetails.teamName}
                  </Subtitle2>
                  <Subtitle2>
                    Team Captain: {teamInvitationDialogDetails.captainName}
                  </Subtitle2>
                  <div className="flex flex-col gap-2">
                    <div>
                      <Headline2Variable>Send Team Invites</Headline2Variable>
                      <Button
                        variant="primary"
                        onClick={handleSendTeamInvitations}
                      >
                        <div className="flex flex-row gap-2">
                          <Body1>Send Team Invites</Body1>
                          {loadingTeamInvitationCreate && (
                            <LoaderCircle className="w-fit h-fit animate-spin" />
                          )}
                        </div>
                      </Button>
                    </div>
                    <div className="flex flex-row items-end mt-3 gap-x-2">
                      <FormField
                        inputChange={(e) => {
                          setTeamInvitationEmail(e.target.value.toLowerCase());
                        }}
                        name="email"
                        label="Invite with Email"
                        initialValue={teamInvitationEmail}
                        className="h-fit"
                      />
                      <Button
                        onClick={() => {
                          setTeamInvitationEmails((prevState) => [
                            ...prevState,
                            teamInvitationEmail,
                          ]);
                          setTeamInvitationEmail("");
                        }}
                        variant="secondary"
                        className="rounded-full h-fit"
                      >
                        <AddIcon />
                      </Button>
                    </div>
                    <div className="flex flex-wrap">
                      {teamInvitationEmails.length > 0 &&
                        teamInvitationEmails.map((email: String, i) => (
                          <span
                            key={i}
                            className="flex flex-row items-center justify-center gap-2 px-4 py-2 mt-3 mr-2 text-sm text-gray-800 break-words bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300"
                          >
                            {email}
                            <CloseIcon
                              className="transition-all rounded-full cursor-pointer hover:text-error-30 hover:bg-error-90"
                              onClick={() => {
                                setTeamInvitationEmails((prevState) => {
                                  return prevState.filter(
                                    (item) => item !== email
                                  );
                                });
                              }}
                            />
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center w-full">
                    {teamInvitationsLoading ? (
                      <CircularProgress />
                    ) : (
                      <div className="max-h-[450px] overflow-scroll border rounded-md">
                        {teamInvitations.length > 0 && (
                          <DataTable
                            data={teamInvitations}
                            columns={teamInvitationColoumns}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openTeamChatDialog}
            onOpenChange={setOpenTeamChatDialog}
          >
            <DialogContent className="flex flex-col flex-grow w-fit max-w-[80%] max-h-[80%] mt-10 overflow-scroll">
              <DialogHeader>
                <Headline2Variable>Team Chat</Headline2Variable>
              </DialogHeader>
              {chatId ? (
                <div className="flex flex-col justify-between w-full h-[60vh]">
                  <TeamChat
                    messages={messages}
                    teamPlayers={teamPlayersList}
                    chatId={chatId!}
                  />
                </div>
              ) : (
                <Body1>No Team Chats</Body1>
              )}
            </DialogContent>
          </Dialog>
        </main>
      )}
    </>
  );
};

export default TeamHq;
