import Alert from "../components/UI/Alerts/Alert";

export const renderZodErrors = (errors: any, parentKey = ""): JSX.Element[] => {
  return Object.keys(errors).flatMap((key) => {
    const error = errors[key];
    const displayKey = key === "_errors" ? parentKey.split(".").pop() : key; // Get the last segment of the path

    if (key === "_errors" && Array.isArray(error) && error.length > 0) {
      // Display the error message with only the final field name
      return error.map((message) => (
        <Alert
          variant="error"
          content={`${displayKey}: ${message}`}
          size="large"
          persist={true}
          key={`${parentKey}-error`}
        />
      ));
    } else if (typeof error === "object" && error !== null) {
      // Recursively process nested fields
      return renderZodErrors(error, `${parentKey}.${key}`);
    }
    return [];
  });
};
