import React from "react";
import { ScratchToReveal } from "../UI/ScratchToReveal/ScratchToReveal";
import image from "../../assets/images/Rookies-and-Vets-2024.jpeg";

const Base = () => {
  return (
    <div className="flex justify-center">
      <ScratchToReveal
        width={800}
        height={600}
        minScratchPercentage={20}
        className="flex items-center justify-center overflow-hidden rounded-2xl border-2 bg-gray-100"
        gradientColors={["#A97CF8", "#F38CB8", "#FDCC92"]}
      >
        <img
          className="mr-2 rounded-sm bg-neutral-80"
          src={image}
          alt="image"
          width={800}
        />
      </ScratchToReveal>
    </div>
  );
};

export default Base;
