import { AppDispatch, RootState } from "@/src/app/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import React, { useEffect, useState } from "react";
import Button from "../../UI/Button/Button";
import moment from "moment";
import { LoadingMaterialUI } from "../../UI";
import { PaginationType } from "../../../types/types";
import {
  ListAllOperations,
  PromoCodesQuery,
  useDeletePromoCodeMutation,
  usePromoCodesLazyQuery,
  useUpdatePromoCodeStatusMutation,
} from "../../../generated/graphql";
import { EPermission, isPermissionGranted } from "../../../utils/permissions";
import { LockOutlined } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import Alert from "../../UI/Alerts/Alert";
import CheckBox from "../../UI/Checkbox/Checkbox";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../UI/shadcn/dropdown";
import { MoreVerticalIcon } from "lucide-react";
import PaginationDataTable from "../../UI/Pagination/PaginationDataTable";

const PromoCodes: React.FC = () => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { permissions, user: admin }: any = useSelector(
    (state: RootState) => state.auth
  );

  const pageSize = 25;

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 25,
    pageCount: undefined,
  });
  const [promoCodeModal, setPromoCodeModal] = useState<{
    open: boolean;
    promoCode: PromoCodesQuery["promoCodes"]["promoCodes"][0] | null;
    errorMessage: string | null;
    type: "delete" | "updateStatus";
  }>({
    open: false,
    promoCode: null,
    errorMessage: null,
    type: "delete",
  });

  const [getPromoCodes, { data: promoCodeData, loading, error }] =
    usePromoCodesLazyQuery({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const pageCount = Math.ceil(data.promoCodes.count / pageSize);
        setPagination((prevState) => ({
          ...prevState,
          pageCount: pageCount,
        }));
      },
    });

  const [deletePromoCode, { loading: loadingDeletePromoCode }] =
    useDeletePromoCodeMutation({
      onCompleted: (data) => {
        if (data.deletePromoCode.success) {
          setPromoCodeModal((prevState) => ({
            ...prevState,
            open: false,
            errorMessage: null,
          }));
          dispatch(displayAlertSuccess(data.deletePromoCode.message));
        } else {
          setPromoCodeModal((prevState) => ({
            ...prevState,
            open: true,
            errorMessage: data.deletePromoCode.message,
          }));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
      refetchQueries: [ListAllOperations.Query.PromoCodes],
    });

  const [updatePromoCodeStatus, { loading: loadingUpdatePromoCodeStatus }] =
    useUpdatePromoCodeStatusMutation({
      onCompleted: (data) => {
        if (data.updatePromoCodeStatus.success) {
          setPromoCodeModal({
            open: false,
            promoCode: null,
            errorMessage: null,
            type: "delete",
          });
          dispatch(displayAlertSuccess(data.updatePromoCodeStatus.message));
        } else {
          setPromoCodeModal((prevState) => ({
            ...prevState,
            errorMessage: data.updatePromoCodeStatus.message,
          }));
          dispatch(displayAlertError(data.updatePromoCodeStatus.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
      refetchQueries: [ListAllOperations.Query.PromoCodes],
    });

  const handleSelectedOption = (
    row: PromoCodesQuery["promoCodes"]["promoCodes"][0],
    idx: number
  ) => {
    if (idx === 1) {
      setPromoCodeModal({
        open: true,
        promoCode: row,
        errorMessage: null,
        type: "delete",
      });
    }
    if (idx === 2) {
      setPromoCodeModal({
        open: true,
        promoCode: row,
        errorMessage: null,
        type: "updateStatus",
      });
    }
  };

  useEffect(() => {
    getPromoCodes({
      variables: {
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }, [pagination.page, pagination.pageSize]);

  const options = [
    { id: 1, text: "Delete" },
    { id: 2, text: "Update Status" },
  ];

  const COLUMNS: ColumnDef<PromoCodesQuery["promoCodes"]["promoCodes"][0]>[] = [
    {
      header: "Name",
      id: "name",
      accessorFn: (d) => d.name,
    },
    {
      header: "Discount",
      id: "discount",
      accessorFn: (d) => `${d.discount} ${d.discountTypeId === 1 ? "%" : "$"}`,
    },
    {
      header: "Valid From",
      id: "validFrom",
      accessorFn: (d) => moment(d.validFrom).local().format("YYYY-MM-DD"),
    },
    {
      header: "Expiry Date",
      id: "validTo",
      accessorFn: (d) => moment(d.validTo).local().format("YYYY-MM-DD"),
    },
    {
      header: "Status",
      id: "isActive",
      accessorFn: (d) => `${d.isActive ? "Active" : "Inactive"}`,
    },
    {
      header: "Updated By",
      id: "updatedBy",
      accessorFn: (d) =>
        d.userUpdatedBy.firstName + " " + d.userUpdatedBy.lastName,
    },
    {
      header: "Regions",
      id: "regions",
      accessorFn: (d) => d.regions?.map((region) => region.name).join(", "),
    },
    {
      header: "Created At",
      id: "createdAt",
      accessorFn: (d) => d.createdAt,
    },
    {
      header: "Updated At",
      id: "updatedAt",
      accessorFn: (d) => d.updatedAt,
    },
  ];

  const isPermGranted =
    admin && admin.permission
      ? isPermissionGranted(admin.permission, EPermission["PROMOCODE_DISCOUNT"])
      : false;

  if (isPermGranted) {
    COLUMNS.push({
      header: "Action",
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <MoreVerticalIcon />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="flex flex-col gap-1">
              <DropdownMenuItem
                className="hover:bg-neutral-90"
                onClick={() => handleSelectedOption(row.original, 2)}
              >
                {row.original.isActive ? "Deactivate" : "Activate"}
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:bg-neutral-90"
                onClick={() => handleSelectedOption(row.original, 1)}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    });
  }

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Promo Codes</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant={isPermGranted ? "primary" : "disabled"}
            href={"/marketing/promo-codes/new"}
            disabled={!isPermGranted}
          >
            New Promo Code
            {!isPermGranted && <LockOutlined />}
          </Button>
        </div>
      </div>
      {loading && <LoadingMaterialUI />}
      {promoCodeData && (
        <div className="flex flex-col gap-4">
          <DataTable
            columns={COLUMNS}
            data={promoCodeData.promoCodes.promoCodes}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
      <Modal
        open={promoCodeModal.open}
        onClose={() => {
          setPromoCodeModal({
            open: false,
            promoCode: null,
            errorMessage: null,
            type: "delete",
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {promoCodeModal.promoCode && (
            <h4>
              {promoCodeModal.type === "delete"
                ? "Delete "
                : `${
                    promoCodeModal.promoCode.isActive
                      ? "Deactivate"
                      : "Activate"
                  } `}
              Promo Code {promoCodeModal.promoCode.name}
            </h4>
          )}
          {promoCodeModal.errorMessage && (
            <Alert
              variant="error"
              size="small"
              persist={true}
              content={promoCodeModal?.errorMessage}
              mb="mb-4"
            />
          )}
          {promoCodeModal.type === "delete" && (
            <div> Are you sure you want to delete this promo code? </div>
          )}
          {promoCodeModal.type === "updateStatus" && (
            <div className="flex flex-col mb-4">
              <div>
                <div>
                  This will action{" "}
                  {promoCodeModal.promoCode?.isActive
                    ? "deactivate"
                    : "activate"}{" "}
                  the promo code.
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3 mt-5">
            <Button
              variant="secondary"
              onClick={() => {
                setPromoCodeModal({
                  open: false,
                  promoCode: null,
                  errorMessage: null,
                  type: "delete",
                });
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (promoCodeModal.type === "delete")
                  deletePromoCode({
                    variables: {
                      id: promoCodeModal.promoCode?.id || 0,
                    },
                  });
                if (promoCodeModal.type === "updateStatus")
                  updatePromoCodeStatus({
                    variables: {
                      id: promoCodeModal.promoCode?.id || 0,
                      isActive: !promoCodeModal.promoCode?.isActive,
                    },
                  });
              }}
            >
              {promoCodeModal.type === "delete"
                ? "Delete"
                : `${
                    promoCodeModal.promoCode?.isActive
                      ? "Deactivate"
                      : "Activate"
                  }`}
            </Button>
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default PromoCodes;
