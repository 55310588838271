import React, { Fragment, useEffect } from "react";
import classes from "./Division/CreateDivision.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import {
  getRegistrationBatch,
  getSessions,
  checkRepeatedTeamNames,
} from "../../app/venueMasterSlice";
import { Session } from "../../types/types";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import ButtonUI from "../UI/Button/Button";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { z } from "zod";
import {
  TeamArgs,
  TeamUpdateArgs,
  useFreeAgentRegistrationBySessionIdLazyQuery,
  useGetPlayersRatingsBySportIdLazyQuery,
  useSessionsLazyQuery,
  useTeamCreateMutation,
  useTeamDeleteMutation,
  useTeamUpdateMutation,
} from "../../../src/generated/graphql";
import FreeAgentTeamBoard from "../UI/DragAndDrop/FreeAgentTeamBoard";
import LoadingDialog from "../UI/Dialog/LoadingDialog";

enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

const PlayerSchema = z.object({
  id: z.string(),
  name: z.string(),
  genderIdentity: z.string(),
  isCaptain: z.boolean(),
  CPR: z.array(z.string()),
  registrationId: z.number().nullable(),
  teamPlayerId: z.number().nullable(),
  toBeCaptain: z.boolean().nullable(),
  skillChoice: z.string().nullable(),
});

const FreeAgentTeamsSchema = z.object({
  id: z.number(),
  name: z.string(),
  order: z.number(),
  players: z.array(PlayerSchema),
});

export type Player = z.infer<typeof PlayerSchema>;
export type FreeAgentTeam = z.infer<typeof FreeAgentTeamsSchema>;

const CreateIndyTeam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialSessionFilter = {
    regions: null,
    dayOfWeek: 7,
    registrationBatchId: 0,
  };

  const weekdaysArray: any = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    isLoading,
    selectedRegions,
    registrationBatches,
    repeatedTeamNames,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const { user }: any = useSelector((state: RootState) => state.auth);

  const [sessionsSelect, setSessionsSelect]: any = React.useState([]);
  const [sessionFilter, setSessionFilter]: any =
    React.useState(initialSessionFilter);
  const [sessionId, setSessionId]: any = React.useState<number>(() => {
    const urlParamSessionId = searchParams.get("sessionId");
    if (urlParamSessionId) {
      return +urlParamSessionId;
    }
    return 0;
  });
  const [teams, setTeams]: any = React.useState<FreeAgentTeam[]>([]);
  const [reset, setReset]: any = React.useState(false);
  const [isEditTeams, setIsEditTeams] = React.useState<Boolean>(false);
  const [deleteTeams, setDeleteTeams] = React.useState<number[]>([]);
  const [createTeam, { loading }]: any = useTeamCreateMutation();
  const [updateTeam, { loading: loadingUpdate }]: any = useTeamUpdateMutation();
  const [deleteTeam, { loading: loadingDelete }]: any = useTeamDeleteMutation();

  const [freeAgentRegistrations, { loading: loadingFreeAgentReg, data }] =
    useFreeAgentRegistrationBySessionIdLazyQuery({
      fetchPolicy: "network-only",
    });

  const [
    getPlayersRatings,
    { data: playerRatings, loading: loadingPlayerRatings },
  ] = useGetPlayersRatingsBySportIdLazyQuery({
    fetchPolicy: "network-only",
  });

  const [getSessions, { data: sessions }] = useSessionsLazyQuery({
    onCompleted: (data) => {
      if (data.sessions) {
        const sessionsArray = data.sessions.map((session) => {
          return {
            id: session.id,
            name: `${DayOfWeek[+session.dayOfWeek]} - ${session.league.name}`,
          };
        });
        setSessionsSelect(sessionsArray);
      }
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.freeAgentRegistrationBySessionId && sessions) {
      setTeams([]);
      // Check if there are any teams already created
      const freeAgentTeams =
        data.freeAgentRegistrationBySessionId.freeAgentTeams;
      const freeAgentRegistrations =
        data.freeAgentRegistrationBySessionId.freeAgentRegistrations;

      const session = sessions["sessions"].find(
        (session) => session.id === sessionId
      );
      const sportId = session?.league?.sportFormat?.sport.id;
      const playerIds = freeAgentRegistrations?.map((reg) => reg?.user?.id!);

      if (sportId && playerIds?.length) {
        getPlayersRatings({
          variables: {
            sportId: +sportId,
            playerIds: playerIds!,
          },
        });
      }
      if (
        freeAgentTeams &&
        freeAgentRegistrations &&
        freeAgentTeams.length > 0
      ) {
        let order = 0;
        const teams = freeAgentTeams.map((team) => {
          const players: Player[] | undefined = team?.teamPlayers.map(
            (player) => {
              const playerReg = freeAgentRegistrations.find(
                (regs) => regs?.id === player?.registration?.id
              );
              const coPlayerReq = playerReg?.coPlayerRequest?.map(
                (coPlayerReq) => {
                  return coPlayerReq?.user
                    ? ` ${coPlayerReq.user.firstName} ${coPlayerReq.user.lastName}`
                    : ` ${coPlayerReq?.value}`;
                }
              );

              return {
                id: player?.playerUserProfile.id || "",
                name: `${player?.playerUserProfile.firstName} ${player?.playerUserProfile.lastName}`,
                genderIdentity:
                  player?.playerUserProfile.genderIdentity?.name || "",
                isCaptain:
                  player?.teamPlayerRole.teamPlayerRoleName === "captain",
                CPR: coPlayerReq || [],
                registrationId: player?.registration?.id || null,
                teamPlayerId: player?.id ? +player?.id : null,
                toBeCaptain:
                  player?.registration?.regQuestionChoice?.toBeCaptain?.name ===
                  "Yes",
                skillChoice:
                  player?.registration?.regQuestionChoice?.regSkillChoice
                    ?.name || null,
              };
            }
          );
          order++;
          return {
            id: team?.id,
            name: team?.name,
            order: order,
            players: players,
          };
        });

        let teamsCopy = [...teams];
        // Find if players are not in any team
        const freeAgents = freeAgentRegistrations.filter(
          (reg) =>
            !teams.find((team) =>
              team?.players?.find(
                (player) =>
                  player.id === reg?.user?.id &&
                  player.registrationId === reg?.id
              )
            )
        );

        order++;
        const freeAgentTeam: FreeAgentTeam = {
          id: 0,
          name: "",
          order: order,
          players: [],
        };

        if (freeAgents.length) {
          freeAgentTeam.players = freeAgents.map((reg) => {
            const coPlayerReq = reg?.coPlayerRequest?.map((coPlayerReq) => {
              return coPlayerReq?.user
                ? ` ${coPlayerReq.user.firstName} ${coPlayerReq.user.lastName}`
                : ` ${coPlayerReq?.value}`;
            });
            return {
              id: reg?.user?.id || "",
              name: `${reg?.user?.firstName} ${reg?.user?.lastName}`,
              genderIdentity: reg?.user?.genderIdentity?.name || "",
              isCaptain: false,
              CPR: coPlayerReq || [],
              registrationId: reg?.id || null,
              teamPlayerId: null,
              toBeCaptain: reg?.regQuestionChoice?.toBeCaptain?.name === "Yes",
              skillChoice: reg?.regQuestionChoice?.regSkillChoice?.name || null,
            };
          });
        }
        teamsCopy = [freeAgentTeam, ...teamsCopy];
        setIsEditTeams(true);
        setTeams(teamsCopy);
      } else {
        const freeAgentTeam: FreeAgentTeam = {
          id: 0,
          name: "",
          order: 1,
          players: [],
        };
        if (freeAgentRegistrations && freeAgentRegistrations.length) {
          freeAgentTeam.players = freeAgentRegistrations.map((reg) => {
            const coPlayerReq = reg?.coPlayerRequest?.map((coPlayerReq) => {
              return coPlayerReq?.user
                ? ` ${coPlayerReq.user.firstName} ${coPlayerReq.user.lastName}`
                : ` ${coPlayerReq?.value}`;
            });
            return {
              id: reg?.user?.id || "",
              name: `${reg?.user?.firstName} ${reg?.user?.lastName}`,
              genderIdentity: reg?.user?.genderIdentity?.name || "",
              isCaptain: false,
              CPR: coPlayerReq || [],
              registrationId: reg?.id || null,
              teamPlayerId: null,
              toBeCaptain: reg?.regQuestionChoice?.toBeCaptain?.name === "Yes",
              skillChoice: reg?.regQuestionChoice?.regSkillChoice?.name || null,
            };
          });
        }
        setTeams([freeAgentTeam, { name: "", order: 2, players: [] }]);
        setIsEditTeams(false);
      }
    }
  }, [data, reset, sessions]);

  useEffect(() => {
    dispatch(getRegistrationBatch("")).then(() => {
      const paramRegBatch = searchParams.get("regBatchId");
      if (paramRegBatch) {
        setSessionFilter({
          ...sessionFilter,
          ["registrationBatchId"]: +paramRegBatch,
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (sessionId && sessionId !== 0) {
      console.log("here");
      freeAgentRegistrations({
        variables: {
          sessionId: sessionId,
        },
      });
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionFilter.registrationBatchId !== 0) {
      getSessions({
        variables: {
          sessionFilters: sessionFilter,
        },
      });
      setTeams([]);
      setDeleteTeams([]);
    }
  }, [sessionFilter]);

  const handleChangeSession = (value: number) => {
    setSessionId(value);
  };

  const checkTeams = async () => {
    //Check is there is at least 1 captain in each team
    const teamsCopy = [...teams].filter((team) => team.id !== 0);
    const teamsWithNoCaptain = teamsCopy.filter(
      (team) => !team.players.find((player: Player) => player.isCaptain)
    );
    if (teamsWithNoCaptain.length) {
      dispatch(displayAlertError("Each team must have at least 1 captain!"));
      return true;
    }

    //Check if there are multiple captain in a team
    const teamsWithMultipleCaptains = teamsCopy.filter(
      (team) =>
        team.players.filter((player: Player) => player.isCaptain).length > 1
    );
    if (teamsWithMultipleCaptains.length) {
      dispatch(displayAlertError("Each team must have only 1 captain!"));
      return true;
    }

    // check if no same team names are there
    const teamNames = teamsCopy.map((team) => team.name);

    // check if the entered names are not same
    for (let i = 0; i < teamNames.length; i++) {
      for (let j = i + 1; j < teamNames.length; j++) {
        if (teamNames[i] === teamNames[j]) {
          dispatch(
            displayAlertError(
              `Team names should be unique. Team ${teamNames[i]} is repeated.`
            )
          );
          return true;
        }
      }
    }

    return false;
  };

  const onSave = async () => {
    const check = await checkTeams();
    if (check) {
      return;
    }
    const teamsArray = teams
      .filter((team: FreeAgentTeam) => team.id !== 0)
      .map((team: FreeAgentTeam) => {
        const teamData: TeamArgs | TeamUpdateArgs = {
          team: {
            id: team.id || null,
            name: team.name,
            sessionId: +sessionId,
            registrantUserId: user?.id.toString() || "1",
            isIndyTeam: true,
          },
        };
        teamData.teamPlayers = team.players.map((player) => {
          const isCaptain = player.isCaptain;
          return {
            playerUserId: player.id,
            teamPlayerRoleId: isCaptain ? 1 : 2,
            addedByUserId: user?.id.toString() || "1",
            id: player.teamPlayerId || null,
            registrationId: player.registrationId,
          };
        });
        return teamData;
      });
    if (isEditTeams) {
      //Remove team with id 0
      await updateTeam({
        variables: {
          updateTeamArgs: teamsArray,
        },
      })
        .then(() => {
          dispatch(displayAlertSuccess("Teams updated successfully"));
          freeAgentRegistrations({
            variables: {
              sessionId: sessionId,
            },
          });
        })
        .catch((error: any) => {
          dispatch(displayAlertError(error.message));
        });
    } else {
      await createTeam({
        variables: {
          createTeamArgs: teamsArray,
        },
      })
        .then(() => {
          dispatch(displayAlertSuccess("Teams created successfully"));
          freeAgentRegistrations({
            variables: {
              sessionId: sessionId,
            },
          });
        })
        .catch((error: any) => {
          dispatch(displayAlertError(error.message));
        });
    }
    if (deleteTeams.length) {
      try {
        await Promise.all(
          deleteTeams.map(
            async (id) =>
              await deleteTeam({
                variables: {
                  teamDeleteId: id,
                },
              })
          )
        ).then(() => {
          dispatch(displayAlertSuccess("Teams deleted successfully"));
          setTimeout(() => {
            freeAgentRegistrations({
              variables: {
                sessionId: sessionId,
              },
            });
          }, 1000);
        });
      } catch (error: any) {
        dispatch(displayAlertError(error.message));
      }
    }
  };

  return (
    <main>
      <LoadingDialog
        open={
          isLoading ||
          loading ||
          loadingDelete ||
          loadingUpdate ||
          loadingFreeAgentReg ||
          loadingPlayerRatings
        }
      />
      <Headline1Variable>Create Free Agent Teams</Headline1Variable>
      <div className={classes.container}>
        <Fragment>
          <div className="flex w-[75%] gap-3 mb-5">
            <FormFieldSelect
              name="regions"
              value={
                !sessionFilter.regions
                  ? "0"
                  : sessionFilter.regions[0].toString()
              }
              inputChange={(value) => {
                const regions = value !== "0" ? [+value] : null;
                setSessionFilter({ ...sessionFilter, regions: regions });
              }}
              label="Region"
              placeholder="Select Region"
            >
              {[...selectedRegions, ...[{ id: 0, name: "All" }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name="registrationBatchId"
              value={sessionFilter.registrationBatchId?.toString()}
              inputChange={(value) => {
                setSessionFilter({
                  ...sessionFilter,
                  registrationBatchId: +value,
                });
              }}
              label="Seasons"
              placeholder="Select Season"
            >
              {[...registrationBatches, ...[{ id: 0, name: "Select Season" }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name="dayOfWeek"
              value={sessionFilter.dayOfWeek?.toString()}
              inputChange={(value) => {
                setSessionFilter({
                  ...sessionFilter,
                  dayOfWeek: +value,
                });
              }}
              label="Day Of Week"
              placeholder="Select Day Of Week"
            >
              {[...weekdaysArray, ...[{ id: 7, name: "All" }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name="sessionId"
              value={sessionId.toString()}
              inputChange={(value) => {
                handleChangeSession(+value);
              }}
              label="Session"
              placeholder="Select Session"
            >
              {[...sessionsSelect, ...[{ id: 0, name: "Select Session" }]]}
            </FormFieldSelect>
          </div>
        </Fragment>
      </div>
      {data &&
        data.freeAgentRegistrationBySessionId &&
        data.freeAgentRegistrationBySessionId.freeAgentRegistrations &&
        data.freeAgentRegistrationBySessionId.freeAgentRegistrations.length >
          0 && (
          <div>
            <div className="flex justify-end gap-3 mt-5 mb-5">
              <ButtonUI
                variant="secondary"
                onClick={() => setReset(!reset)}
              >
                Reset
              </ButtonUI>
              <ButtonUI
                variant="primary"
                onClick={() => onSave()}
              >
                {isEditTeams ? "Edit" : "Save"} Teams
              </ButtonUI>
            </div>
            <ButtonUI
              className="ml-4"
              variant="secondary"
              onClick={() => {
                setTeams((prevTeams: FreeAgentTeam[]) => [
                  ...prevTeams,
                  {
                    name: "",
                    order: prevTeams.length + 1,
                    players: [],
                  },
                ]);
              }}
            >
              Add Team
            </ButtonUI>
            <FreeAgentTeamBoard
              teams={teams}
              setTeams={setTeams}
              handleDeleteTeam={(teamId: number) => {
                setDeleteTeams((prevTeams) => [...prevTeams, teamId]);
              }}
              playerRatings={playerRatings?.getPlayersRatingsBySportId || []}
            />
            <div className="flex justify-end gap-3 mt-5">
              <ButtonUI
                variant="secondary"
                onClick={() => setReset(!reset)}
              >
                Reset
              </ButtonUI>
              <ButtonUI
                variant="primary"
                onClick={() => onSave()}
              >
                {isEditTeams ? "Edit" : "Save"} Teams
              </ButtonUI>
            </div>
          </div>
        )}
    </main>
  );
};

export default CreateIndyTeam;
