import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";

type GlobalContextType = {
  emailTemplateInstanceId: number | undefined;
  setEmailTemplateInstanceId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  coolMode: {
    isCoolMode: boolean;
    sound: number;
    isSound: boolean;
    particle: number;
    isParticle: boolean;
  };
  setCoolMode: React.Dispatch<
    React.SetStateAction<{
      isCoolMode: boolean;
      sound: number;
      isSound: boolean;
      particle: number;
      isParticle: boolean;
    }>
  >;
  superDarkMode: boolean;
  setSuperDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalContextDefault: GlobalContextType = {
  emailTemplateInstanceId: undefined,
  setEmailTemplateInstanceId: () => {},
  coolMode: {
    isCoolMode: false,
    sound: 0,
    isSound: false,
    particle: 0,
    isParticle: false,
  },
  setCoolMode: () => {},
  superDarkMode: false,
  setSuperDarkMode: () => {},
};

const GlobalContext = createContext<GlobalContextType>(GlobalContextDefault);

export const GlobalContextProvider = ({ children }: any) => {
  const [emailTemplateInstanceId, setEmailTemplateInstanceId] = useState<
    number | undefined
  >();
  const [coolMode, setCoolMode] = useState<{
    isCoolMode: boolean;
    sound: number;
    isSound: boolean;
    particle: number;
    isParticle: boolean;
  }>(() => {
    const state = Cookies.get("coolMode");
    if (state) {
      return JSON.parse(state);
    } else {
      return {
        isCoolMode: false,
        sound: 0,
        isSound: false,
        particle: 0,
        isParticle: false,
      };
    }
  });

  const [superDarkMode, setSuperDarkMode] = useState<boolean>(false);

  const value = {
    emailTemplateInstanceId,
    setEmailTemplateInstanceId,
    coolMode,
    setCoolMode,
    superDarkMode,
    setSuperDarkMode,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
