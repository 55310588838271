import { Outlet } from "react-router-dom";
// import { refToken } from '../app/authSlice';
import classes from "./Home.module.css";
import Navbar from "../components/Home/Navbar";
import LeftNavDrawer from "../components/Home/LeftNavDrawer";
import SuperDarkMode from "../components/UI/SuperDarkMode/SuperDarkMode";
import { useGlobalContext } from "../context/GlobalContext";

const Home = () => {
  const { superDarkMode } = useGlobalContext();

  return (
    <div className={classes.app}>
      <Navbar />
      {superDarkMode && (
        <SuperDarkMode>
          <div className={classes.app__body}>
            <div className={classes.app__main}>
              <LeftNavDrawer />
              <Outlet />
            </div>
          </div>
        </SuperDarkMode>
      )}
      {!superDarkMode && (
        <div className={classes.app__body}>
          <div className={classes.app__main}>
            <LeftNavDrawer />
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
