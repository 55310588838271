import explosion from "./explosion.mp3";
import trombone from "./trombone.mp3";
import glassBrakeSteveAustin from "./glass-break-steve-austin.mp3";
import betrayed from "./betrayed.mp3";
import fart from "./dry-fart.mp3";
import eagle from "./eagle-sound.mp3";
import gruntBirthdayParty from "./grunt-birthday-party.mp3";
import hail_to_the_king from "./hail_to_the_king.mp3";
import halo_headhost from "./halo_headhost.mp3";
import halo_killionaire from "./halo_killionaire.mp3";
import minecraft_click from "./minecraft-click-cropped.mp3";
import mlg from "./mlg.mp3";
import mouse_click from "./mouse-click-by-ek6_VR0O6PL.mp3";
import navi_hey from "./oot_navi_hey1.mp3";
import halo_overkill from "./overkill.mp3";
import rick_roll from "./rick_roll.mp3";
import super_mario_bros_3_coin from "./super-mario-bros-3-coin-sound-effect.mp3";
import switch_sound from "./switch-sound.mp3";
import halo_yoink from "./yoink-halo.mp3";
import glass from "./glass.mp3";
import surprise from "./surprise.mp3";

export const soundsList = [
  { name: "Explosion", src: explosion },
  { name: "Trombone", src: trombone },
  { name: "Glass Break SA", src: glassBrakeSteveAustin },
  { name: "Betrayed", src: betrayed },
  { name: "Fart", src: fart },
  { name: "Eagle", src: eagle },
  { name: "Grunt Birthday Party", src: gruntBirthdayParty },
  { name: "Hail To The King", src: hail_to_the_king },
  { name: "Halo Headhost", src: halo_headhost },
  { name: "Halo Killionaire", src: halo_killionaire },
  { name: "Minecraft Click", src: minecraft_click },
  { name: "Mlg", src: mlg },
  { name: "Mouse Click", src: mouse_click },
  { name: "Navi Hey", src: navi_hey },
  { name: "Halo Overkill", src: halo_overkill },
  { name: "Simple Click", src: rick_roll },
  { name: "Super Mario Bros 3 Coin", src: super_mario_bros_3_coin },
  { name: "Switch Sound", src: switch_sound },
  { name: "Halo Yoink", src: halo_yoink },
  { name: "Glass", src: glass },
  { name: "Surprise", src: surprise },
];
