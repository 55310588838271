import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../Button/Button";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import ScheduleBackup from "../ScheduleBackup";
import { FormFieldSelectMultiSearch } from "../FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import {
  ListAllOperations,
  useRegSkillChoicesBySessionIdQuery,
  useRegSkillChoicesQuery,
  useUpdateRegQuestionChoiceSkillChoiceMutation,
} from "../../../generated/graphql";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import { DialogContentText } from "@mui/material";
import Subtitle1 from "../Text/Subtitle/Subtitle1";
import { client } from "../../../graphql";

interface RegSkillChoiceDialogProps {
  registration: {
    id: number;
    regSkillChoiceId: number;
    sessionId: number;
  };
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdateRegSkillChoice: () => void;
}

/**
 * A dialog that allows the user to swap two teams
 * @param registration Array of schedule rows
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param onRestoreSchedule Function to restore the schedule
 * @returns
 */
const RegSkillChoiceDialog = ({
  registration,
  open,
  onOpenChange,
  onUpdateRegSkillChoice,
}: RegSkillChoiceDialogProps) => {
  const dispatch = useDispatch();
  const { data: regSkillChoices, loading: loadingRegSkillChoices } =
    useRegSkillChoicesBySessionIdQuery({
      variables: {
        sessionId: registration.sessionId,
      },
    });
  const [regSkillChoiceId, setRegSkillChoiceId] = useState<number | null>(null);
  const [updateRegQuestionChoiceSkillChoice, { loading }] =
    useUpdateRegQuestionChoiceSkillChoiceMutation({
      onCompleted: async () => {
        onOpenChange(false);
        dispatch(displayAlertSuccess("Registration skill choice updated"));
        onUpdateRegSkillChoice();
      },
      onError: (error) => {
        dispatch(
          displayAlertError("Failed to update registration skill choice")
        );
      },
    });
  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      sx={{
        zIndex: 30,
      }}
    >
      <DialogContent>
        <DialogTitle>
          <Headline2Variable>
            Update Registration Skill Choice
          </Headline2Variable>
        </DialogTitle>

        <Subtitle1 className="mb-2">
          Select a skill choice for the registration to be associated with, this
          will be used to determine the skill level of the player.
        </Subtitle1>
        <FormFieldSelectMultiSearch
          defaultValue={
            registration.regSkillChoiceId
              ? [`${registration.regSkillChoiceId}`]
              : []
          }
          label="Skill Choice"
          oneItem={true}
          options={
            regSkillChoices?.regSkillChoicesBySessionId.map((skill) => {
              return { value: `${skill.id}`, label: skill.name };
            }) || []
          }
          onValueChange={(values) => {
            setRegSkillChoiceId(+values[0]);
          }}
          placeholder="Select Reg Skill Choice"
          loading={loadingRegSkillChoices}
          maxCount={1}
        />

        <div className="flex mt-5 gap-2 justify-end">
          <Button
            variant="secondary"
            onClick={() => onOpenChange(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (!regSkillChoiceId) {
                dispatch(displayAlertError("Please select a skill choice"));
                return;
              }
              updateRegQuestionChoiceSkillChoice({
                variables: {
                  registrationId: registration.id,
                  regSkillChoiceId: regSkillChoiceId!,
                },
              });
            }}
          >
            Update Registration
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RegSkillChoiceDialog;
