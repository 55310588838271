import { IconButton } from "@mui/material";
import NormalLogo from "../../assets/logos/JAMsports_logo_smaller.png";
import SmallLogo from "../../assets/logos/JAMsports_logo_smallest.png";

import React, { useEffect, useState } from "react";
import { getJwtCookie, getUserDetails, logout } from "../../app/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { getRegions, handleSelectedRegions } from "../../app/venueMasterSlice";
import { RegionsQuery } from "../../../src/generated/graphql";
import WeatherNavBar from "../Admin/Weather/WeatherNavBar";
import { Label } from "../UI/shadcn/label";
import Cookies from "js-cookie";
import FormFieldPlayerSeach from "../UI/FormField/FormFieldPlayerSearch/FormFieldPlayerSearch";
import { Switch } from "../UI/shadcn/switch";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from "../UI/shadcn/dropdown";
import { cn } from "../../lib/utils";
import { FormFieldSelectMulti } from "../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { soundsList } from "../../assets/sounds";
import { particleList } from "../UI/Button/coolMode/coolMode";
import { PersonOutlined } from "@mui/icons-material";

const SELECTED_REGIONS_COOKIE_NAME = "selectedRegions";

const Navbar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { coolMode, setCoolMode, setSuperDarkMode, superDarkMode } =
    useGlobalContext();
  const { regions } = useSelector((state: RootState) => state.venueMaster);
  const { user }: any = useSelector((state: RootState) => state.auth);

  const [regionFilter, setRegionFilter] = useState<
    (RegionsQuery["regions"][0] & { selected: boolean })[]
  >([]);

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(getRegions(""));
    if (!user) {
      const token = getJwtCookie();
      if (token == null) {
        return;
      }
      dispatch(getUserDetails(token));
    }
  }, [dispatch]);

  useEffect(() => {
    if (regions.length > 0) {
      const regionsCopy = [...regions];
      let selectionRegions = regionsCopy.map((region) => ({
        ...region,
        selected: true,
      }));
      const savedSelectedRegions = Cookies.get(SELECTED_REGIONS_COOKIE_NAME);
      if (savedSelectedRegions) {
        const savedSelectedRegionsArray: number[] =
          JSON.parse(savedSelectedRegions);
        selectionRegions = regionsCopy.map((region) => ({
          ...region,
          selected: savedSelectedRegionsArray.some(
            (selectedRegion) => selectedRegion === region.id
          ),
        }));
      }
      setRegionFilter(selectionRegions);
    }
  }, [regions]);

  useEffect(() => {
    if (regionFilter.length === 0) {
      return;
    }
    const selectedRegions = regionFilter
      .filter((region) => region.selected)
      .map((region) => {
        const { selected, ...regionCopy } = region;
        return regionCopy;
      });
    Cookies.set(
      SELECTED_REGIONS_COOKIE_NAME,
      JSON.stringify(selectedRegions.map((region) => region.id)),
      {
        expires: 365,
      }
    );
    dispatch(handleSelectedRegions(selectedRegions));
  }, [regionFilter]);

  return (
    <div
      className={cn(
        "fixed w-full flex items-center justify-between px-4 z-50 py-2 h-16",
        process.env.REACT_APP_ENV === "production"
          ? "bg-secondary-10"
          : "bg-staging-nav"
      )}
    >
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{
          mr: 2,
          paddingBottom: "5px",
          paddingTop: "5px",
        }}
        onClick={() => navigate("/")}
      >
        <img
          src={SmallLogo}
          srcSet={`${SmallLogo} 1x, ${NormalLogo} 2x`}
          sizes="100px"
          alt="JAMsports Logo"
          style={{ width: "100px", height: "auto" }}
        />
      </IconButton>
      <div className="flex gap-2">
        <FormFieldPlayerSeach
          onUserSelect={(player) => navigate(`/players/user/${player.id}`)}
        />

        <FormFieldSelectMulti
          name="selectedRegions"
          input={regionFilter.map((region) => ({
            id: region.id,
            name: region.name,
          }))}
          addAllOption={true}
          selectedItems={regionFilter
            .filter((region) => region.selected)
            .map((region) => region.id)}
          setSelectedItems={(values) => {
            setRegionFilter((prevState) => {
              return prevState.map((prevRegion) => {
                return {
                  ...prevRegion,
                  selected: values.includes(prevRegion.id),
                };
              });
            });
          }}
          placeholder="Region"
          className="w-60 whitespace-nowrap"
        />
        <WeatherNavBar />
        <DropdownMenu>
          <DropdownMenuTrigger>
            <PersonOutlined className="text-white" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="z-50">
            <DropdownMenuItem>
              <div
                className="flex flex-row items-center"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <Switch
                  id="coolMode"
                  checked={coolMode.isCoolMode}
                  onCheckedChange={(newState) => {
                    if (!newState) setSuperDarkMode(false);
                    setCoolMode((prevState) => ({
                      ...prevState,
                      isCoolMode: newState,
                    }));
                    Cookies.set(
                      "coolMode",
                      JSON.stringify({
                        isCoolMode: newState,
                        sound: coolMode.sound,
                        isSound: coolMode.isSound,
                        particle: coolMode.particle,
                        isParticle: coolMode.isParticle,
                      })
                    );
                  }}
                />
                <Label
                  htmlFor="coolMode"
                  className="ml-2"
                >
                  Cool Mode
                </Label>
              </div>
            </DropdownMenuItem>

            {coolMode.isCoolMode && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <div
                    className="flex flex-row items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <Switch
                      id="coolMode"
                      checked={coolMode.isSound}
                      onCheckedChange={(newState) => {
                        setCoolMode((prevState) => ({
                          ...prevState,
                          isSound: newState,
                        }));
                        Cookies.set(
                          "coolMode",
                          JSON.stringify({
                            isCoolMode: newState,
                            sound: coolMode.sound,
                            isSound: newState,
                            particle: coolMode.particle,
                            isParticle: coolMode.isParticle,
                          })
                        );
                      }}
                    />
                    <Label
                      htmlFor="coolMode"
                      className="ml-2"
                    >
                      Sounds
                    </Label>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <div
                    className="flex flex-row items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <FormFieldSelect
                      value={coolMode.sound?.toString()}
                      className="m-0"
                      name="coolModeSound"
                      id="coolModeSound"
                      placeholder=""
                      inputChange={(value) => {
                        setCoolMode((prevState) => ({
                          ...prevState,
                          sound: +value,
                        }));
                        Cookies.set(
                          "coolMode",
                          JSON.stringify({
                            isCoolMode: coolMode.isCoolMode,
                            sound: +value,
                            particle: coolMode.particle,
                          })
                        );
                      }}
                    >
                      {soundsList.map((sound: any, index: number) => ({
                        id: index,
                        name: sound.name,
                      }))}
                    </FormFieldSelect>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <div
                    className="flex flex-row items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <Switch
                      id="coolMode"
                      checked={coolMode.isParticle}
                      onCheckedChange={(newState) => {
                        setCoolMode((prevState) => ({
                          ...prevState,
                          isParticle: newState,
                        }));
                        Cookies.set(
                          "coolMode",
                          JSON.stringify({
                            isCoolMode: newState,
                            sound: coolMode.sound,
                            isSound: coolMode.isSound,
                            particle: coolMode.particle,
                            isParticle: newState,
                          })
                        );
                      }}
                    />
                    <Label
                      htmlFor="coolMode"
                      className="ml-2"
                    >
                      Button Particles
                    </Label>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <div
                    className="flex flex-row items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <FormFieldSelect
                      value={coolMode.particle?.toString()}
                      className="m-0"
                      name="coolModeParticle"
                      id="coolModeParticle"
                      placeholder=""
                      inputChange={(value) => {
                        setCoolMode((prevState) => ({
                          ...prevState,
                          particle: +value,
                        }));
                        Cookies.set(
                          "coolMode",
                          JSON.stringify({
                            isCoolMode: coolMode.isCoolMode,
                            sound: coolMode.sound,
                            particle: +value,
                          })
                        );
                      }}
                    >
                      {particleList.map((particle, index) => ({
                        id: index,
                        name: particle.name,
                      }))}
                    </FormFieldSelect>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Switch
                    id="superDarkMode"
                    checked={superDarkMode}
                    onCheckedChange={(newState) => {
                      setSuperDarkMode(newState);
                    }}
                  />
                  <Label
                    htmlFor="superDarkMode"
                    className="ml-2"
                  >
                    Super Dark Mode
                  </Label>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}
            <DropdownMenuItem onClick={handleLogout}>Log Out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Navbar;
