import {
  Box,
  Button as MuiButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ContractManagement.module.css";
import { useTable, useSortBy, Column } from "react-table";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Division } from "../../types/types";
import {
  cleanupDivisionToEdit,
  deleteDivision,
} from "../../app/venueMasterSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import ActionsCell from "../UI/ActionCell";
import TablePagination from "../UI/Pagination/Pagination";
import { DivisionsQuery, useDivisionsLazyQuery } from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Disclaimer from "../UI/Alerts/Disclaimer";
import Body1 from "../UI/Text/Body/Body1";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

const options = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Delete" },
];

interface Pagination {
  page?: number;
  pageCount?: number;
  pageSize?: number;
}

const Divisions: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = React.useState(0);
  const [divisions, setDivisions] = useState<DivisionsQuery["divisions"]>([]);
  const publicUrl = process.env.PUBLIC_URL;
  const pageSize = 10;
  const [pagination, setPagination] = useState<null | Pagination>(null);

  const weekdays = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Select a day" },
  ];

  enum DayOfWeek {
    Sunday = 0,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
  }

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [getDivisions, { loading: loadingDivisions }] = useDivisionsLazyQuery({
    variables: { divisionFilters: {} },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setDivisions(data.divisions);
    },
  });

  useEffect(() => {
    getDivisions();
  }, []);

  useEffect(() => {
    getDivisions();
  }, [selectedRegions]);

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  const redirectCreateDivision = () => {
    // dispatch(toggleView(false));
    // dispatch(createSportFormatView(1));
    dispatch(cleanupDivisionToEdit(""));
    navigate(`/ops/division/`);
  };

  const redirectEditDivision = (divisionId: number) => {
    navigate(`/ops/division/${divisionId}`, {
      state: { divisionId },
    });
  };

  const redirectDeleteDivision = (divisionId: number, latestSchedule: any) => {
    if (latestSchedule) {
      dispatch(displayAlertError("Division has schedule, cannot be deleted"));
    } else {
      setDeleteModal(divisionId);
    }
  };

  const redirectSchedule = (divisionId: number) => {
    navigate(`/ops/division/${divisionId}/schedule`, {
      state: { divisionId },
    });
  };

  const handleDeleteDivision = () => {
    dispatch(deleteDivision(deleteModal)).then((res) => {
      if (res.payload.success) {
        getDivisions();
      }
      setDeleteModal(0);
    });
  };

  const handleSelectedOption = (row: any, idx: number) => {
    console.log(row);
    if (idx === 1) {
      // edit
      redirectEditDivision(row.original.id);
    }
    if (idx === 2) {
      // delete
      redirectDeleteDivision(row.original.id, row.original?.latestSchedule);
    }
  };

  const COLUMNS: Column<DivisionsQuery["divisions"][0]>[] = [
    {
      Header: "Name",
      id: "name",
      accessor: (d: any) => {
        return (
          <a
            className="underline"
            href={`/ops/division/${d.id}`}
          >
            {d.name}
          </a>
        );
      },
    },
    {
      Header: "Session",
      id: "session",
      accessor: (d: any) => {
        return (
          <div>{`${DayOfWeek[d.session.dayOfWeek]} ${
            d.session.league.name
          } (${moment(d.session.startDate).format("MMM DD, YYYY")})`}</div>
        );
      },
    },
    {
      Header: "Day",
      id: "day",
      accessor: (d: any) => {
        return <div>{`${DayOfWeek[d.session.dayOfWeek]}`}</div>;
      },
    },
    {
      Header: "Start Date",
      id: "startDate",
      accessor: (d: any) => {
        return <div>{moment(d.startDate).format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "Teams",
      id: "endDate",
      accessor: (d: any) => {
        return <div>{d.teams.length}</div>;
      },
    },
    {
      Header: "Schedule",
      accessor: (d: any) => {
        return (
          <div className={classes.format__options}>
            <MuiButton
              sx={{ color: d.latestSchedule ? "green" : "black" }}
              onClick={() => redirectSchedule(d.id)}
            >
              {<CalendarMonthIcon />}
            </MuiButton>
          </div>
        );
      },
    },
    {
      Header: " ",
      Cell: (d: any) => {
        return (
          <ActionsCell
            row={d.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
        // return (
        //   <div className={classes.format__options}>
        //     <div className={classes.format__option}>
        //       <MuiButton
        //         sx={{ color: 'black' }}
        //         onClick={() => redirectEditDivision(d.id)}
        //       >
        //         {<EditIcon />}
        //       </MuiButton>
        //       <MuiButton
        //         sx={{ color: 'black' }}
        //         onClick={() => redirectDeleteDivision(d.id)}
        //       >
        //         {<DeleteIcon />}
        //       </MuiButton>
        //     </div>
        //   </div>
        // );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => divisions, [divisions]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id"],
        },
      },
      useSortBy
    );
  const sportFormatsTable = (
    <TableContainer
      className={classes.shift__container}
      sx={{
        maxHeight: "80vh",
        width: "95vw",
        maxWidth: "100%",
        bgcolor: "white",
        padding: "20px",
        paddingTop: "0px",
        borderRadius: "12px",
      }}
    >
      {" "}
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
        className={classes.shift__table}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: "white",
                    color: "var(--textColor)",
                    fontSize: "16px",
                    textAlign: "left",
                    fontWeight: 550,
                    borderColor: "var(--table-border)",
                    paddingBottom: "5px",
                  }}
                  {...column
                    .getHeaderProps
                    // column.getSortByToggleProps()
                    ()}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    {/* <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownwardIcon />
                            ) : (
                              <ArrowUpwardIcon />
                            )
                          ) : (
                            ''
                          )}
                        </span> */}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                sx={{
                  borderBottom: "5px",
                  borderWidth: "2px",
                }}
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "var(--textColor)",
                        // borderWidth: '2px',
                        borderColor: "var(--table-border)",
                        paddingBottom: "8px",
                        paddingTop: "8px",
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={pagination?.page || 0}
            pageCount={pagination?.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </TableContainer>
  );
  return (
    <main className="flex flex-col">
      <LoadingDialog open={loadingDivisions} />
      <div className="flex flex-row items-center justify-between w-full mb-4">
        <Headline1Variable>Divisions</Headline1Variable>
        <Button
          variant="primary"
          className="h-fit"
          onClick={redirectCreateDivision}
        >
          <AddIcon
            fontSize="large"
            sx={{
              width: "18px",
              height: "18px",
            }}
          />
          <span>New Division</span>
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        {divisions
          .filter((division) =>
            division.latestSchedule?.regularGames.some(
              (game) => game.homeTeam === null || game.awayTeam === null
            )
          )
          .map((division) => {
            return (
              <Disclaimer
                variant="error"
                key={division.id}
                title={`${division.name} (id ${division.id}) has invalid games`}
                size="large"
                persist={true}
              />
            );
          })}
      </div>
      <div className={classes.container}>
        <div className={classes.table__venues}>{sportFormatsTable}</div>
      </div>
      <Modal
        open={deleteModal ? true : false}
        onClose={() => {
          setDeleteModal(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <p> Do you want to delete the division ?</p>
          <div className={classes.actions}>
            <MuiButton
              variant="contained"
              className={classes.button__alt}
              onClick={handleDeleteDivision}
              sx={{
                marginRight: "10px",
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
              }}
            >
              Delete
            </MuiButton>
            <MuiButton
              variant="contained"
              className={classes.button__alt}
              onClick={() => {
                setDeleteModal(0);
              }}
              sx={{
                marginRight: "10px",
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
              }}
            >
              Cancel
            </MuiButton>
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default Divisions;
