import React, { useEffect, useMemo, useState } from "react";
import ActionsCell from "../../../UI/ActionCell";
import {
  RegistrationBySessionIdQuery,
  useRegistrationBySessionIdLazyQuery,
} from "../../../../generated/graphql";
import { useSessionWorkflowContext } from "../../../../context/SessionWorkflowContext";
import dayjs from "dayjs";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../../../UI/Table/DataTable";
import RegSkillChoiceDialog from "../../../UI/Dialog/RegSkillChoiceDialog";

const SessionWorkflowRegistration: React.FC = () => {
  /*** STATES ***/
  const { selectedSession, tabGroupValue } = useSessionWorkflowContext();
  const [registrations, setRegistraions] = useState<
    RegistrationBySessionIdQuery["registrationBySessionId"]
  >([]);
  const [openUpdateRegSkillChoiceModal, setOpenUpdateRegSkillChoiceModal] =
    useState<{
      open: boolean;
      registration:
        | RegistrationBySessionIdQuery["registrationBySessionId"][0]
        | null;
    }>({
      open: false,
      registration: null,
    });

  /*** QUERIES ***/
  const [getRegistrations, { data, loading, refetch }] =
    useRegistrationBySessionIdLazyQuery({
      fetchPolicy: "network-only",
    });

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (selectedSession && tabGroupValue === "Registrations") {
      getRegistrations({
        variables: {
          sessionId: selectedSession.id,
        },
      });
    } else {
      setRegistraions([]);
    }
  }, [selectedSession]);

  /*** UTILITY FUNCTIONS ***/

  const handleSelectedOption = (
    row: RegistrationBySessionIdQuery["registrationBySessionId"][0],
    idx: number
  ) => {
    if (idx === 1) {
      // Update Reg Skill
      setOpenUpdateRegSkillChoiceModal({
        open: true,
        registration: row,
      });
    }
  };

  const COLUMNS: ColumnDef<
    RegistrationBySessionIdQuery["registrationBySessionId"][0]
  >[] = [
    {
      id: "createdAt",
      header: "Created At",
      accessorFn: (d) => dayjs(d.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      id: "firstName",
      header: "Player Name",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/user/${row.original.user.id}`}
          >
            {row.original.user.firstName} {row.original.user.lastName}
          </a>
        );
      },
    },
    {
      id: "gender",
      header: "Gender",
      accessorFn: (row) => row.user.genderIdentity?.name,
    },
    {
      id: "email",
      header: "Email",
      accessorFn: (row) => row.user.email,
    },
    {
      id: "region",
      header: "Region",
      accessorFn: (row) => row.session.league.sportFormat.region.name,
    },
    {
      id: "registrationType",
      header: "Reg Type",
      accessorFn: (row) => row.registrationType.type,
    },
    {
      id: "teamName",
      header: "Team Name",
      accessorFn: (row) => row.team?.name || "N/A",
    },
    {
      id: "season",
      header: "Season",
      accessorFn: (row) => row.session.registrationBatch.name,
    },
    {
      id: "shoppingCartId",
      header: "Shopping Cart Id",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${row.original.shoppingCart.id}`}
          >
            {row.original.shoppingCart.id}
          </a>
        );
      },
    },
    {
      id: "amountPaid",
      header: "Amount",
      accessorFn: (row) => row.shoppingCartItem.amountPaid,
    },
    {
      id: "regSkillChoice",
      header: "Reg Skill Choice",
      accessorFn: (row) => row.regQuestionChoice?.regSkillChoice?.name || "N/A",
    },
    {
      id: "isDeleted",
      header: "Deleted?",
      accessorFn: (row) => (row.isDeleted ? "Yes" : "No"),
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row}
            options={[
              {
                id: 1,
                text: "Update Reg Skill",
              },
            ]}
            handleSelectedOption={(row, optionId) => {
              handleSelectedOption(row.original, optionId);
            }}
          />
        );
      },
    },
  ];

  // Constants for the table
  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => {
    if (data?.registrationBySessionId) return data.registrationBySessionId;
  }, [data?.registrationBySessionId]);

  return (
    <main className="flex flex-col gap-4">
      {tableData && (
        <DataTable
          columns={columns}
          data={tableData}
        />
      )}
      {openUpdateRegSkillChoiceModal.registration && (
        <RegSkillChoiceDialog
          open={openUpdateRegSkillChoiceModal.open}
          registration={{
            sessionId: +openUpdateRegSkillChoiceModal.registration?.session.id,
            id: +openUpdateRegSkillChoiceModal.registration.id || 0,
            regSkillChoiceId:
              +openUpdateRegSkillChoiceModal.registration?.regQuestionChoice
                ?.regSkillChoiceId!,
          }}
          onOpenChange={(open) => {
            if (!open) {
              setOpenUpdateRegSkillChoiceModal({
                open,
                registration: null,
              });
            }
          }}
          onUpdateRegSkillChoice={() => refetch()}
        />
      )}
    </main>
  );
};

export default SessionWorkflowRegistration;
