import { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "../Button/Button";
import Card from "../Card/Card";
import {
  GetImagesPaginatedQuery,
  ImageManagerDirectory,
  useCreateImageMutation,
  useGetImageManagerDirectoryTreeByNameQuery,
  useGetImagesPaginatedLazyQuery,
} from "../../../generated/graphql";
import { File, Folder, Tree, TreeViewElement } from "../magic-ui/file-tree";
import { useImageManagerContext } from "../../../context/ImageMangerContext";
import dayjs from "dayjs";
import { multipartFetch } from "../../../app/authSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import FormFieldFile from "../FormField/FormFieldFile/FormFieldFile";
import { FileUploadOutlined } from "@mui/icons-material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Blob } from "buffer";
import CropImageDialog from "./CropImageDialog";

const fileTypes = ["JPEG", "PNG", "SVG", "JPG", "WEBP"];

interface TournamentImagesDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  handleApplyImage: (image: string) => void;
}

/**
 * A dialog that allows the user to swap two teams
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @returns
 */
const TournamentImagesDialog = ({
  open,
  onOpenChange,
  handleApplyImage,
}: TournamentImagesDialogProps) => {
  const { selectedDirectoryId, setSelectedDirectoryId } =
    useImageManagerContext();

  const dispatch = useDispatch<AppDispatch>();

  const [imageManagerDirectoryTree, setImageManagerDirectoryTree] = useState<
    TreeViewElement[]
  >([]);
  const [imageManagerDirectoryData, setImageManagerDirectoryData] = useState<
    ImageManagerDirectory[]
  >([]);
  const [selectedDirectoryPath, setSelectedDirectoryPath] =
    useState<string>("");
  const [files, setFiles] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedDirectoryImages, setSelectedDirectoryImages] = useState<
    GetImagesPaginatedQuery["getImagesPaginated"]["images"]
  >([]);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [imgName, setImageName] = useState<string>("");

  const flattenDirectoryTree = (directory: any) => {
    // Start with the current directory
    let flatArray = [directory];

    // Recursively flatten the children if they exist
    if (directory.children && directory.children.length > 0) {
      directory.children.forEach((child: any) => {
        flatArray = flatArray.concat(flattenDirectoryTree(child));
      });
    }

    // Return the flattened array
    return flatArray;
  };

  /** Queries */
  const { loading: loadingVenueImageManagerTree, refetch } =
    useGetImageManagerDirectoryTreeByNameQuery({
      variables: {
        name: "tournament_images",
      },
      onCompleted: (data) => {
        setImageManagerDirectoryTree([data.getImageManagerDirectoryTreeByName]);
        const flattenedDirectories = flattenDirectoryTree(
          data.getImageManagerDirectoryTreeByName
        );
        setImageManagerDirectoryData(flattenedDirectories);
        setSelectedDirectoryId({
          id: flattenedDirectories[0].id,
          name: flattenedDirectories[0].name,
        });
        setSelectedDirectoryPath(getFullPath(flattenedDirectories, 3));
      },
      fetchPolicy: "network-only",
    });

  const [getImages, { loading: loadingImages }] =
    useGetImagesPaginatedLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setSelectedDirectoryImages(data.getImagesPaginated.images);
      },
    });

  /**Mutations*/

  const [createImage, { loading: loadingCreateImage }] =
    useCreateImageMutation();

  /** Use Effects */
  useEffect(() => {
    if (selectedDirectoryPath) {
      getImages({
        variables: {
          filePath: `image_manager/ops${selectedDirectoryPath}`,
        },
      });
    }
  }, [selectedDirectoryPath]);

  useEffect(() => {
    if (files) {
      handleUpload();
    }
  }, [files]);

  /** Function */

  const handleUpload = async () => {
    if (!files) return;
    const formData = new FormData();
    formData.append("files", files, imgName); // Append the file name to the form data
    formData.append(
      `filePath_${0}`,
      `image_manager/ops${selectedDirectoryPath}`
    ); // Add modified file name as a field\
    try {
      await multipartFetch
        .post("/imageManager/upload", formData)
        .then(async (response) => {
          if (response.status === 200) {
            dispatch(
              displayAlertWarning(
                `Oops! Duplicate images detected: ${response.data.duplicates.map(
                  (duplicateImage: string) => duplicateImage
                )}`
              )
            );
            return;
          }

          // wait for 1 second to allow the images to be uploaded to GCP
          const imagesForDb: {
            fileName: string;
            publicUrl: string;
            imageHash: string;
            filePath: string;
          }[] = response.data.results.map(
            (file: {
              fileName: string;
              publicUrl: string;
              hash: string;
              filePath: string;
            }) => {
              return {
                fileName: file.fileName.slice(
                  file.fileName.lastIndexOf(":") + 1
                ),
                publicUrl: file.publicUrl,
                imageHash: file.hash,
                filePath: file.filePath,
              };
            }
          );
          await createImage({
            variables: {
              createImageInput: {
                images: imagesForDb,
              },
            },
            onCompleted: (data) => {
              if (data.createImage.success) {
                dispatch(displayAlertSuccess(data.createImage.message));
              } else {
                dispatch(displayAlertError(data.createImage.message));
              }
              getImages({
                variables: {
                  filePath: `image_manager/ops${selectedDirectoryPath}`,
                },
              });
            },
            onError: (error) => {
              dispatch(displayAlertError(error.message));
            },
          });
        });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  function getFullPath(data: ImageManagerDirectory[], id: number): string {
    const idToNodeMap: { [key: number]: ImageManagerDirectory } = {};

    // Map all items by their id for quick lookup
    data.forEach((item) => {
      idToNodeMap[item.id] = item;
    });

    // Recursive function to build the path
    function buildPath(nodeId: number): string {
      const node = idToNodeMap[nodeId];
      if (!node || node.parentId === 0) {
        return node ? node.name : ""; // Return the name of the root node
      }
      return `${buildPath(node.parentId)}/${node.name}`; // Recursively prepend the parent's path
    }

    return buildPath(id);
  }

  const handleChangeimage = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result as string); // Set image source to the file content
        setImageName(file.name);
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const renderTree = (elements: TreeViewElement[]) => {
    return elements.map((element) => {
      if (element.children) {
        return (
          <Folder
            key={element.id}
            value={element.id}
            element={element.name}
            isSelect={+element.id === selectedDirectoryId?.id}
            handleFolderClicked={({ id, name }) => {
              setSelectedDirectoryId({ id: id, name: name });
              setSelectedDirectoryPath(
                getFullPath(imageManagerDirectoryData, id)
              );
            }}
          >
            {renderTree(element.children)}
          </Folder>
        );
      } else {
        return (
          <File
            key={element.id}
            value={element.id}
          >
            <p>{element.name}</p>
          </File>
        );
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
        sx={{
          zIndex: 30,
          marginTop: "8vh",
        }}
      >
        <DialogContent>
          <div className="w-[70vw] max- max-h-[70vh]">
            <div className="flex items-center justify-between">
              <div className="text-lg"> Tournament Image Manager</div>
              <div className="flex gap-3">
                <FormFieldFile
                  onChange={handleChangeimage}
                  accept="image/png, image/gif, image/jpeg"
                  multiple={false}
                  text="Upload"
                  className="text-nowrap"
                  icon={<FileUploadOutlined />}
                />
              </div>
            </div>
            <div className="flex gap-3 mt-5">
              <div className="w-1/4">
                {imageManagerDirectoryTree &&
                  imageManagerDirectoryTree.length !== 0 && (
                    <Tree
                      className="p-2 overflow-hidden rounded-md bg-background"
                      elements={imageManagerDirectoryTree}
                    >
                      {renderTree(imageManagerDirectoryTree)}
                    </Tree>
                  )}
              </div>
              <div className="flex flex-wrap justify-center w-3/4 gap-3">
                {selectedDirectoryImages &&
                  selectedDirectoryImages.length > 0 &&
                  selectedDirectoryImages.map((image) => (
                    <div
                      className={`w-[200px] cursor ${
                        image.publicUrl === selectedImage
                          ? "border-2 border-primary-80 p-1"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedImage(image.publicUrl);
                      }}
                    >
                      <img
                        className="mb-2 rounded-sm bg-neutral-80"
                        src={image.publicUrl}
                        alt="image"
                      />
                      <div className="text-sm break-words text-light text-wrap">
                        {image.fileName}
                      </div>
                      <div className="text-sm text-light">
                        {dayjs(image.createdAt).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => onOpenChange(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (selectedImage) {
                handleApplyImage(selectedImage);
                setSelectedImage(null);
                onOpenChange(false);
              }
            }}
          >
            Apply Image
          </Button>
        </DialogActions>
        <CropImageDialog
          open={imgSrc !== null}
          onOpenChange={(open) => setImgSrc(null)}
          handleCroppedImage={(blob) => {
            setFiles(blob);
            setImgSrc(null);
          }}
          src={imgSrc || ""}
        />
      </Dialog>
    </>
  );
};

export default TournamentImagesDialog;
