import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { Column } from "react-table";
import ActionsCell from "../../UI/ActionCell";
import BaseTable from "../../UI/Table/Table";
import TablePagination from "../../UI/Pagination/Pagination";
import { LoadingMaterialUI } from "../../UI";
import moment from "moment";
import {
  getAllContracts,
  getVendors,
  getVenues,
} from "../../../app/venueMasterSlice";
import { Contract, Pagination, PaginationType } from "../../../types/types";
import {
  ContractsByFilterPaginatedQuery,
  ContractsByFilterPaginatedQueryVariables,
  useContractsByFilterPaginatedQuery,
  useVenuesByVendorIdQuery,
} from "../../../generated/graphql";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../UI/shadcn/dropdown";
import { MoreVerticalIcon } from "lucide-react";
import PaginationDataTable from "../../UI/Pagination/PaginationDataTable";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormField from "../../UI/FormField/FormField";

const ContractManagement: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, vendorList } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const navigate = useNavigate();
  //Pagination Page Size
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 25,
    pageCount: undefined,
  });

  const [contractFilter, setContractFilter] = useState<
    ContractsByFilterPaginatedQueryVariables["contractsByFilterPaginatedInput"]["filter"]
  >({
    regionId: 0,
    vendorId: 0,
    venueId: 0,
    name: "",
  });
  const [contracts, setContracts] = useState<
    ContractsByFilterPaginatedQuery["contractsByFilterPaginated"]["contracts"]
  >([]);

  const redirectCreateContract = () => {
    navigate(`/ops/contract/new`);
  };

  const { data: dataContracts, loading: loadingContracts } =
    useContractsByFilterPaginatedQuery({
      variables: {
        contractsByFilterPaginatedInput: {
          filter: contractFilter,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
      },
      onCompleted: (data) => {
        if (data.contractsByFilterPaginated) {
          setContracts(data.contractsByFilterPaginated.contracts);
          setPagination((prevState: PaginationType) => {
            return {
              ...prevState,
              pageCount: data.contractsByFilterPaginated.count,
            };
          });
        }
      },
    });

  const { data: dataVenues, loading: loadingVenues } = useVenuesByVendorIdQuery(
    {
      variables: {
        vendorId: contractFilter.vendorId!,
      },
      skip:
        contractFilter.vendorId == undefined || contractFilter.vendorId === 0,
    }
  );

  useEffect(() => {
    dispatch(getVendors({}));
  }, []);
  const contractsColumns: ColumnDef<
    ContractsByFilterPaginatedQuery["contractsByFilterPaginated"]["contracts"][0]
  >[] = [
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <a
            className="underline transition-colors text-info-70 hover:text-info-50"
            href={`/ops/contract/${row.original.id}`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Vendor",
      id: "vendor",

      accessorFn: (row) => `${row.vendor.name}`,
    },
    {
      header: "Region",
      id: "region",
      accessorFn: (d) => d.region.name,
    },
    {
      header: "Type",
      id: "type",
      accessorFn: (d) => d.type.name,
    },

    {
      header: "Permit Status",
      id: "status",
      accessorFn: (d) => d.permitStatus.name,
    },
    {
      header: "Approval Status",
      id: "approvalStatus",
      accessorFn: (d) => d.approvalStatus.name,
    },
    {
      header: "Created",
      id: "createdAt",
      accessorFn: (d) => moment(d.createdAt).local().format("YYYY-MM-DD"),
    },
    {
      header: "Last Edit",
      id: "updatedAt",
      accessorFn: (d) => moment(d.updatedAt).local().format("YYYY-MM-DD"),
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a href={`/ops/contract/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Edit
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return (
    <main className="flex flex-col gap-4">
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Contracts</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={redirectCreateContract}
          >
            <AddIcon />
            New Contract
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <FormFieldSelect
          inputChange={(value) => {
            setContractFilter((prevState) => {
              return {
                ...prevState,
                regionId: +value,
              };
            });
          }}
          label="Region"
          placeholder="Region"
          value={contractFilter.regionId?.toString() ?? "0"}
        >
          {[{ id: "0", name: "All" }, ...selectedRegions]}
        </FormFieldSelect>
        <FormFieldSelect
          inputChange={(value) => {
            setContractFilter((prevState) => {
              return {
                ...prevState,
                vendorId: +value,
              };
            });
          }}
          label="Vendor"
          placeholder="Vendor"
          value={contractFilter.vendorId?.toString() ?? "-1"}
        >
          {[{ id: "0", name: "All" }, ...vendorList]}
        </FormFieldSelect>
        <FormFieldSelect
          inputChange={(value) => {
            setContractFilter((prevState) => {
              return {
                ...prevState,
                venueId: +value,
              };
            });
          }}
          label="Venue"
          placeholder="Venue"
          value={contractFilter.venueId?.toString() ?? "0"}
        >
          {[
            ...(dataVenues
              ? [
                  { id: "0", name: "All" },
                  ...dataVenues.venuesByVendorId.map((venue) => ({
                    id: venue.id.toString(),
                    name: venue.name,
                  })),
                ]
              : [{ id: 0, name: "Select a Vendor" }]),
          ]}
        </FormFieldSelect>
        <FormField
          initialValue={contractFilter.name!}
          inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setContractFilter((prevState) => ({
              ...prevState,
              name: e.target.value,
            }));
          }}
          name="search"
          placeholder="Contract Search"
          className="bg-white"
        />
      </div>
      {contracts.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={contracts}
            columns={contractsColumns}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default ContractManagement;
