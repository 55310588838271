import { PaginationType } from "@/src/types/types";
import { Pagination } from "@mui/material";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../UI/shadcn/select";
import React, { FC } from "react";

interface PaginationDataTableProps {
  pagination: PaginationType;
  setPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
}

const PaginationDataTable: FC<PaginationDataTableProps> = ({
  pagination,
  setPagination,
}) => {
  return (
    <div>
      {pagination.pageCount ? (
        <>
          <div className="my-auto text-xs text-disabled">
            Page {pagination.page + 1} of{" "}
            {Math.ceil(pagination.pageCount / pagination.pageSize)}
          </div>
          <div className="flex flex-row">
            <Pagination
              page={pagination.page + 1}
              count={Math.ceil(pagination.pageCount / pagination.pageSize)}
              onChange={(event: any, value: number) => {
                setPagination((prevState: PaginationType) => {
                  return {
                    ...prevState,
                    page: value - 1,
                  };
                });
              }}
            />
            <Select
              value={`${pagination.pageSize.toString()}`}
              onValueChange={(value) => {
                setPagination((prevState: PaginationType) => {
                  return {
                    ...prevState,
                    page: Math.floor(
                      (prevState.page * prevState.pageSize) / Number(value)
                    ),
                    pageSize: Number(value),
                  };
                });
              }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue placeholder={pagination.pageSize.toString()} />
              </SelectTrigger>
              <SelectContent side="top">
                {[1, 5, 10, 25, 50, 100].map((pageSize) => (
                  <SelectItem
                    key={pageSize}
                    value={`${pageSize}`}
                    className="hover:bg-primary-95"
                  >
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PaginationDataTable;
