import { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { GameSummaryFilter, Pagination } from "../../types/types";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { dayOfWeek } from "../../utils/dayOfWeek";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Button from "../UI/Button/Button";
import dayjs from "dayjs";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import {
  GamesQuery,
  useGamesLazyQuery,
  useVenuesFacilityWithFilterLazyQuery,
} from "../../../src/generated/graphql";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import { FormFieldSelectMultiSearch } from "../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";

const GameSummary = () => {
  const { selectedRegions, isLoading }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const pageSize = 50;

  const dispatch = useDispatch<AppDispatch>();

  const [pagination, setPagination] = useState<Pagination>({
    pageCount: 0,
    pageSize: pageSize,
    page: 0,
  });
  const [gameSummaryReportFilter, setGameSummaryReportFilter] =
    useState<GameSummaryFilter>({
      regionId: null,
      venueId: null,
      date: dayjs().format("YYYY-MM-DD"),
    });
  const [loading, setLoading] = useState(false);

  /** QUERIES */
  const [getGameSummaryReport, { data: gameSummaryReportData }] =
    useGamesLazyQuery({
      fetchPolicy: "no-cache",
    });

  const getGames = async () => {
    getGameSummaryReport({
      variables: {
        filter: {
          venueId: gameSummaryReportFilter.venueId,
          regionId: gameSummaryReportFilter.regionId,
          date: dayjs(gameSummaryReportFilter.date).format("YYYY-MM-DD"),
        },
      },
    });
  };

  const [getVenues, { loading: loadingVenues, data: dataVenues }] =
    useVenuesFacilityWithFilterLazyQuery({
      variables: {
        venueFilters: {
          regionId: [gameSummaryReportFilter.regionId],
        },
      },
    });

  const dailyGameSummaryReportData = useMemo(() => {
    if (gameSummaryReportData) {
      return gameSummaryReportData.games;
    } else {
      return [];
    }
  }, [gameSummaryReportData]);

  const gameSummaryReportColumn: ColumnDef<GamesQuery["games"][0]>[] = [
    {
      id: "Venue",
      header: "Venue",
      accessorFn: (d) => d.venue.name,
      enableSorting: true,
    },
    {
      id: "regionId",
      header: "Region",
      accessorFn: (d) => d.venue.venueDetail.region.name,
    },
    {
      id: "gameStartTime",
      header: "Game Start Time",
      accessorFn: (d) => dayjs(d.startDateTimeLocal).format("HH:mm"),
    },
    {
      id: "league",
      header: "League",
      accessorFn: (d) => d?.division?.session?.league?.name,
    },
    {
      id: "division",
      header: "Division",
      accessorFn: (d) => d.division.name,
      cell: ({ row }) => {
        return (
          <a
            className="underline text-info-50 hover:text-info-70"
            href={`/ops/division/${row.original.division.id}/schedule`}
          >
            {row.original.division.name}
          </a>
        );
      },
    },
    {
      id: "skill",
      header: "Division skill",
      accessorFn: (d) => d?.division.skillChoice?.name,
    },
    {
      id: "homeTeam",
      header: "Home Team",
      accessorFn: (d) => d.homeTeam?.name,
      cell: ({ row }) => {
        return (
          <>
            {row.original.homeTeam ? (
              <a
                className="underline text-info-50 hover:text-info-70"
                href={`/players/team/${row.original.homeTeam.id}`}
              >
                {row.original.homeTeam.name}
              </a>
            ) : (
              "Missing Team"
            )}
          </>
        );
      },
    },
    {
      id: "awayTeam",
      header: "Away Team",
      accessorFn: (d) => d.awayTeam?.name,
      cell: ({ row }) => {
        return (
          <>
            {row.original.awayTeam ? (
              <a
                className="underline text-info-50 hover:text-info-70"
                href={`/players/team/${row.original.awayTeam.id}`}
              >
                {row.original.awayTeam.name}
              </a>
            ) : (
              "Missing Team"
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
      } catch (err) {
        dispatch(displayAlertError("Error fetching game summary report"));
      }
      setLoading(false);
    })();
  }, [dispatch, pagination.page]);

  return (
    <main>
      <div className="flex flex-col justify-between w-full gap-4 pb-8">
        <LoadingDialog open={isLoading || loading} />
        <Headline1Variable>Game Summary</Headline1Variable>
        <div className="flex flex-row flex-wrap items-end gap-4">
          <div className="w-64">
            <FormFieldDate
              type="date"
              name="startDate"
              initialValue={new Date(gameSummaryReportFilter.date) as any}
              dateChange={(e: any) => {
                setGameSummaryReportFilter((prevState) => {
                  return {
                    ...prevState,
                    date: e,
                  };
                });
              }}
              label="Start date"
            ></FormFieldDate>
          </div>
          <div className="w-64">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                setGameSummaryReportFilter((prevState) => {
                  return {
                    ...prevState,
                    regionId: value == "0" ? null : parseInt(value),
                  };
                });
                getVenues();
              }}
              name="regionId"
              label="Region"
              placeholder="Region"
              value={gameSummaryReportFilter.regionId?.toString() ?? "0"}
            >
              {[
                { id: 0, name: "Select a region" },
                ...selectedRegions.map((region: any) => {
                  return { id: region.id, name: region.name };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="w-64">
            <FormFieldSelectMultiSearch
              oneItem={true}
              label="Select Venue"
              name="venueId"
              options={[
                ...(dataVenues?.venuesFacility.map((region) => {
                  return { value: `${region.id}`, label: region.name };
                }) || []),
              ]}
              values={
                gameSummaryReportFilter.venueId
                  ? [gameSummaryReportFilter.venueId.toString()]
                  : []
              }
              onValueChange={(values) => {
                setGameSummaryReportFilter((prevState) => {
                  return {
                    ...prevState,
                    venueId: values[0] == "0" ? null : parseInt(values[0]),
                  };
                });
              }}
              placeholder="Select Venue"
              maxCount={1}
            />
          </div>
          <div className="flex flex-row gap-4">
            <Button
              variant="primary"
              onClick={() => {
                getGames();
              }}
            >
              Apply
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setGameSummaryReportFilter({
                  regionId: null,
                  venueId: null,
                  date: dayjs().format("YYYY-MM-DD"),
                });
              }}
            >
              Clear
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {
            <DataTable
              data={dailyGameSummaryReportData}
              columns={gameSummaryReportColumn}
              // pagination={pagination}
              // handleChangePagination={handleChangePagination}
            />
          }
        </div>
      </div>
    </main>
  );
};

export default GameSummary;
