import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useNavigate, useParams } from "react-router-dom";
import FormField from "../../UI/FormField/FormField";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import Card from "../../UI/Card/Card";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Button from "../../UI/Button/Button";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import ReactQuill from "react-quill";
import {
  useCreateTournamentMutation,
  useSportFormatsQuery,
  useTournamentQuery,
  useUpdateTournamentMutation,
} from "../../../../src/generated/graphql";
import { z, ZodFormattedError } from "zod";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import Alert from "../../UI/Alerts/Alert";
import { dateWithoutTimezone } from "../../../utils/timeFunctions";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import Caption1 from "../../UI/Text/Caption/Caption1";
import { DateTimePicker } from "../../UI/shadcn/Time/date-time-picker";
import Body1 from "../../UI/Text/Body/Body1";
import dayjs from "dayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../UI/shadcn/tooltip";
import TextArea from "../../UI/FormField/TextArea/TextArea";
import { Switch } from "../../UI/shadcn/switch";
import { Label } from "../../UI/shadcn/label";
import { FormFieldSelectMultiSearch } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { FormFieldSelectMulti } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import FilterChip from "../../UI/Input/FilterChip";
import { getRegSkillChoices } from "../../../app/venueMasterSlice";
import {
  DeleteOutlineOutlined,
  FileUploadOutlined,
  FmdGood,
} from "@mui/icons-material";
import LeagueImagesDialog from "../../UI/Dialog/LeagueImagesDialog";
import TournamentImagesDialog from "../../UI/Dialog/TournamentImagesDialog";

const TournamentSchema = z
  .object({
    id: z.number().optional(),
    name: z.string().min(1, { message: "Name is required" }),
    regOpenDateTime: z.date(),
    regCloseDateTime: z.date(),
    startDateTimeLocal: z.date(),
    endDateTimeLocal: z.date(),
    sportFormatId: z.number().min(1, { message: "Sport format is required" }),
    priceForTeam: z
      .number()
      .min(0, { message: "Price for team must be non-negative" }),
    priceForIndy: z
      .number()
      .min(0, { message: "Price for Indy must be non-negative" }),
    maxCapacity: z
      .number()
      .min(0, { message: "Max capacity must be non-negative" }),
    setCapacity: z
      .number()
      .min(0, { message: "Set capacity must be non-negative" }),
    indyTeams: z
      .number()
      .min(0, { message: "Indy teams must be non-negative" }),
    tournamentDetails: z
      .string()
      .min(1, { message: "Tournament details are required" }),
    regSkillChoices: z.array(z.number()).refine(
      (value) => {
        return value.length > 0;
      },
      {
        message: "You must select at least 1 reg skill choice",
        path: ["regSkillChoices"],
      }
    ),
    isActive: z.boolean(),
    imageLink: z.string().optional().catch(""),
  })
  .refine(
    (data) => {
      return data.regOpenDateTime < data.regCloseDateTime;
    },
    { message: "Registration open date must be before registration close date" }
  )
  .refine(
    (data) => {
      return data.startDateTimeLocal < data.endDateTimeLocal;
    },
    { message: "Start date must be before end date" }
  )
  .refine(
    (data) => {
      return dayjs(data.startDateTimeLocal).isSame(
        dayjs(data.endDateTimeLocal),
        "day"
      );
    },
    { message: "Start and end date must be on the same day" }
  )
  .refine(
    (data) => {
      return data.regCloseDateTime < data.startDateTimeLocal;
    },
    { message: "Start date must be after the reg close date" }
  );

type TournamentInput = z.infer<typeof TournamentSchema>;

const TournamentManagement: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const isEditTournament =
    params.id !== undefined && !isNaN(+params.id) ? +params.id : undefined;
  const navigate = useNavigate();
  const { selectedRegions, regSkillChoices } = useSelector(
    (state: RootState) => state.venueMaster
  );

  const initialTournament: TournamentInput = {
    name: "",
    regOpenDateTime: new Date(),
    regCloseDateTime: new Date(),
    startDateTimeLocal: new Date(),
    endDateTimeLocal: new Date(),
    sportFormatId: 0,
    priceForTeam: 0,
    priceForIndy: 0,
    maxCapacity: 0,
    setCapacity: 0,
    indyTeams: 0,
    tournamentDetails: "",
    regSkillChoices: [],
    isActive: false,
    imageLink: "",
  };

  const [tournament, setTournament] =
    useState<TournamentInput>(initialTournament);
  const [regionId, setRegionId] = useState<number>(0);
  const [openTournamentImageDialog, setOpenTournamentImageDialog] =
    React.useState(false);

  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<TournamentInput, string>
  >({ _errors: [] });

  /*** QUERUES ***/
  const { data: sportFormats, loading: loadingSportFormats } =
    useSportFormatsQuery({
      variables: {
        sportFormatFilters: {
          ...(regionId !== 0 && { regionId: regionId }),
        },
      },
    });

  const { loading: loadingTournament } = useTournamentQuery({
    variables: {
      id: isEditTournament!,
    },
    skip: isEditTournament === undefined,
    onCompleted: (data) => {
      console.log(data);
      const tournamentData = {
        ...data.tournament,
        regSkillChoices: data.tournament.regSkillChoices.map((regSkill) => {
          return regSkill.id;
        }),
        regOpenDateTime: new Date(data.tournament.regOpenDateTime),
        regCloseDateTime: new Date(data.tournament.regCloseDateTime),
        startDateTimeLocal: new Date(data.tournament.startDateTimeLocal),
        endDateTimeLocal: new Date(data.tournament.endDateTimeLocal),
      };
      const parsedTournamentData = TournamentSchema.safeParse(tournamentData);
      if (parsedTournamentData.success) {
        setTournament(parsedTournamentData.data);
      } else {
        dispatch(displayAlertWarning("Data is not valid. Please contact IT"));
      }
    },
  });

  /*** MUTATIONS ***/
  const [createTournament, { loading: loadingCreate }] =
    useCreateTournamentMutation();
  const [updateTournament, { loading: loadingUpdate }] =
    useUpdateTournamentMutation();

  /*** USE EFFECTS ***/
  useEffect(() => {
    dispatch(getRegSkillChoices(""));
  }, []);
  /*** UTILITY FUNCTIONS ***/
  const handleSubmit = () => {
    const result = TournamentSchema.safeParse(tournament);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("Issue with form"));
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    const { id, startDateTimeLocal, endDateTimeLocal, ...otherFields } =
      tournament;
    if (id === undefined) {
      createTournament({
        variables: {
          tournamentInput: {
            ...otherFields,
            startDateTimeLocal: dateWithoutTimezone(startDateTimeLocal),
            endDateTimeLocal: dateWithoutTimezone(endDateTimeLocal),
          },
        },
        onCompleted(data, clientOptions) {
          if (data.createTournament.success) {
            dispatch(displayAlertSuccess(data.createTournament.message));
            navigate(`/ops/tournaments`);
          } else {
            dispatch(displayAlertError(data.createTournament.message));
          }
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      updateTournament({
        variables: {
          updateTournamentInput: {
            id: id,
            tournamentInput: {
              ...otherFields,
              startDateTimeLocal: dateWithoutTimezone(startDateTimeLocal),
              endDateTimeLocal: dateWithoutTimezone(endDateTimeLocal),
            },
          },
        },
        onCompleted(data, clientOptions) {
          if (data.updateTournament.success) {
            dispatch(displayAlertSuccess(data.updateTournament.message));
            navigate(`/ops/tournaments`);
          } else {
            dispatch(displayAlertError(data.updateTournament.message));
          }
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message));
        },
      });
    }
  };
  const renderErrors = (errors: any, parentKey = ""): JSX.Element[] => {
    return Object.keys(errors).flatMap((key) => {
      const error = errors[key];
      const displayKey = key === "_errors" ? parentKey.split(".").pop() : key; // Get the last segment of the path

      if (key === "_errors" && Array.isArray(error) && error.length > 0) {
        // Display the error message with only the final field name
        return error.map((message) => (
          <Alert
            variant="error"
            content={`${displayKey}: ${message}`}
            size="small"
            persist={true}
            key={`${parentKey}-error`}
          />
        ));
      } else if (typeof error === "object" && error !== null) {
        // Recursively process nested fields
        return renderErrors(error, `${parentKey}.${key}`);
      }
      return [];
    });
  };

  const pathsBreadcrumbs = [
    { name: "Tournament", url: "/ops/tournaments" },
    {
      name: "Tournament Details",
      url: `/ops/tournament/${isEditTournament ? isEditTournament : "new"}`,
    },
  ];

  return (
    <main className="flex flex-col gap-6 pb-20">
      <LoadingDialog
        open={loadingCreate || loadingUpdate || loadingTournament}
      />
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/tournaments"
      />
      <div className="flex flex-row items-center justify-between w-full">
        <Headline1Variable>
          {isEditTournament ? "Edit Sport format" : "Create New Tournament"}
        </Headline1Variable>
        <Button
          variant="primary"
          className="h-fit"
          onClick={() => handleSubmit()}
        >
          {isEditTournament ? (
            <span> Edit Tournament</span>
          ) : (
            <span> Create Tournament</span>
          )}
        </Button>
      </div>
      {renderErrors(zodErrors)}
      <Card className="flex flex-col w-2/3 gap-4">
        <div className="flex items-center justify-between">
          <Subtitle1>Tournament Details</Subtitle1>
          <div className="flex items-center space-x-2">
            <Switch
              id="isActive"
              checked={tournament.isActive}
              onCheckedChange={(newState) =>
                setTournament((prevState) => ({
                  ...prevState,
                  isActive: newState,
                }))
              }
            />
            <Label htmlFor="isActive">Active</Label>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4">
          <FormField
            initialValue={tournament.name}
            error={zodErrors.name !== undefined}
            inputChange={(e) => {
              setTournament((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
            name="name"
            label="Name"
          ></FormField>
          <FormFieldSelect
            name="regionId"
            value={regionId ? regionId.toString() : "0"}
            inputChange={(value) => {
              setTournament((prevState) => ({
                ...prevState,
                sportFormatId: 0,
              }));
              setRegionId(+value);
            }}
            label="Region"
            placeholder="Select Region"
          >
            {[...[{ id: "0", name: "Select Region" }], ...selectedRegions]}
          </FormFieldSelect>
          <FormFieldSelectMultiSearch
            name="sportFormatId"
            value={
              tournament.sportFormatId
                ? tournament.sportFormatId.toString()
                : "0"
            }
            loading={loadingSportFormats}
            error={zodErrors.sportFormatId !== undefined}
            oneItem={true}
            values={
              tournament.sportFormatId
                ? [tournament.sportFormatId.toString()]
                : []
            }
            onValueChange={(value) => {
              setTournament((prevState) => ({
                ...prevState,
                sportFormatId: +value,
              }));
            }}
            label="Sport Format"
            placeholder="Select Sport Format"
            options={[
              ...(sportFormats?.sportFormats.sportsFormat.map((sF) => {
                return { value: sF.id.toString(), label: sF.name };
              }) || []),
            ]}
          />
        </div>
        <div className="flex flex-row w-full gap-4">
          <FormField
            type="number"
            initialValue={tournament.maxCapacity?.toString()}
            error={zodErrors.maxCapacity !== undefined}
            inputChange={(e) => {
              setTournament((prevState) => ({
                ...prevState,
                maxCapacity: +e.target.value,
              }));
            }}
            name="maxCapacity"
            label="Max Capacity"
          />
          <FormField
            type="number"
            initialValue={tournament.setCapacity?.toString()}
            error={zodErrors.setCapacity !== undefined}
            inputChange={(e) => {
              setTournament((prevState) => ({
                ...prevState,
                setCapacity: +e.target.value,
              }));
            }}
            name="setCapacity"
            label="Set Capacity"
          />
          <FormField
            type="number"
            initialValue={tournament.indyTeams?.toString()}
            error={zodErrors.indyTeams !== undefined}
            inputChange={(e) => {
              setTournament((prevState) => ({
                ...prevState,
                indyTeams: +e.target.value,
              }));
            }}
            name="indyTeams"
            label="Indy Teams"
          />
        </div>
        <div className="flex flex-row w-full gap-4">
          <FormField
            type="number"
            initialValue={tournament.priceForTeam?.toString()}
            error={zodErrors.priceForTeam !== undefined}
            inputChange={(e) => {
              setTournament((prevState) => ({
                ...prevState,
                priceForTeam: +e.target.value,
              }));
            }}
            name="priceForTeam"
            label="Price for Team"
          />
          <FormField
            type="number"
            initialValue={tournament.priceForIndy?.toString()}
            error={zodErrors.priceForIndy !== undefined}
            inputChange={(e) => {
              setTournament((prevState) => ({
                ...prevState,
                priceForIndy: +e.target.value,
              }));
            }}
            name="priceForIndy"
            label="Price for Indy"
          />
        </div>
        <div className="flex flex-row w-full gap-4">
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">Start Date Time</Caption1>
              <DateTimePicker
                error={zodErrors.startDateTimeLocal !== undefined}
                date={
                  tournament.startDateTimeLocal
                    ? new Date(tournament.startDateTimeLocal)
                    : undefined
                }
                setDate={(date) => {
                  if (date) {
                    setTournament((prevState) => ({
                      ...prevState,
                      startDateTimeLocal: date,
                      endDateTimeLocal: dayjs(prevState.endDateTimeLocal)
                        .year(dayjs(date).year())
                        .month(dayjs(date).month())
                        .date(dayjs(date).date())
                        .toDate(),
                    }));
                  }
                }}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">End Date Time</Caption1>
              <DateTimePicker
                error={zodErrors.endDateTimeLocal !== undefined}
                date={
                  tournament.endDateTimeLocal
                    ? new Date(tournament.endDateTimeLocal)
                    : undefined
                }
                setDate={(date) => {
                  if (date) {
                    setTournament((prevState) => ({
                      ...prevState,
                      endDateTimeLocal: date,
                    }));
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card className="flex flex-col w-2/3 gap-4">
        <Subtitle1>Skill Selection</Subtitle1>
        <div className="flex flex-col w-1/3 gap-4 mt-3">
          <div className="w-64">
            <FormFieldSelectMulti
              name="regSkillChoice"
              input={regSkillChoices}
              addAllOption={false}
              selectedItems={tournament.regSkillChoices}
              setSelectedItems={(values) => {
                setTournament((prevState) => ({
                  ...prevState,
                  regSkillChoices: values,
                }));
              }}
              label="Skill Choice"
              placeholder="Skill Choice"
            />
          </div>
          <div className="flex flex-row items-center justify-start w-full gap-2">
            {tournament.regSkillChoices &&
              tournament.regSkillChoices.length > 0 &&
              tournament.regSkillChoices.map((id: number) => {
                const regSkillChoice = regSkillChoices.find(
                  (regSkill: any) => regSkill.id == id
                );
                return (
                  <FilterChip
                    key={id}
                    text={regSkillChoice ? regSkillChoice.name : "Oops"}
                    onDelete={() => {
                      const values = tournament.regSkillChoices.filter(
                        (regSkillId) => regSkillId != id
                      );
                      setTournament((prevState) => ({
                        ...prevState,
                        regSkillChoices: values,
                      }));
                    }}
                  />
                );
              })}
          </div>
        </div>
      </Card>
      <Card className="flex flex-col w-2/3 gap-4">
        <Subtitle1>Registration Dates</Subtitle1>
        <div className="flex flex-row w-full gap-4">
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center gap-1">
                <Caption1 className="font-medium">Reg Open Date</Caption1>
                {tournament.regOpenDateTime && (
                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger>
                        <InfoOutlinedIcon
                          sx={{ height: "16px", width: "16px" }}
                          className="transition-colors text-info-50 hover:text-info-80 hover:cursor-pointer"
                        />
                      </TooltipTrigger>
                      <TooltipContent side="right">
                        <div className="flex flex-col">
                          <div className="flex flex-row">
                            <Body1>Toronto Start Time:</Body1>{" "}
                            <Body1>
                              {dayjs(new Date(tournament.regOpenDateTime))
                                .tz("America/Toronto")
                                .format("YYYY-MM-DD h:mm A")}
                            </Body1>
                          </div>
                          <div className="flex flex-row">
                            <Body1>Winnepeg Start Time:</Body1>{" "}
                            <Body1>
                              {dayjs(new Date(tournament.regOpenDateTime))
                                .tz("America/Winnipeg")
                                .format("YYYY-MM-DD h:mm A")}
                            </Body1>
                          </div>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>
              <DateTimePicker
                error={zodErrors.regOpenDateTime !== undefined}
                date={
                  tournament.regOpenDateTime
                    ? new Date(tournament.regOpenDateTime)
                    : undefined
                }
                setDate={(date) => {
                  if (date) {
                    setTournament((prevState) => ({
                      ...prevState,
                      regOpenDateTime: date,
                    }));
                  }
                }}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center gap-1">
                <Caption1 className="font-medium">Reg Close Date</Caption1>
                {tournament.regCloseDateTime && (
                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger>
                        <InfoOutlinedIcon
                          sx={{ height: "16px", width: "16px" }}
                          className="transition-colors text-info-50 hover:text-info-80 hover:cursor-pointer"
                        />
                      </TooltipTrigger>
                      <TooltipContent side="right">
                        <div className="flex flex-col">
                          <div className="flex flex-row">
                            <Body1>Toronto Close Time:</Body1>{" "}
                            <Body1>
                              {dayjs(new Date(tournament.regCloseDateTime))
                                .tz("America/Toronto")
                                .format("YYYY-MM-DD h:mm A")}
                            </Body1>
                          </div>
                          <div className="flex flex-row">
                            <Body1>Winnepeg Close Time: </Body1>
                            <Body1>
                              {dayjs(new Date(tournament.regCloseDateTime))
                                .tz("America/Winnipeg")
                                .format("YYYY-MM-DD h:mm A")}
                            </Body1>
                          </div>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>
              <DateTimePicker
                error={zodErrors.regCloseDateTime !== undefined}
                date={
                  tournament.regCloseDateTime
                    ? new Date(tournament.regCloseDateTime)
                    : undefined
                }
                setDate={(date) => {
                  if (date) {
                    setTournament((prevState) => ({
                      ...prevState,
                      regCloseDateTime: date,
                    }));
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card className="flex flex-col w-2/3 gap-4">
        <Subtitle1>Tournament Image</Subtitle1>
        <div className="flex flex-row gap-4 lg:w-1/4 md:1/3">
          {tournament.imageLink && (
            <img
              className="mr-2 rounded-sm bg-neutral-80"
              src={tournament.imageLink}
              alt="image"
              width={200}
            />
          )}
          {!tournament.imageLink && (
            <div className="w-[120px] h-[120px] bg-gray-300 rounded-2xl flex items-center justify-center">
              <FmdGood fontSize="large" />
            </div>
          )}
          <div className="flex flex-col self-center gap-2">
            <Button
              variant="secondary"
              onClick={() => {
                setOpenTournamentImageDialog(true);
              }}
              type="button"
              // disabled={!!facilitySetups.isDeleted}
            >
              <div className="flex gap-2">
                <FileUploadOutlined />
                <div className="text-center">Select Image</div>
              </div>
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setTournament((prevState) => ({
                  ...prevState,
                  imageLink: "",
                }));
              }}
              type="button"
            >
              <div className="flex font-normal">
                <div className="mr-1">
                  <DeleteOutlineOutlined />
                </div>
                Remove
              </div>
            </Button>
          </div>
        </div>
      </Card>
      <Card className="flex flex-col w-2/3 gap-4 h-[486px]">
        <ReactQuill
          style={{ height: "400px" }}
          className="w-full"
          theme="snow" // you can use 'bubble' theme as well
          value={tournament.tournamentDetails}
          onChange={(text: string) =>
            setTournament((prevState) => ({
              ...prevState,
              tournamentDetails: text,
            }))
          }
        />
      </Card>
      <Button
        variant="primary"
        className="h-fit w-60"
        onClick={() => handleSubmit()}
      >
        {isEditTournament ? (
          <span> Edit Tournament</span>
        ) : (
          <span> Create Tournament</span>
        )}
      </Button>
      <TournamentImagesDialog
        open={openTournamentImageDialog}
        onOpenChange={(value) => {
          setOpenTournamentImageDialog(value);
        }}
        handleApplyImage={(imageUrl) => {
          setTournament((prevState) => ({
            ...prevState,
            imageLink: imageUrl,
          }));
        }}
      />
    </main>
  );
};

export default TournamentManagement;
