import React, { ChangeEvent, useEffect, useState } from "react";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import Alert from "../../UI/Alerts/Alert";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import FormField from "../../UI/FormField/FormField";
import CheckBox from "../../UI/Checkbox/Checkbox";
import Body1 from "../../UI/Text/Body/Body1";
import dayjs from "dayjs";
import { z, ZodFormattedError } from "zod";
import Caption1 from "../../UI/Text/Caption/Caption1";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import Card from "../../UI/Card/Card";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import {
  EPermission,
  filterPermittedRegions,
  isPermissionGranted,
} from "../../../utils/permissions";
import {
  FormFieldSelectMulti,
  Item,
} from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import { useNavigate } from "react-router-dom";
import { useCreatePromoCodeMutation } from "../../../generated/graphql";
import {
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { FormFieldSelectMultiSearch } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import { renderZodErrors } from "../../../utils/zodErrors";
import { Switch } from "../../UI/shadcn/switch";
import { Label } from "../../UI/shadcn/label";

const PromoCodeSchema = z
  .object({
    name: z.string().trim().min(1, { message: "Promo Code name is required" }),
    discount: z
      .number()
      .int()
      .min(1, { message: "Discount must be a positive number" }),
    discountTypeId: z
      .number()
      .int()
      .min(1, { message: "Discount Type is required" }),
    validFrom: z.date().refine(
      (data) => {
        return data !== undefined;
      },
      { message: "Valid From Date is required" }
    ),
    validTo: z.date().refine(
      (data) => {
        return data !== undefined;
      },
      { message: "Expiry Date is required" }
    ),
    regions: z.array(z.number()).min(1, {
      message: "At least one region is required",
    }),
  })
  .refine(
    (data) => {
      if (data.validFrom && data.validTo) {
        return data.validFrom < data.validTo;
      }
    },
    { message: "Invalid dates", path: ["validFrom", "validTo"] }
  )
  .refine(
    (data) => {
      // If discount type is percentage, discount should be between 0 and 100
      if (data.discountTypeId === 1) {
        return data.discount >= 0 && data.discount <= 100;
      } else {
        return data.discount >= 0;
      }
    },
    { message: "Invalid discount value", path: ["discount"] }
  );

type PromoCodeType = z.infer<typeof PromoCodeSchema>;

const CreatePromoCode = () => {
  const pathsBreadcrumbs = [
    { name: "Promo Codes", url: "/marketing/promo-codes" },
    { name: "Create Promo Code", url: "/marketing/promo-codes/new" },
  ];
  const initialPromoCode: PromoCodeType = {
    name: "",
    discount: 0,
    discountTypeId: 1,
    validFrom: new Date(),
    validTo: new Date(),
    regions: [],
  };
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user: admin }: any = useSelector((state: RootState) => state.auth);

  const { selectedRegions } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const [promoCode, setPromoCode] = useState<PromoCodeType>(initialPromoCode);
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<PromoCodeType, string>
  >({ _errors: [] });
  const [promoCodeErrorMessages, setPromoCodeErrorMessages] = React.useState<
    string[]
  >([]);
  const [regions, setRegions] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [allRegions, setAllRegions] = useState<boolean>(false);

  const [createPromoCode, { loading: loadingCreatePromoCode }] =
    useCreatePromoCodeMutation();

  useEffect(() => {
    const isPermGranted =
      admin && admin.permission
        ? isPermissionGranted(
            admin.permission,
            EPermission["PROMOCODE_DISCOUNT"]
          )
        : false;
    if (admin == null) {
      return;
    }
    let permittedRegions: Item[] | undefined = undefined;

    if (isPermGranted) {
      const regions = (permittedRegions =
        admin && admin.permission
          ? filterPermittedRegions(
              admin.permission,
              EPermission["PROMOCODE_DISCOUNT"]
            )?.map((region) => {
              return {
                id: region.id,
                name: region.name,
              };
            })
          : undefined);
      permittedRegions =
        regions && regions.length > 0 ? regions : selectedRegions;
      if (regions === undefined) {
        setAllRegions(true);
      }
      setRegions(
        permittedRegions.map((region) => ({
          id: region.id,
          name: region.name,
        }))
      );
    } else {
      navigate("/marketing/promo-codes");
    }
  }, [admin]);

  const handleSubmit = () => {
    const result = PromoCodeSchema.safeParse(promoCode);

    if (result.success) {
      createPromoCode({
        variables: {
          promoCodeInput: {
            name: promoCode.name.trim().toUpperCase(),
            discount: promoCode.discount,
            discountTypeId: promoCode.discountTypeId,
            validFrom: dayjs(promoCode.validFrom).format("YYYY-MM-DD"),
            validTo: dayjs(promoCode.validTo).format("YYYY-MM-DD"),
            regions: promoCode.regions,
          },
        },
        onCompleted: (data) => {
          if (data.createPromoCode.success) {
            navigate("/marketing/promo-codes");
            dispatch(displayAlertSuccess("Promo Code created successfully"));
          } else {
            const message = [data.createPromoCode.message];
            setPromoCodeErrorMessages(message);
          }
        },
        onError: (error) => {
          setPromoCodeErrorMessages([
            "Error creating promo code. Check the form and try again. If the problem persists, contact support.",
          ]);
        },
      });
    } else {
      if (!result.success) {
        setZodErrors(result.error.format());
        dispatch(displayAlertWarning("There is an issue with the form"));
        return;
      }
    }
  };

  return (
    <main className="pb-10">
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/marketing/promo-codes"
      ></BreadCrumbs>
      <div className="flex flex-row items-center justify-between w-full mt-6">
        <Headline1Variable>Create New Promo Code</Headline1Variable>
        <div className="h-10 mt-5">
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
          >
            Create Promo Code
          </Button>
        </div>
      </div>
      {promoCodeErrorMessages.length > 0 && (
        <div className="flex flex-col w-2/3 gap-2">
          {promoCodeErrorMessages.map((errorMessage) => {
            return (
              <Alert
                variant="error"
                size="large"
                content={errorMessage}
                persist={true}
              />
            );
          })}
        </div>
      )}
      <div className="grid w-2/3 grid-cols-1 gap-2 mt-4">
        {renderZodErrors(zodErrors)}
      </div>

      <Card className="w-2/3 mt-4">
        <Subtitle1>Promo Code Details</Subtitle1>
        <div className="flex flex-row items-center justify-center w-full gap-4 mt-6">
          <FormField
            initialValue={promoCode.name ? promoCode.name : ""}
            inputChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPromoCode({ ...promoCode, name: e.target.value.toUpperCase() })
            }
            name="name"
            label="Promo Code Name"
          ></FormField>
        </div>
        <div className="flex flex-row items-center justify-center w-full gap-4 mt-6">
          <FormFieldSelect
            inputChange={(val: number) =>
              setPromoCode({ ...promoCode, discountTypeId: +val })
            }
            name="discountTypeId"
            label="Discount Type"
            value={promoCode.discountTypeId.toString()}
            placeholder="Select Discount Type"
          >
            {[
              { id: 1, name: "Percentage" },
              { id: 2, name: "Fixed Amount" },
            ]}
          </FormFieldSelect>
          <FormField
            value={promoCode.discount ? promoCode.discount : 0}
            inputChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPromoCode({ ...promoCode, discount: +e.target.value })
            }
            name="discount"
            label="Discount"
            type="number"
          ></FormField>
        </div>
      </Card>
      <Card className="w-2/3 mt-6">
        <Subtitle1>Location Specification</Subtitle1>
        <div className="flex flex-col w-full gap-4 mt-6">
          <div className="flex items-center w-full gap-4 h-14">
            {allRegions && (
              <div className="flex items-center space-x-2">
                <Switch
                  onCheckedChange={(value) => {
                    if (value) {
                      setPromoCode((prevState) => ({
                        ...prevState,
                        regions: [0],
                      }));
                    } else {
                      setPromoCode((prevState) => ({
                        ...prevState,
                        regions: [],
                      }));
                    }
                  }}
                  id="all-regions"
                />
                <Label
                  htmlFor="all-regions"
                  className="whitespace-nowrap"
                >
                  All Regions
                </Label>
              </div>
            )}
            {promoCode.regions[0] !== 0 && (
              <FormFieldSelectMulti
                name="regions"
                label="Regions"
                placeholder="Region"
                input={regions.map((region) => {
                  return { id: region.id, name: region.name };
                })}
                defaultValue={
                  promoCode.regions?.map((region) => region.toString()) || []
                }
                selectedItems={promoCode.regions || []}
                setSelectedItems={(items) =>
                  setPromoCode((prevState) => ({
                    ...prevState,
                    regions: items.map((value) => +value),
                  }))
                }
              />
            )}
          </div>
          {promoCode.regions.length > 0 && (
            <Alert
              variant="info"
              size="large"
              content={`This promo code will be available in ${
                promoCode.regions[0] === 0
                  ? "all regions on JUDI"
                  : regions
                      .filter((region) => promoCode.regions.includes(region.id))
                      .map((region) => region.name)
                      .join(", ")
              }.`}
              persist={true}
            />
          )}
        </div>
      </Card>
      <Card className="w-2/3 mt-6">
        <Subtitle1>Promo Code Dates</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">Valid From</Caption1>
              <DatePicker
                className="w-full"
                date={
                  promoCode.validFrom
                    ? new Date(promoCode.validFrom)
                    : undefined
                }
                setDate={(date) =>
                  setPromoCode({
                    ...promoCode,
                    validFrom: date ?? new Date(),
                  })
                }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">Expiry Date</Caption1>
              <DatePicker
                className="w-full"
                date={
                  promoCode.validTo ? new Date(promoCode.validTo) : undefined
                }
                setDate={(date) =>
                  setPromoCode({
                    ...promoCode,
                    validTo: date ?? new Date(),
                  })
                }
              />
            </div>
          </div>
        </div>
      </Card>
      <div className="h-10 mt-5">
        <Button
          variant="primary"
          onClick={() => handleSubmit()}
        >
          Create Promo Code
        </Button>
      </div>
    </main>
  );
};

export default CreatePromoCode;
