import { useEffect, useState } from "react";

export default function SuperDarkMode({
  children,
}: {
  children: React.ReactNode;
}) {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="bg-black h-fit overflow-hidden">
      <div
        className="absolute z-40 w-screen h-screen"
        style={{
          background: `radial-gradient(circle at ${mousePos.x}px ${mousePos.y}px, rgba(0, 0, 0, 0) 100px, rgba(0, 0, 0, 1) 250px)`,
          pointerEvents: "none",
        }}
      ></div>
      <div className="absolute z-10 w-full h-full">{children}</div>
    </div>
  );
}
